import React, { FC, Suspense } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import { AppCrossroad } from './AppCrossroad';
import { ErrorBoundary } from './ErrorBoundary';
import {
  HardwareContextProvider,
  CompanyContextProvider,
  HardwareRevisionTypeContextProvider,
  PaymentContextProvider,
  HardwarePlacementContextProvider,
  IssueContextProvider,
} from './contexts';
import { Loading } from './components';

import './App.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const defaultTheme = createTheme({});
const colorTheme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
  palette: {
    primary: {
      light: '#365ACC',
      main: '#496AEC',
      dark: '#365ACC',
    },
    secondary: {
      light: '#A1B7FF',
      main: '#C7D4FF',
      dark: '#A1B7FF',
    },
    error: {
      light: '#FF4242',
      main: '#FF4242',
      dark: '#FF4242',
    },
    warning: {
      light: '#E6E6E6',
      main: '#E6E6E6',
      dark: '#E6E6E6',
    },
    info: {
      light: '#E6E6E6',
      main: '#E6E6E6',
      dark: '#E6E6E6',
    },
  },
});

const theme = createTheme({
  ...colorTheme,
  components: {
    MuiListItemButton: {
      styleOverrides: {
        selected: {
          backgroundColor: '#000',
        },
        root: {
          '&.Mui-selected': {
            backgroundColor: colorTheme.palette.primary.main,
            color: defaultTheme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: colorTheme.palette.primary.dark,
            },
          },
        },
      },
    },
  },
});

export const App: FC = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <CompanyContextProvider>
              <HardwareContextProvider>
                <HardwareRevisionTypeContextProvider>
                  <PaymentContextProvider>
                    <HardwarePlacementContextProvider>
                      <IssueContextProvider>
                        <AppCrossroad />
                      </IssueContextProvider>
                    </HardwarePlacementContextProvider>
                  </PaymentContextProvider>
                </HardwareRevisionTypeContextProvider>
              </HardwareContextProvider>
            </CompanyContextProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </Suspense>
    </ErrorBoundary>
  );
};
