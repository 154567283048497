import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  FC,
  PropsWithChildren,
} from 'react';
import { Employee } from '../commonTypes';
import { UserContext } from './UserContext';

export const EmployeesContext = createContext<
  Partial<{
    employees: Employee[];
    isLoading: boolean;
    refreshEmployees: () => Promise<void>;
  }>
>({
  employees: [],
  refreshEmployees: () => Promise.resolve(),
  isLoading: false,
});

export const EmployeesContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { userData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const refreshEmployees = async () => {
    setIsLoading(true);

    const dbEmployees = await fetch('/api/employees', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'GET',
    }).then((data) => data.json());
    const activeEmployee = dbEmployees.filter(
      (employee: Employee) => employee.isDeleted === false
    );

    setEmployees(activeEmployee);

    setIsLoading(false);
  };

  useEffect(() => {
    refreshEmployees();
  }, []); // eslint-disable-line

  return (
    <EmployeesContext.Provider
      value={{ employees, isLoading, refreshEmployees }}
    >
      {children}
    </EmployeesContext.Provider>
  );
};
