import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { Dialog } from '../Dialog';
import { StickerPrintForm } from '../DialogForms';

interface StickerPrintDialogProps {
  onClose: () => void;
}

export const StickerPrintDialog: FC<StickerPrintDialogProps> = (props) => {
  const { onClose } = props;

  return (
    <Dialog
      title={<Trans i18nKey="stickerPrintDialog.title" />}
      onClose={onClose}
    >
      <StickerPrintForm onCancel={onClose} />
    </Dialog>
  );
};
