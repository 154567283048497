import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Trans } from 'react-i18next';
import { Buffer } from 'buffer';
import { useSnackbar } from 'notistack';
import { Button, Grid } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { AddFileDialog } from './AddFileDialog';
import { UserContext } from '../contexts';
import { Documents, DocumentType } from '../commonTypes';
import { AlertDialog } from '../components';

interface FileFormProps {
  hwId?: string;
  companyId?: string;
}

export interface FileFormFunctions {
  openAddFileDialog: () => void;
}

export const FileForm = forwardRef<FileFormFunctions, FileFormProps>(
  (props, ref) => {
    const { hwId, companyId } = props;
    const { userData } = useContext(UserContext);
    const [documents, setDocuments] = useState<Documents[]>();
    const [isOpenAddFileDialog, setOpenAddFileDialog] = useState(false);
    const [deleteFileID, setDeleteFileID] = useState('');
    const [isOpenRemoveDialog, setOpenRemoveDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const getFiles = useCallback(async () => {
      try {
        const res = await fetch(
          `/api/documents/get-documents/${hwId}?companyId=${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${userData!.user.token}`,
            },
            method: 'GET',
          }
        ).then((data) => data.json());
        if (res) {
          setDocuments(res);
        }
      } catch (exception) {
        console.log(exception);
      }
    }, [hwId, userData, companyId]);

    useEffect(() => {
      getFiles();
    }, [getFiles]);

    const deleteFile = async (idFile: string) => {
      const res = await fetch(`/api/documents/delete/${idFile}`, {
        headers: {
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'DELETE',
      }).then((data) => data.json());
      if (res.error) {
        enqueueSnackbar(res.error, {
          variant: 'error',
        });
        return;
      }
      enqueueSnackbar(<Trans i18nKey="notifications.deleted" />, {
        variant: 'success',
      });

      getFiles();
    };

    const download = async (fileID: string) => {
      await fetch(`/api/documents/file-download/${fileID}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'GET',
      }).then((data) => {
        data.json().then((res) => {
          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.download = res.file.description.endsWith(res.file.fileFormat)
            ? res.file.description
            : res.file.description + '.' + res.file.fileFormat;
          link.href = window.URL.createObjectURL(
            new Blob([Buffer.from(res.data, 'base64')])
          );

          link.click();
          window.URL.revokeObjectURL(link.href);
          link.remove();
        });
      });
    };

    const locale = userData?.userInfo.preferredLanguage.split(
      '-'
    )[0] as keyof DocumentType['locales'];

    useImperativeHandle(ref, () => ({
      openAddFileDialog: () => {
        setOpenAddFileDialog(true);
      },
    }));

    return (
      <Grid
        container
        alignItems="flex-start"
        data-testid="documents-modal-form"
      >
        <Grid container item xs={12}>
          {documents ? (
            documents.map((document, index) => (
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="space-between"
                sx={{ fontSize: '13px' }}
              >
                <Grid item xs={7}>
                  {document.description}
                </Grid>
                <Grid item xs={3} sx={{ color: '#747474' }}>
                  {document.documentTypeInfo?.locales[locale] ?? ''}
                </Grid>
                <Grid item xs={1}>
                  <Button
                    color="error"
                    sx={{ boxShadow: 'none', minWidth: '50px' }}
                    onClick={() => {
                      setDeleteFileID(document?._id);
                      setOpenRemoveDialog(!isOpenRemoveDialog);
                    }}
                    data-testid="delete-document-btn"
                    data-id={document._id}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    color="primary"
                    sx={{ boxShadow: 'none', minWidth: '50px' }}
                    onClick={() => download(document._id)}
                  >
                    <FileDownloadOutlinedIcon />
                  </Button>
                </Grid>
                {index !== documents.length - 1 ? (
                  <div
                    style={{
                      height: '1px',
                      background: 'rgba(0, 0, 0, 0.12)',
                      width: '100%',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                  />
                ) : null}
              </Grid>
            ))
          ) : (
            <Grid>
              <Trans i18nKey="fileForm.addDocuments" />
            </Grid>
          )}
        </Grid>
        {isOpenAddFileDialog ? (
          <AddFileDialog
            hwId={hwId}
            companyId={companyId}
            refresh={() => {
              getFiles();
            }}
            onClose={() => {
              setOpenAddFileDialog(!isOpenAddFileDialog);
            }}
          />
        ) : null}
        {isOpenRemoveDialog ? (
          <AlertDialog
            onClose={() => setOpenRemoveDialog(!isOpenRemoveDialog)}
            onDelete={() => {
              deleteFile(deleteFileID);
            }}
          />
        ) : null}
      </Grid>
    );
  }
);
