import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

import { FilePickerDialog } from './FilePickerDialog';
import { SharedFileUploadProps } from './interfaces';
import { UserContext } from '../../contexts';
import { AddFileDialog } from '../../hardware';
import { Documents } from '../../commonTypes';

export const FileUploadForm: FC<SharedFileUploadProps> = (props) => {
  const { onClose, documentId: hwId, onSelect } = props;

  const { userData } = useContext(UserContext);

  const [isOpenAddFileDialog, setOpenAddFileDialog] = useState(false);
  const [documents, setDocuments] = useState<Documents[]>();
  const [openFilePicker, setOpenFilePicker] = useState(false);
  const [documentId, setDocumentId] = useState('');

  const getFiles = useCallback(async () => {
    try {
      const res = await fetch(`/api/documents/get-documents/${hwId}`, {
        headers: {
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'GET',
      }).then((data) => data.json());
      if (res) {
        setDocuments(res);
      }
    } catch (exception) {
      console.log(exception);
    }
  }, [hwId, userData]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  return (
    <Grid container alignItems="flex-start">
      <Grid container item xs={12}>
        {documents ? (
          documents.map((document) => (
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={10}>
                {document.description}
              </Grid>
              <Grid item xs={1}>
                <Button
                  color="primary"
                  sx={{ boxShadow: 'none', minWidth: '50px' }}
                  onClick={() => {
                    setDocumentId(document._id);
                    setOpenFilePicker(!openFilePicker);
                  }}
                >
                  <InsertLinkIcon />
                </Button>
              </Grid>
              <div
                style={{
                  height: '1px',
                  background: 'rgba(0, 0, 0, 0.12)',
                  width: 'calc(100% + 32px)',
                  margin: '0 -16px',
                  marginTop: '8px',
                  marginBottom: '8px',
                }}
              />
            </Grid>
          ))
        ) : (
          <Grid>
            <Trans i18nKey="fileForm.addDocuments" />
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        position={{ xs: 'sticky', lg: 'relative' }}
        bottom={{ xs: '-16px' }}
        bgcolor={{ xs: 'white' }}
        sx={{
          marginTop: '16px',
        }}
      >
        <Grid
          container
          justifyContent="center"
          sx={{
            maxWidth: 'calc(100% + 32px)',
            width: 'calc(100% + 32px)',
            marginInline: '-16px',
            paddingInline: '16px',
            background: '#fff',
          }}
        >
          <Grid item xs={12}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => setOpenAddFileDialog(!isOpenAddFileDialog)}
            >
              <Trans i18nKey="fileForm.newDocument" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button type="button" fullWidth onClick={onClose}>
              <Trans i18nKey="fileForm.close" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {openFilePicker ? (
        <FilePickerDialog
          documentId={documentId}
          onSelect={onSelect}
          onClose={() => {
            setOpenFilePicker(!openFilePicker);
            onClose();
          }}
        />
      ) : null}
      {isOpenAddFileDialog ? (
        <AddFileDialog
          hwId={hwId}
          refresh={() => {
            getFiles();
          }}
          onClose={() => {
            setOpenAddFileDialog(!isOpenAddFileDialog);
          }}
        />
      ) : null}
    </Grid>
  );
};
