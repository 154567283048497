import React, { FC } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { Dialog } from '../Dialog';
import { Trans } from '../../i18n';

interface AlertDialogProps {
  onClose: () => void;
  onDelete: () => void;
}

export const AlertDialog: FC<AlertDialogProps> = (props) => {
  const { onClose, onDelete } = props;

  return (
    <div>
      <Dialog
        title={<Trans i18nKey="alertDialog.title" />}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid>
          <DialogTitle id="alert-dialog-title">
            {<Trans i18nKey="alertDialog.notice" />}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Trans i18nKey="alertDialog.title" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="close-dialog-btn"
              color="primary"
              onClick={onClose}
            >
              <Trans i18nKey="alertDialog.storno" />
            </Button>
            <Button
              onClick={() => {
                onDelete();
                onClose();
              }}
              color="error"
              autoFocus
              data-testid="alert-dialog-delete-btn"
            >
              <Trans i18nKey="alertDialog.delete" />
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  );
};
