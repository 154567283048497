import React, { FC, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Grid, Button, FormControlLabel, Checkbox } from '@mui/material';
import { DatePicker } from 'mui-rff';
import { get } from 'lodash';

import { UserContext, HardwareContext } from '../contexts';
import { Employee, Hardware } from '../commonTypes';
import { UserSelectField } from '../users';
import { Dialog } from '../components';

interface RemoveDialogProps {
  values?: Hardware & { borrow?: boolean };
  onClose: () => void;
  onSubmit: (res: Hardware) => void;
}

export const RemoveDialog: FC<RemoveDialogProps> = (props) => {
  const { values, onClose, onSubmit } = props;

  const { setHardwareUpdate, globalHardware } = useContext(HardwareContext);
  const { userData } = useContext(UserContext);

  const [borrow, setBorrow] = useState(false);
  const currHwUser = globalHardware.find(
    (hw) => hw._id === values?._id
  )?.borrowedBy;

  const handleSubmit = async (formValues: Hardware & { borrow?: boolean }) => {
    if (formValues.borrow && currHwUser === formValues.borrowedBy) {
      return;
    }

    const data = formValues.borrow
      ? {
          borrowingConfirmed: false,
          borrowedDate: formValues.borrowedDate,
          borrowedBy:
            formValues.borrowedBy === -1 ? null : formValues.borrowedBy,
        }
      : {
          borrowedDate: formValues.borrowedDate,
          borrowedBy: formValues.borrowedBy === -1,
        };

    try {
      const res = await fetch(`/api/hardware/${values?._id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'PUT',
        body: JSON.stringify(data),
      }).then((data) => data.json());

      if (res.error) {
        return;
      }

      onSubmit(res);
      setHardwareUpdate(true);
    } catch (exception) {
      console.log(exception);
    }
  };

  return (
    <Dialog title={<Trans i18nKey="removeDialog.title" />} onClose={onClose}>
      <Form
        initialValues={{
          ...values,
          borrowedDate: new Date(),
          borrow,
        }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DatePicker
                    name="borrowedDate"
                    inputFormat="dd.MM.yyyy"
                    label={
                      <Trans i18nKey="removeDialog.removeDatePickerLabel" />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field name="borrow" type="checkbox">
                    {(props) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...props.input}
                            checked={borrow}
                            onChange={() =>
                              setBorrow((prevBorrow) => !prevBorrow)
                            }
                          />
                        }
                        label={<Trans i18nKey="removeDialog.newUserCheckbox" />}
                      />
                    )}
                  </Field>
                </Grid>
                {values.borrow ? (
                  <Grid item xs={12}>
                    <Field name="borrowedBy">
                      {(props) => {
                        return (
                          <UserSelectField
                            selectedValue={values.companyId}
                            value={get(props, 'input.value', undefined)}
                            onChange={(emp?: Employee) =>
                              props.input.onChange(emp?._id)
                            }
                            showAddNew={true}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                ) : null}

                <Grid
                  item
                  xs={12}
                  position={{ xs: 'sticky', lg: 'relative' }}
                  bottom={{ xs: '-16px' }}
                  bgcolor={{ xs: 'white' }}
                  paddingBottom="16px"
                  sx={{
                    marginTop: '8px',
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    spacing={{ xs: 0, lg: 2 }}
                    sx={{
                      maxWidth: '100%',
                      width: '100%',

                      background: '#fff',
                    }}
                  >
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        data-testid="dialog-save-return-hw-btn"
                        sx={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="removeDialog.save" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        type="button"
                        variant="text"
                        onClick={onClose}
                        sx={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="removeDialog.cancel" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </Dialog>
  );
};
