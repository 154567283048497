import React, { useCallback, useContext } from 'react';
import { Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  HardwareContext,
  HardwarePlacementContext,
  UserContext,
} from '../contexts';
import { PlacementType } from '../commonTypes';

export const useHandleDeleteItem = (
  onUpdate?: (placement: PlacementType[]) => void
) => {
  const { globalHardwarePlacements } = useContext(HardwarePlacementContext);
  const { userData } = useContext(UserContext);
  const { globalHardware } = useContext(HardwareContext);

  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    async (row: PlacementType) => {
      const assignedPlacement = globalHardware.find(
        (hw) => hw?.placement?._id === row._id
      );
      if (assignedPlacement) {
        return enqueueSnackbar(
          <Trans i18nKey="notifications.placementIsUsed" />,
          {
            variant: 'error',
          }
        );
      }
      const placementId = [row._id];
      const _hardwarePlacements = [...(globalHardwarePlacements ?? [])];
      const updatedData = _hardwarePlacements.filter((r) => r._id !== row._id);
      try {
        const res = await fetch(
          `/api/places/delete?placementId=${encodeURIComponent(
            placementId.join(',')
          )}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userData!.user.token}`,
            },
            method: 'DELETE',
          }
        );
        if (res.status === 200) {
          enqueueSnackbar(<Trans i18nKey="notifications.deleted" />, {
            variant: 'success',
          });
        }
      } catch (error) {}
      onUpdate?.(updatedData);
    },
    [
      globalHardware,
      globalHardwarePlacements,
      userData,
      enqueueSnackbar,
      onUpdate,
    ]
  );
};
