import React, { FC, useContext, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import * as fastIban from 'fast-iban';
import { Box, Button, Grid, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

import { CurrencySelectField } from '../Fields';
import { PaymentContext, UserContext } from '../../contexts';
import { Company, Currency, PaymentDetails } from '../../commonTypes';
import { getQRPayments } from '../../utils';
import { Trans } from '../../i18n';

interface PaymentFormProps {
  onCancel: () => void;
  companyData: Company;
  paymentData: PaymentDetails;
  onSubmit: (comp: Company) => void;
}

export const PaymentForm: FC<PaymentFormProps> = (props) => {
  const { companyData, onCancel, paymentData, onSubmit } = props;

  const { userData } = useContext(UserContext);
  const { setCurrency, currency } = useContext(PaymentContext);

  const [accountInfo, setAccountInfo] = useState('');
  const [companyVat, setCompanyVat] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const today = new Date();
  const currentDateWithoutTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const thirtyDaysAgo = new Date(currentDateWithoutTime);
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const isThirtyDaysAgo = companyData.licenceLastRequestAt
    ? new Date(companyData.licenceLastRequestAt) < thirtyDaysAgo
    : true;

  const handleSubmit = async (companyId: string) => {
    try {
      const res = await fetch('/api/companies/company/update-company-licence', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'PUT',
        body: JSON.stringify({
          companyId,
          companyVat,
          accountInfo: accountInfo,
          currency: currency,
        }),
      }).then((data) => data.json());
      if (res.error) {
        return enqueueSnackbar(res.error, { variant: 'error' });
      } else if ((res.status = 200)) {
        if (isThirtyDaysAgo) {
          enqueueSnackbar(
            <Trans i18nKey="notifications.premiumLicenseSuccesfullyOrdered" />,
            { variant: 'success' }
          );
        } else {
          enqueueSnackbar(
            <Trans i18nKey="notifications.premiumLicenceSuccesfullyOrderedWithoutExtended" />,
            { variant: 'success' }
          );
        }

        onSubmit(res);
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  const handleCurrencyChange = (currency?: string) => {
    currency && setCurrency(currency as Currency);
  };
  const isIbanCorrect = fastIban.validateIBAN(paymentData.companyIban);
  useEffect(() => {
    if (currency === 'CZK') {
      if (isIbanCorrect) {
        const ibanWithoutSpaces = paymentData.companyIban.replaceAll(' ', '');
        const accountNumberWithBankNumber = ibanWithoutSpaces.slice(-20);
        const bankNumber = accountNumberWithBankNumber.slice(0, 4);
        const accountNumber = accountNumberWithBankNumber.slice(-16);
        setAccountInfo(accountNumber + '/' + bankNumber);
      }
    }
    if (currency !== 'CZK') {
      setAccountInfo(paymentData.companyIban.replaceAll(' ', ''));
    }
  }, [currency, paymentData.companyIban, isIbanCorrect]);

  return (
    <Box data-testid="premium-payment-modal">
      <Grid container>
        <Grid item marginBottom={2}>
          {isThirtyDaysAgo ? (
            <Trans i18nKey="paymentForm.description" />
          ) : (
            <Trans i18nKey="paymentForm.descriptionWithoutExtended" />
          )}
        </Grid>
      </Grid>
      <Box maxWidth="184px" marginBottom={1}>
        {currency && (
          <CurrencySelectField
            onChange={handleCurrencyChange}
            value={currency}
          />
        )}
      </Box>
      {isIbanCorrect && accountInfo ? (
        <Grid data-testid="bank-account-value">
          <Trans i18nKey="paymentForm.bankAccount" />: {accountInfo}
        </Grid>
      ) : (
        <Grid data-testid="iban-value">
          <Trans i18nKey="paymentForm.iban" />: {paymentData.companyIban}
        </Grid>
      )}
      <Grid data-testid="price-value">
        <Trans i18nKey="paymentForm.totalPrice" />:{' '}
        {`${paymentData.totalSumToPay} ${currency}`}
      </Grid>
      <Grid data-testid="license-for-content">
        <Trans i18nKey="paymentForm.paymentReason" /> {companyData.companyName}
      </Grid>
      <Grid sx={{ marginTop: '16px' }}>
        <TextField
          type="text"
          label={<Trans i18nKey="labels.companyVat" />}
          name="companyVat"
          margin="none"
          variant="outlined"
          defaultValue={companyData.companyVat}
          onChange={(e) => setCompanyVat(e.target.value)}
        />
        {companyData.companyVat ? null : (
          <Grid sx={{ marginTop: '16px' }}>
            <Trans i18nKey="paymentForm.companyVatInvoice" />
          </Grid>
        )}
      </Grid>
      {currency === 'CZK' && (
        <Grid
          item
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            padding: '32px',
          }}
          data-testid="payment-qrcode"
        >
          <QRCode size={200} value={getQRPayments(companyData, paymentData)} />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        position={{ xs: 'sticky', lg: 'relative' }}
        bottom={{ xs: '-16px', lg: '0px' }}
        bgcolor={{ xs: 'white' }}
        sx={{
          marginTop: '16px',
        }}
      >
        <Grid
          container
          justifyContent="center"
          spacing={{ xs: 0, lg: 2 }}
          sx={{
            maxWidth: '100%',
            width: '100%',
            background: '#fff',
            padding: '16px 0',
          }}
        >
          <Grid item xs={12} sm={6} lg={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(companyData?._id)}
              sx={{
                boxShadow: 'none',
                width: '100%',
              }}
              data-testid="buy-license-modal-btn"
              disabled={!Boolean(accountInfo)}
            >
              <Trans i18nKey="paymentForm.buy" />
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Button
              type="button"
              variant="text"
              onClick={onCancel}
              sx={{
                boxShadow: 'none',
                width: '100%',
              }}
              data-testid="close-license-modal-btn"
            >
              <Trans i18nKey="labels.cancel" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
