import React, { FC } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Grid, Paper, styled } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { t } from 'i18next';

import { DynamicIcon } from './DynamicIcon';

interface ItemProps {
  img: string;
  name: string;
  description?: string;
  company?: string;
}

const CarouselWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const Logo = styled(Grid)(({ theme }) => ({
  width: '176px',
  height: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Name = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '20px',
  color: '#496AEC',
  padding: '16px',
  textAlign: 'center',
  fontWeight: '700',
}));

const Description = styled(Grid)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
  WebkitLineClamp: '7',
  fontSize: '15px',
  lineHeight: '20px',
  fontWeight: '400',
  minHeight: '140px',
}));

const Company = styled(Grid)(({ theme }) => ({
  fontSize: '13px',
  color: '#496AEC',
  padding: '16px',
  textAlign: 'center',
  fontWeight: '500',
}));

const Item = (props: { item: ItemProps }) => {
  return (
    <Paper
      sx={{
        boxShadow: 'unset',
        borderRadius: 'unset',
        backgroundColor: 'unset',
      }}
    >
      <CarouselWrapper>
        <Logo>
          <DynamicIcon name={props.item.img} />
        </Logo>
        <Name>{props.item.name}</Name>
        <Description>{props.item.description}</Description>
        <Company>
          <ApartmentIcon style={{ marginRight: '5px', fontSize: 'small' }} />
          {props.item.company}
        </Company>
      </CarouselWrapper>
    </Paper>
  );
};

export const CarouselHomePage: FC = () => {
  var items = t('carousel', { returnObjects: true }) as Array<ItemProps>;

  return (
    <Carousel interval={10000} sx={{ width: '100%' }}>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};
