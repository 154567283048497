import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Issue } from '../commonTypes';
import { UserContext } from '.';

export const IssueContext = createContext<{
  issueData: Issue[];
  getIssue: () => void;
  companyIds: string[];
  setCompanyIds: (company: string[]) => void;
}>({
  issueData: [],
  getIssue: () => {},
  companyIds: [],
  setCompanyIds: () => {},
});

export const IssueContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { userData } = useContext(UserContext);
  const [issueData, setIssueData] = useState<Issue[]>([]);
  const [companyIds, setCompanyIds] = useState<string[]>([]);

  const getIssue = useCallback(async () => {
    if (userData) {
      await fetch(`/api/issue/issue?companyIds=${companyIds.join(',')}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'GET',
      }).then(async (data) => {
        const response = await data.json();
        setIssueData(response);
      });
    }
  }, [userData, companyIds]);

  useEffect(() => {
    getIssue();
  }, [getIssue, companyIds]);

  return (
    <IssueContext.Provider
      value={{
        issueData,
        getIssue,
        companyIds,
        setCompanyIds,
      }}
    >
      {children}
    </IssueContext.Provider>
  );
};
