import { pick } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { Hardware } from 'src/commonTypes';
import { HardwareContext, IssueContext, UserContext } from 'src/contexts';
import { isValidDate } from 'src/utils';

export const useUpdateHardware = () => {
  const { setHardwareUpdate } = useContext(HardwareContext);

  const { userData } = useContext(UserContext);
  const { getIssue } = useContext(IssueContext);

  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateHardware = async (row: Hardware, editKey?: string) => {
    try {
      if (row._id) {
        const copyRow = { ...row };
        if (row.description === undefined) {
          copyRow.description = '';
        }

        if (
          row.inventoryNumber === null ||
          row?.inventoryNumber?.length === 0
        ) {
          if (row.type.isInventoryNumberRequired) {
            enqueueSnackbar(
              <Trans i18nKey="notifications.mandatoryInformationInventoryNumber" />,
              {
                variant: 'error',
              }
            );
            return;
          } else {
            copyRow.inventoryNumber = '--';
          }
        }

        if (row.serialNumber === null || row?.serialNumber?.length === 0) {
          if (row.type.isSerialNumberRequired) {
            enqueueSnackbar(
              <Trans i18nKey="notifications.mandatoryInformationSerialNumber" />,
              {
                variant: 'error',
              }
            );
            return;
          } else {
            copyRow.serialNumber = '--';
          }
        }

        if (row.price < 0) {
          enqueueSnackbar(<Trans i18nKey="notifications.wrongFormatPrice" />, {
            variant: 'error',
          });
          return;
        }
        if (isNaN(row.price) || row.price === 0) {
          if (row.type.isPriceRequired) {
            enqueueSnackbar(
              <Trans i18nKey="notifications.mandatoryInformationPrice" />,
              { variant: 'error' }
            );
            return;
          } else {
            copyRow.price = 0;
          }
        }

        if (
          row?.dateOfPurchase == null ||
          !isValidDate(new Date(row?.dateOfPurchase))
        ) {
          if (row.type.isDateOfPurchaseRequired) {
            enqueueSnackbar(
              <Trans i18nKey="notifications.wrongFormatPurchaseDate" />,
              {
                variant: 'error',
              }
            );
            return;
          }
        }

        if (row.guaranteeUntil == null) {
          if (row.type.isGuaranteeRequired) {
            enqueueSnackbar(
              <Trans i18nKey="notifications.wrongFormatQuaranteeDate" />,
              {
                variant: 'error',
              }
            );
            return;
          }
        }

        await fetch(`/api/hardware/${row._id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'PUT',
          body: JSON.stringify({
            ...pick(copyRow, [
              'type.name',
              'price',
              'borrowedBy',
              'description',
              'borrowedDate',
              'serialNumber',
              'dateOfPurchase',
              'guaranteeUntil',
              'inventoryNumber',
              'revisionNext',
              'revisionType',
              'borrowingConfirmed',
              'placement',
            ]),
            typeId: row.type._id,
          }),
        })
          .then((res) => res.json())
          .finally(() => {
            setHardwareUpdate(true);
            if (editKey === 'issue') getIssue();
          });

        enqueueSnackbar(<Trans i18nKey="notifications.saved" />, {
          variant: 'success',
        });
      }
    } catch (exception) {
      console.log(exception);
      enqueueSnackbar(<Trans i18nKey="notifications.errorWhenSaving" />, {
        variant: 'error',
      });
    }
  };

  return {
    handleUpdateHardware,
  };
};
