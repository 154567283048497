import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { UserForm } from './UserForm';
import { Employee } from '../commonTypes';
import { Dialog } from '../components';

interface CreateUserDialogProps {
  onClose?: () => void;
  onSubmit: (hw: Employee) => void;
  selectedCompany?: string;
}

export const CreateUserDialog: FC<CreateUserDialogProps> = (props) => {
  const { onClose, onSubmit, selectedCompany } = props;

  return (
    <Dialog title={<Trans i18nKey="labels.addUser" />} onClose={onClose}>
      <UserForm
        onSubmit={onSubmit}
        onCancel={onClose!}
        selectedCompany={selectedCompany}
      />
    </Dialog>
  );
};
