import React, { FC, useContext } from 'react';
import { Trans } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { UserContext } from '../contexts';
import { Hardware } from '../commonTypes';
import { Dialog } from '../components';

interface SendEmailConfirmationDialogProps {
  values?: Hardware;
  onClose?: () => void;
  onSubmit: () => void;
}
export const SendEmailConfirmationDialog: FC<
  SendEmailConfirmationDialogProps
> = (props) => {
  const { onClose, values, onSubmit } = props;
  const { userData } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    const res = await fetch(
      `/api/hardware/send-confirmation-email/${values?._id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'PUT',
      }
    ).then((res) => res.json());
    onSubmit();
    if (res.status === 200) {
      enqueueSnackbar(<Trans i18nKey="notifications.confirmationSended" />, {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(res.error, {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog
      title={<Trans i18nKey="SendConfirmationEmailDialog.title" />}
      onClose={onClose}
    >
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Trans i18nKey="SendConfirmationEmailDialog.description" />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                style={{
                  boxShadow: 'none',
                  width: '100%',
                }}
              >
                <Trans i18nKey="SendConfirmationEmailDialog.send" />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Button
                type="button"
                variant="text"
                onClick={onClose}
                style={{
                  boxShadow: 'none',
                  width: '100%',
                }}
              >
                <Trans i18nKey="SendConfirmationEmailDialog.cancel" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
