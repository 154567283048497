import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Dialog } from '../Dialog';
import { PlacementHwListForm } from '../DialogForms';

interface PlacementHwListDialogProps {
  placementID: string;
  onClose: () => void;
}

export const PlacementHwListDialog: FC<PlacementHwListDialogProps> = (
  props
) => {
  const { placementID, onClose } = props;

  return (
    <Dialog title={<Trans i18nKey="placementDialog.title" />} onClose={onClose}>
      <PlacementHwListForm placementID={placementID} onCancel={onClose} />
    </Dialog>
  );
};
