import { Company, PaymentDetails } from '../commonTypes';

export const getQRPlacement = (placementID: string) => {
  const urlWithoutPathname = window.location.origin;
  return `${urlWithoutPathname}/hardware-placement/${placementID}`;
};

export const getQRPayments = (
  companyData?: Company,
  paymentDetails?: PaymentDetails
) => {
  return `SPD*1.0*ACC:${paymentDetails?.companyIban}*AM:${paymentDetails?.totalSumToPay}*CC:CZK*PT:IP*MSG:Platba za licenci www.evhard.cz pro spolecnost ${companyData?.companyName}`;
};

export const getQRQuide = (hwID: String) => {
  const urlWithoutPathname = window.location.origin;
  return `${urlWithoutPathname}/guide/${hwID}`;
};

export const getQRSticker = (hwID: String) => {
  return `*HWID:${hwID}`;
};
