import React, { FC, useCallback, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Box,
} from '@mui/material';

import { HardwarePlacementContext } from '../../contexts';
import { PlacementType } from '../../commonTypes';
import { AddHardwarePlacementDialog } from '../../hardwarePlacement';

interface PlacementSelectFieldProps {
  error?: string | boolean;
  value?: string;
  disabled?: boolean;
  defaultValue?: string;
  onChange: (placement: PlacementType | null) => void;
  companyId?: string;
  disableTitle?: boolean;
  showAddNew?: boolean;
}

const renderItems = (placements: PlacementType[] = []) => {
  return placements.map((item) => (
    <MenuItem key={item._id} value={item._id}>
      {item.name}
    </MenuItem>
  ));
};

export const PlacementSelectField: FC<PlacementSelectFieldProps> = (props) => {
  const {
    onChange,
    companyId,
    defaultValue,
    disableTitle,
    disabled,
    error,
    showAddNew,
    value,
  } = props;

  const { globalHardwarePlacements, refreshHardwarePlacements } = useContext(
    HardwarePlacementContext
  );
  const [showAddPlacementDialog, setShowAddPlacementDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const options = renderItems(
    globalHardwarePlacements?.filter((a) => a.companyId === companyId)
  );

  const handleOnSubmitNewPlacement = useCallback(
    async (placement: PlacementType | null) => {
      try {
        await refreshHardwarePlacements?.();
        setShowAddPlacementDialog(false);
        setIsOpen(false);
        onChange(placement);
      } catch (error) {
        console.log(error);
      }
    },
    [globalHardwarePlacements, refreshHardwarePlacements] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    const selectedPlacement = globalHardwarePlacements?.find(
      ({ _id: id }) => id === selectedValue
    );
    onChange(selectedPlacement ? selectedPlacement : null);
  };

  return (
    <>
      {showAddPlacementDialog ? (
        <AddHardwarePlacementDialog
          onClose={() => {
            onChange(null);
            setShowAddPlacementDialog(false);
          }}
          onSubmit={handleOnSubmitNewPlacement}
          selectedCompany={companyId}
        />
      ) : null}

      <FormControl error={!!error} fullWidth>
        <FormControl fullWidth disabled={disabled}>
          {!disableTitle && (
            <InputLabel id="placement">
              <Trans i18nKey="labels.placement" />
            </InputLabel>
          )}
          <Select
            defaultValue={defaultValue}
            labelId="placement"
            id="revision-select"
            label={!disableTitle ? <Trans i18nKey="labels.placement" /> : null}
            onChange={handleChange}
            value={value}
            inputProps={{ value: value ?? '' }}
            open={isOpen}
            onClick={() => !disabled && setIsOpen((prev) => !prev)}
          >
            <MenuItem value={-1}>
              <Trans i18nKey="labels.select" />
            </MenuItem>
            {options}
            {showAddNew && (
              <Box
                onClick={() => setShowAddPlacementDialog(true)}
                sx={{
                  '&:last-of-type': {
                    color: 'primary.dark',
                    padding: '8px 16px',
                    cursor: 'pointer',
                    fontWeight: '500',
                    borderTop: (theme) =>
                      '1px solid ' + theme.palette.warning.light,
                    '&:hover': { bgcolor: 'warning.light' },
                  },
                }}
              >
                <Trans i18nKey="labels.hwPlacement" />
              </Box>
            )}
          </Select>
        </FormControl>
      </FormControl>
    </>
  );
};
