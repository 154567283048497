import MaterialTable, { Column } from '@material-table/core';
import { format } from 'date-fns';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Tasks } from 'src/commonTypes';
import { UserContext } from 'src/contexts';
import {
  CustomTablePagination,
  StyledTableColumnTitle,
  StyledTableTitle,
} from 'src/customStyledComponents';
import { useMaterialTableLocalization } from 'src/hooks';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Chip, Grid, Paper } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { RemoveRedEye } from '@mui/icons-material';
import { TaskDialog } from '../../components/Dialogs/TaskDialog';
import { TaskDetailDialog } from '../../components/Dialogs/TaskDetailDialog';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

interface TableState {
  columns: Array<Column<Tasks>>;
  data: Tasks[];
}

export interface TasksCardFunctions {
  refetch: () => void;
}

interface TaskCardProps {
  filterByCompany?: string[];
  refetchEventsTable?: () => void;
}

enum TaskStatus {
  OWN = 'own',
  UNASSIGNED = 'unassigned',
  ALL = 'all',
}

const TasksCard = forwardRef<TasksCardFunctions, TaskCardProps>(
  (props, ref) => {
    const localization = useMaterialTableLocalization();
    const { userData } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();

    const [refetchTable, setRefetchTable] = useState(false);

    const [tableData, setTableData] = useState<TableState>({
      columns: [],
      data: [],
    });
    const { id } = useParams<{ id: string }>();

    const [tasksFilter, setTasksFilter] = useState<TaskStatus>(TaskStatus.ALL);
    const filterByCompany = props.filterByCompany;

    const [openTaskForm, setOpenTaskForm] = useState<boolean | string>(false);
    const [openTaskDetail, setOpenTaskDetail] = useState<string>();

    const hasUserRole = !!userData?.user?.appRole;

    const getTasks = useCallback(async () => {
      const res = await fetch(
        `/api/tasks/${id}?filterByStatus=${tasksFilter}&filterByCompany=${filterByCompany}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'GET',
        }
      ).then((data) => data.json());
      setTableData({ ...tableData, data: res });
      setRefetchTable(false);
      return res;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, tasksFilter, userData, tableData, refetchTable, filterByCompany]);

    useImperativeHandle(ref, () => ({
      refetch: () => getTasks(),
    }));

    useEffect(() => {
      getTasks();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasksFilter, refetchTable, filterByCompany]);

    const handleDelete = async (id: string) => {
      await fetch(`/api/tasks/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'DELETE',
      });
      enqueueSnackbar(<Trans i18nKey="labels.deleted" />, {
        variant: 'success',
      });

      getTasks();
    };

    const getUsersEmail = (userId?: string) => {
      const userEmail =
        userData?.usersData?.find((a) => a?._id === userId)?.email ?? '';
      return userEmail;
    };

    const dataTable: TableState = {
      columns: [
        {
          title: (
            <StyledTableColumnTitle>
              <Trans i18nKey="tables.tasksCard.name" />
            </StyledTableColumnTitle>
          ),
          field: 'name',
          render: (row) => {
            return <div>{row?.name || '--'}</div>;
          },
        },
        {
          title: (
            <StyledTableColumnTitle>
              <Trans i18nKey="tables.tasksCard.deadline" />
            </StyledTableColumnTitle>
          ),
          field: 'deadline',
          render: (row) => {
            const date = format(new Date(row?.deadline), 'dd.MM.yyyy');

            return <div>{date || '--'}</div>;
          },
        },
        {
          title: (
            <StyledTableColumnTitle>
              <Trans i18nKey="tables.tasksCard.assigneeName" />
            </StyledTableColumnTitle>
          ),
          field: 'assigneeName',
          render: (row) => {
            const userEmail = getUsersEmail(row.assignee);
            return <div>{userEmail || '--'}</div>;
          },
        },
        {
          title: (
            <StyledTableColumnTitle>
              <Trans i18nKey="tables.tasksCard.status" />
            </StyledTableColumnTitle>
          ),
          field: 'status',
          render: (row) => {
            // t("tables.tasksCard.new")
            // t("tables.tasksCard.in_progress")
            // t("tables.tasksCard.done")
            return <div>{t(`tables.tasksCard.${row?.status}`) || '--'}</div>;
          },
        },
        {
          title: (
            <StyledTableColumnTitle>
              <Trans i18nKey="tables.tasksCard.placementName" />
            </StyledTableColumnTitle>
          ),
          field: 'placementName',
          render: (row) => {
            return <div>{row?.placementNames || '--'}</div>;
          },
        },
      ],
      data: [],
    };

    return (
      <div style={{ marginTop: '16px' }}>
        <Paper>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '16px',
            }}
          >
            <Grid item sx={{ marginRight: '24px' }}>
              <Trans i18nKey="tasksCard.tasks" />
            </Grid>
            <Chip
              avatar={
                tasksFilter === TaskStatus.OWN ? (
                  <DoneIcon color="error" />
                ) : undefined
              }
              label={<Trans i18nKey="tasksCard.myTasks" />}
              variant={tasksFilter === TaskStatus.OWN ? 'filled' : 'outlined'}
              color="primary"
              onClick={() => {
                if (tasksFilter === TaskStatus.OWN) {
                  setTasksFilter(TaskStatus.ALL);
                } else {
                  setTasksFilter(TaskStatus.OWN);
                }
              }}
              sx={{
                marginRight: '8px',
                borderRadius: '8px',
                '& .MuiChip-avatar': { backgroundColor: 'transparent' },
              }}
            />
            <Chip
              avatar={
                tasksFilter === TaskStatus.UNASSIGNED ? (
                  <DoneIcon color="error" />
                ) : undefined
              }
              label={<Trans i18nKey="tasksCard.unassignedTasks" />}
              variant={
                tasksFilter === TaskStatus.UNASSIGNED ? 'filled' : 'outlined'
              }
              color="primary"
              onClick={() => {
                if (tasksFilter === TaskStatus.UNASSIGNED) {
                  setTasksFilter(TaskStatus.ALL);
                } else {
                  setTasksFilter(TaskStatus.UNASSIGNED);
                }
              }}
              sx={{
                borderRadius: '8px',
                '& .MuiChip-avatar': { backgroundColor: 'transparent' },
              }}
            />
            {hasUserRole ? (
              <>
                <Box flex={1} />
                <Button onClick={() => setOpenTaskForm(true)}>
                  {<Trans i18nKey="tasks.addTask" />}
                </Button>
              </>
            ) : null}
          </Grid>
          <MaterialTable
            title={
              <StyledTableTitle>
                <Trans i18nKey="tables.tasksCard.description" />
              </StyledTableTitle>
            }
            localization={localization}
            actions={
              hasUserRole
                ? [
                    (a) => ({
                      icon: EditIcon,
                      onClick: () => setOpenTaskForm(a._id),
                    }),
                    (a) => ({
                      icon: RemoveRedEye,
                      onClick: () => setOpenTaskDetail(a._id),
                    }),
                  ]
                : []
            }
            columns={dataTable.columns}
            data={tableData.data}
            style={{
              fontSize: '13px',
            }}
            components={{
              Pagination: CustomTablePagination,
            }}
            options={{
              actionsColumnIndex: -1,
              showTitle: false,
            }}
          />
        </Paper>

        {openTaskDetail && (
          <TaskDetailDialog
            handleDelete={handleDelete}
            onClose={() => setOpenTaskDetail(undefined)}
            taskId={openTaskDetail}
            refetchTable={(state: boolean) => setRefetchTable(state)}
            refetchEventsTable={() => props.refetchEventsTable?.()}
          />
        )}
        {openTaskForm && (
          <TaskDialog
            taskId={typeof openTaskForm === 'string' ? openTaskForm : undefined}
            onSubmit={() => {
              setOpenTaskForm(false);
              getTasks();
            }}
            onClose={() => {
              setOpenTaskForm(false);
            }}
          />
        )}
      </div>
    );
  }
);

export default TasksCard;
