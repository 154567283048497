import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { NewHwForm } from './NewHwForm';
import { Hardware } from '../commonTypes';
import { Dialog } from '../components';

interface AddHardwareDialogProps {
  onClose?: () => void;
  onSubmit: (hw: Hardware[]) => void;
  printSticker: (hw: Hardware[]) => void;
  imageUploader?: (hw: Hardware[]) => void;
}

export const AddHardwareDialog: FC<AddHardwareDialogProps> = (props) => {
  const { onClose, onSubmit, printSticker, imageUploader } = props;

  return (
    <Dialog title={<Trans i18nKey="labels.addHardware" />} onClose={onClose}>
      <NewHwForm
        onSubmit={onSubmit}
        onCancel={onClose!}
        printSticker={printSticker}
        imageUploader={imageUploader}
      />
    </Dialog>
  );
};
