import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { Trans } from 'react-i18next';
import { get } from 'lodash';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { DatePicker } from 'mui-rff';

import { FormData } from './interfaces';
import { Employee } from '../../commonTypes';
import { UserSelectField } from '../../users';

interface BorrowFieldsProps {
  values: FormData;
  typePrefix: string[];
  dynamicFieldsArrayCount: number[];
}

export const BorrowFields: FC<BorrowFieldsProps> = (props) => {
  const { values, typePrefix, dynamicFieldsArrayCount } = props;

  return (
    <>
      <Grid item xs={12}>
        <Field name="borrow" type="checkbox">
          {(props) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...props.input}
                  // @ts-ignore
                  inputProps={{ 'data-testid': 'add-borrower-chekbox' }}
                />
              }
              label={<Trans i18nKey="addHardwareForm.revisionCheckboxLabel" />}
            />
          )}
        </Field>
      </Grid>

      {values.borrow ? (
        <>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field name={`borrowedBy1`}>
                  {(props) => {
                    return (
                      <UserSelectField
                        selectedValue={values.companyId}
                        value={get(props, 'input.value', undefined)}
                        onChange={(emp?: Employee) =>
                          props.input.onChange(emp?._id)
                        }
                        showAddNew
                        disabled={!Boolean(values.companyId)}
                        startAdornment={typePrefix.length > 1 ? `1.` : null}
                      />
                    );
                  }}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  name={`borrowedDate1`}
                  inputFormat="dd.MM.yyyy"
                  label={<Trans i18nKey="addHardwareForm.borrowDate" />}
                  // @ts-ignore
                  inputProps={{
                    'data-testid': 'hw-borrow-date-field',
                  }}
                  disabled={!Boolean(values.companyId)}
                />
              </Grid>
            </Grid>
          </Grid>
          {dynamicFieldsArrayCount.map((_, index) => (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field name={`borrowedBy${index + 2}`}>
                    {(props) => {
                      return (
                        <UserSelectField
                          selectedValue={values.companyId}
                          value={get(props, 'input.value', undefined)}
                          onChange={(emp?: Employee) =>
                            props.input.onChange(emp?._id)
                          }
                          showAddNew
                          disabled={!Boolean(values.companyId)}
                          startAdornment={`${index + 2}.`}
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    name={`borrowedDate${index + 2}`}
                    inputFormat="dd.MM.yyyy"
                    label={<Trans i18nKey="addHardwareForm.borrowDate" />}
                    disabled={!Boolean(values.companyId)}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </>
      ) : null}
    </>
  );
};
