import React, { FC, useContext, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { UserContext } from '../contexts';
import { Trans } from '../i18n';
import { Hardware } from '../commonTypes';

interface IssueManagementFormProps {
  onClose: () => void;
  hwData: Hardware;
}

export const IssueManagementForm: FC<IssueManagementFormProps> = (props) => {
  const { onClose, hwData } = props;

  const { userData } = useContext(UserContext);

  const [issueType, setIssueType] = useState('broken');
  const [issueDescription, setIssueDescription] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (issueType: string, issueDescription: string) => {
    if (!issueDescription) {
      enqueueSnackbar(
        <Trans i18nKey="notifications.errorTheDescriptionFieldCannotBeEmpty" />,
        {
          variant: 'error',
        }
      );
      return;
    }
    if (!issueType) {
      enqueueSnackbar(
        <Trans i18nKey="notifications.errorYouMustSelectHardwareStatus" />,
        {
          variant: 'error',
        }
      );
      return;
    }
    const data = {
      hwId: hwData._id,
      createdBy: userData?.user._id,
      issueType,
      issueDescription,
    };

    const res = await fetch('/api/issue/', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (res.status !== 200) {
      const resJSON = await res.json();
      enqueueSnackbar(resJSON.error, {
        variant: 'error',
      });
    } else {
      enqueueSnackbar(<Trans i18nKey="notifications.saved" />, {
        variant: 'success',
      });
      onClose();
    }
  };

  return (
    <Grid>
      <FormControl>
        <RadioGroup name="radio-buttons-group" defaultValue="broken">
          <FormControlLabel
            value="broken"
            control={
              <Radio
                onChange={(e) => {
                  setIssueType(e.target.value);
                }}
              />
            }
            label={<Trans i18nKey="issue.broken" />}
          />
          <FormControlLabel
            value="missing"
            control={
              <Radio
                onChange={(e) => {
                  setIssueType(e.target.value);
                }}
              />
            }
            label={<Trans i18nKey="issue.missing" />}
          />
        </RadioGroup>
      </FormControl>
      <TextField
        id="outlined-multiline-static"
        multiline
        sx={{ width: '100%' }}
        onBlur={(e) => {
          setIssueDescription(e.target.value);
        }}
        label={<Trans i18nKey="issue.description" />}
      />
      <Grid item xs={12} style={{ marginTop: 16 }}>
        <Grid container justifyContent="center" xs={12}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="contained"
              fullWidth
              onClick={() => {
                handleSubmit(issueType, issueDescription);
              }}
              sx={{
                marginBottom: '8px',
              }}
            >
              <Trans i18nKey="labels.save" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button type="button" fullWidth onClick={onClose}>
              <Trans i18nKey="labels.back" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
