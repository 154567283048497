import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { FileUploadForm } from './FileUploadForm';
import { SharedFileUploadProps } from './interfaces';
import { Dialog } from '../Dialog';

export const FileUploadDialog: FC<SharedFileUploadProps> = (props) => {
  const { onClose, documentId: hwId, onSelect } = props;

  return (
    <Dialog title={<Trans i18nKey="fileDialog.title" />} onClose={onClose}>
      <FileUploadForm onClose={onClose} documentId={hwId} onSelect={onSelect} />
    </Dialog>
  );
};
