import React, { FC, useState, MouseEvent } from 'react';
import { Form } from 'react-final-form';
import { Button, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { Trans } from '../i18n';
import { CompanyUser } from '../commonTypes';
import { Dialog } from '../components';
import { StyledGrid } from '../customStyledComponents';

interface UsersPageEditDialogProps {
  onClose: () => void;
  companyUser: CompanyUser;
  onUpdate: (cu: CompanyUser) => void;
}

export const UsersPageEditDialog: FC<UsersPageEditDialogProps> = (props) => {
  const { onClose, companyUser, onUpdate } = props;

  const [userRole, setUserRole] = useState(companyUser.userData?.userRole);

  const handleSubmit = (data: CompanyUser) => {
    if (data && userRole) {
      onUpdate({
        ...data,
        userData: { ...data.userData, userRole: userRole },
      });
    }
    onClose();
  };

  const handleChange = (
    _event: MouseEvent<HTMLElement>,
    newUserRole: string | null
  ) => {
    if (newUserRole === null) {
      setUserRole(userRole);
    } else {
      setUserRole(newUserRole);
    }
  };

  const initialValues: CompanyUser = {
    companyId: companyUser.companyId,
    companyName: companyUser.companyName,
    isDeleted: companyUser.isDeleted,
    uid: companyUser.uid,
    userRole: companyUser.userRole,
    isEdit: companyUser.isEdit,
    userData: companyUser.userData,
  };

  return (
    <Dialog
      title={
        companyUser.userData.email ?? <Trans i18nKey="detail.detailTitle" />
      }
      onClose={onClose}
    >
      <>
        <Form
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid>
                  <Grid xs={12} sx={{ marginBottom: '20px' }}>
                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="usersPageEditDialog.authorization" />
                        </Grid>
                        <Grid item xs={6}>
                          <ToggleButtonGroup
                            color="primary"
                            value={userRole}
                            exclusive
                            onChange={handleChange}
                          >
                            <ToggleButton
                              value="Admin"
                              data-testid="edit-toggle-admin"
                            >
                              <Trans i18nKey="usersForm.admin" />
                            </ToggleButton>
                            <ToggleButton
                              value="Editor"
                              data-testid="edit-toggle-editor"
                            >
                              <Trans i18nKey="usersForm.editor" />
                            </ToggleButton>
                            <ToggleButton
                              value="User"
                              data-testid="edit-toggle-user"
                            >
                              <Trans i18nKey="usersForm.user" />
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Grid>
                      </Grid>
                    </StyledGrid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    xs={12}
                    sx={{
                      width: '100%',
                      position: 'sticky',
                      left: '0px',
                      bottom: '-18px',
                      padding: '16px 0px',
                      background: 'white',
                    }}
                  >
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                        style={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.save" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        type="button"
                        variant="text"
                        onClick={onClose}
                        style={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.cancel" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </>
    </Dialog>
  );
};
