import React, { FC, useState } from 'react';
import { t } from 'i18next';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Trans } from '../../i18n';
import { SelectProps } from '../../commonTypes';

export const StickerOrGuideSelect: FC<SelectProps<string>> = (props) => {
  const { defaultValue, onChange, error } = props;
  const [defVal, setDefVal] = useState(defaultValue);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
    setDefVal(selectedValue);
  };

  return (
    <FormControl error={!!error} fullWidth>
      <Select
        value={defVal}
        defaultValue={t('stickerOrGuideSelect.choose') as string}
        onChange={handleChange}
        renderValue={(selected?: string) => {
          if (!selected) {
            return (
              <FormHelperText sx={{ color: '#0000004D' }}>
                <Trans i18nKey="stickerOrGuideSelect.choose" />
              </FormHelperText>
            );
          }

          if (selected === 'Guide')
            return <Trans i18nKey="stickerOrGuideSelect.guide" />;
          else if (selected === 'Sticker')
            return <Trans i18nKey="stickerOrGuideSelect.sticker" />;
          else return <Trans i18nKey="stickerOrGuideSelect.choose" />;
        }}
      >
        <MenuItem disabled value={undefined}>
          <FormHelperText>
            <Trans i18nKey="stickerOrGuideSelect.choose" />
          </FormHelperText>
        </MenuItem>
        <MenuItem value={'Sticker'}>
          <Trans i18nKey="stickerOrGuideSelect.sticker" />
        </MenuItem>
        <MenuItem value={'Guide'}>
          <Trans i18nKey="stickerOrGuideSelect.guide" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};
