import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Localization } from '@material-table/core';

import { Trans } from '../i18n';

export const useMaterialTableLocalization = (): Localization => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      header: {
        actions: <Trans i18nKey="tables.common.actions" />,
      },
      body: {
        editTooltip: <Trans i18nKey="tables.common.editTooltip" />,
        emptyDataSourceMessage: (
          <Trans i18nKey="tables.common.emptyDataSourceMessage" />
        ),
      },
      toolbar: {
        searchPlaceholder: t('tables.common.searchPlaceholder'),
      },
      pagination: {
        labelRowsPerPage: <Trans i18nKey="tables.common.labelRowsPerPage" />,
        labelRowsSelect: t('tables.common.labelRowsSelect'),
        labelDisplayedRows: t('tables.common.labelDisplayedRows'),
        nextTooltip: <Trans i18nKey="tables.common.nextTooltip" />,
        previousTooltip: <Trans i18nKey="tables.common.previousTooltip" />,
        firstTooltip: <Trans i18nKey="tables.common.firstTooltip" />,
        lastTooltip: <Trans i18nKey="tables.common.lastTooltip" />,
      },
    }),
    [t]
  );
};
