import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { Dialog } from '../Dialog';
import { StickerForm } from '../DialogForms';

interface StickerDialogProps {
  onClose: () => void;
  data: string[];
  variant?: 'placement' | 'guide';
  defaultStickerType?: string;
}

export const StickerDialog: FC<StickerDialogProps> = (props) => {
  const { onClose, data, variant, defaultStickerType } = props;

  return (
    <Dialog title={<Trans i18nKey="stickerDialog.title" />} onClose={onClose}>
      <StickerForm
        onCancel={onClose}
        data={data}
        variant={variant}
        defaultStickerType={defaultStickerType}
      />
    </Dialog>
  );
};
