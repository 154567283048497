import React, { forwardRef } from 'react';
import { Icons } from '@material-table/core';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SearchIcon from '@mui/icons-material/Search';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

export const createTableIcons = (tableName: string): Icons => {
  return {
    Add: forwardRef((props, ref) => <AddBoxIcon {...props} ref={ref} />),
    Check: forwardRef((props, ref) => (
      <CheckIcon {...props} ref={ref} data-testid={`check-${tableName}-item`} />
    )),
    Clear: forwardRef((props, ref) => (
      <ClearIcon {...props} ref={ref} data-testid={`clear-${tableName}-item`} />
    )),
    Delete: forwardRef((props, ref) => (
      <DeleteOutlineOutlinedIcon {...props} ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRightIcon {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => (
      <EditIcon {...props} ref={ref} data-testid={`edit-${tableName}-item`} />
    )),
    Export: forwardRef((props, ref) => <SaveAltIcon {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterListIcon {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => (
      <FirstPageIcon {...props} ref={ref} />
    )),
    LastPage: forwardRef((props, ref) => (
      <LastPageIcon {...props} ref={ref} data-testid="table-last-page-btn" />
    )),
    NextPage: forwardRef((props, ref) => (
      <ChevronRightIcon {...props} ref={ref} />
    )),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeftIcon {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownwardIcon {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <RemoveIcon {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => (
      <ViewColumnIcon {...props} ref={ref} />
    )),
  };
};
