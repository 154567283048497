import React, { memo } from 'react';
import { CircularProgress, styled } from '@mui/material';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  textAlign: 'center',
});

export const Loading = memo(() => {
  return (
    <Root>
      <CircularProgress color="primary" />
    </Root>
  );
});
