import React, { useContext, useState, useMemo, FC, ReactText } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Grid,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MaterialTable, { Column } from '@material-table/core';
import { useSnackbar } from 'notistack';

import { UserContext, UsersAppRoleEnum } from '../contexts';
import { useMaterialTableLocalization } from '../hooks';
import { useGetTableRows, createTableIcons, tableEnum } from '../utils';
import { Company, UserData } from '../commonTypes';
import { AddNewUserDialog } from '../users';
import {
  AddButton,
  CustomTablePagination,
  StyledTableColumnTitle,
  StyledTableTitle,
} from '../customStyledComponents';
import { AlertDialog } from '../components';

interface TableState {
  columns: Array<Column<UserData>>;
  data: UserData[];
}

const ServerUsersPage: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { userData, changeTableRows, refreshUser, checkRole } =
    useContext(UserContext);
  const [isAddNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false);
  const localization = useMaterialTableLocalization();
  const [openAlertDialog, setOpenAlertDialog] = useState<ReactText>();

  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const usersTableIcons = createTableIcons('user');

  const handleChange = (val: string, onChange: (newValue: string) => void) => {
    if (val !== null) {
      onChange(val);
    }
  };

  const dataTable: TableState = useMemo(() => {
    return {
      columns: [
        {
          title: (
            <StyledTableColumnTitle>
              <Trans i18nKey="tables.usersList.email" />
            </StyledTableColumnTitle>
          ),
          field: 'email',
          editable: 'never',
          render: (row) => {
            return <div>{row?.email ?? '--'}</div>;
          },
        },
        {
          title: (
            <StyledTableColumnTitle>
              <Trans i18nKey="tables.usersList.authorization" />
            </StyledTableColumnTitle>
          ),
          field: 'appRole',
          editComponent: ({ value, onChange }) => {
            return (
              <Grid>
                <ToggleButtonGroup
                  color="primary"
                  value={value}
                  exclusive
                  onChange={(_e, val) => handleChange(val, onChange)}
                >
                  <ToggleButton value="admin">
                    <Trans i18nKey="usersForm.admin" />
                  </ToggleButton>
                  <ToggleButton value="editor">
                    <Trans i18nKey="usersForm.editor" />
                  </ToggleButton>
                  <ToggleButton value="user">
                    <Trans i18nKey="usersForm.user" />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Grid>
                  <Grid>
                    <strong>
                      <Trans i18nKey="usersForm.admin" />:
                    </strong>
                    <Trans i18nKey="usersForm.serverAdminDescription" />
                  </Grid>
                  <Grid>
                    <strong>
                      <Trans i18nKey="usersForm.editor" />:
                    </strong>
                    <Trans i18nKey="usersForm.serverEditorDescription" />
                  </Grid>
                  <Grid>
                    <strong>
                      <Trans i18nKey="usersForm.user" />:
                    </strong>
                    <Trans i18nKey="usersForm.serverUserDescription" />
                  </Grid>
                </Grid>
              </Grid>
            );
          },
          render: (row) => {
            return <div>{row?.appRole ?? '--'}</div>;
          },
        },
      ],
      data: [],
    };
  }, []);

  const closeAlertDialog = (index?: ReactText) => {
    setOpenAlertDialog(index);
  };

  const updatedItem = async (row: UserData) => {
    const updateObject = {
      ...row,
      appRole: row.appRole,
    };

    try {
      const res = await fetch(`/api/users/user/server-license/update-user`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'PUT',
        body: JSON.stringify(updateObject),
      });
      if (res.status === 200) {
        refreshUser?.();
        enqueueSnackbar(<Trans i18nKey="notifications.saved" />, {
          variant: 'success',
        });
      }
    } catch (exception) {}
  };

  const handleDelete = async (id: React.ReactText) => {
    try {
      const res = await fetch(
        `/api/users/user/server-license/delete-user/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'DELETE',
        }
      );
      if (res.status === 200) {
        refreshUser?.();
        enqueueSnackbar(<Trans i18nKey="notifications.deleted" />, {
          variant: 'success',
        });
      }
    } catch (exception) {}
  };

  return (
    <>
      <AddButton
        type="button"
        color="primary"
        variant="contained"
        onClick={() => setAddNewUserDialogOpen(!isAddNewUserDialogOpen)}
      >
        <AddIcon fontSize="large" />
      </AddButton>

      <Grid
        display={{ xs: 'flex' }}
        flexDirection={{ xs: 'column', lg: 'unset' }}
        flexWrap={{ lg: 'wrap' }}
      >
        <Grid
          container
          style={{ marginBottom: 16 }}
          justifyContent={{ xs: 'flex-end', lg: 'flex-start' }}
          order={{ xs: 1 }}
          width={{ xs: '100%', lg: '50%' }}
        >
          <Grid item xs={12} lg={6}>
            <Typography
              variant="h5"
              sx={{ fontWeight: '700', color: '#555555' }}
            >
              <Trans i18nKey="pages.usersList.title" />
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={6}
          display={{ sm: 'flex' }}
          alignContent={{ sm: 'baseline' }}
          justifyContent={{ xs: 'flex-start', lg: 'flex-end' }}
          order={{ xs: 3, lg: 2 }}
          width={{ xs: '100%', lg: '50%' }}
          zIndex={{ xs: 10 }}
        >
          {!lgDown ? (
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={() => setAddNewUserDialogOpen(true)}
              sx={{ boxShadow: 'none' }}
            >
              <Trans i18nKey="labels.createNewUser" />
            </Button>
          ) : null}
        </Grid>

        <Grid
          order={{ xs: 2, lg: 3 }}
          marginBottom={{ xs: '16px', lg: 'unset' }}
          width={{ xs: '100%' }}
        >
          <MaterialTable
            title={
              <StyledTableTitle>
                <Trans i18nKey="tables.usersList.title" />
              </StyledTableTitle>
            }
            localization={localization}
            icons={usersTableIcons}
            columns={dataTable.columns}
            data={userData?.usersData || []}
            style={{
              fontSize: '13px',
            }}
            components={{
              Pagination: CustomTablePagination,
            }}
            editable={{
              isEditable: (rowData) =>
                checkRole(UsersAppRoleEnum.ADMIN) &&
                rowData._id !== userData?.user._id,
              onRowUpdate: (newRow) => {
                const data = newRow;
                updatedItem(data);
                return Promise.resolve();
              },
            }}
            onRowsPerPageChange={(value) => {
              changeTableRows?.(tableEnum.usersTable, value);
            }}
            actions={[
              (rowData) => ({
                icon: () => {
                  if (
                    checkRole(UsersAppRoleEnum.ADMIN) &&
                    rowData._id !== userData?.user._id
                  ) {
                    return (
                      <Button
                        onClick={() => {
                          setOpenAlertDialog(rowData._id);
                        }}
                        color="error"
                        variant="contained"
                        sx={{ boxShadow: 'none', minWidth: '50px' }}
                      >
                        <DeleteOutlineIcon />
                      </Button>
                    );
                  } else {
                    return (
                      <Button
                        disabled
                        sx={{ boxShadow: 'none', minWidth: '50px' }}
                      >
                        <DeleteOutlineIcon />
                      </Button>
                    );
                  }
                },
                tooltip: t('tables.usersList.deleteUser') as string,
                onClick: (_event, _rowData) => {},
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              pageSize: useGetTableRows(tableEnum.usersTable),
            }}
          />
        </Grid>
      </Grid>

      {isAddNewUserDialogOpen ? (
        <AddNewUserDialog
          onClose={() => setAddNewUserDialogOpen(false)}
          onSubmit={(_company: Company) => {
            setAddNewUserDialogOpen(false);
          }}
        />
      ) : null}
      {openAlertDialog ? (
        <AlertDialog
          onClose={() => closeAlertDialog()}
          onDelete={() => {
            handleDelete(openAlertDialog);
          }}
        />
      ) : null}
    </>
  );
};

export default ServerUsersPage;
