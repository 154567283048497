import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';

import { FormBase } from './FormBase';
import { Validations } from '../utils';
import { Trans } from '../i18n';
import { FormFields } from '../commonTypes';

interface FormData {
  email?: string;
  password?: string;
  repeatPassword?: string;
  companyName?: string;
  companyVat?: string;
}

const initialValues: FormData = {
  email: '@',
  password: '',
  repeatPassword: '',
  companyName: '',
  companyVat: '',
};

const formFields: FormFields[] = [
  {
    size: 12,
    field: (
      <TextField
        label={<Trans i18nKey="labels.email" />}
        type="email"
        name="email"
        margin="none"
        variant="outlined"
        required
        inputProps={{ 'data-testid': 'register-email-input' }}
      />
    ),
  },
  {
    size: 12,
    field: (
      <TextField
        type="password"
        label={<Trans i18nKey="labels.password" />}
        name="password"
        margin="none"
        variant="outlined"
        required
        inputProps={{ 'data-testid': 'register-password-input' }}
      />
    ),
  },
  {
    size: 12,
    field: (
      <TextField
        type="password"
        label={<Trans i18nKey="labels.password" />}
        name="repeatPassword"
        margin="none"
        variant="outlined"
        required
        inputProps={{ 'data-testid': 'register-confirm-password-input' }}
      />
    ),
  },
  {
    size: 12,
    field: (
      <TextField
        type="text"
        label={<Trans i18nKey="labels.companyName" />}
        name="companyName"
        margin="none"
        variant="outlined"
        required
        inputProps={{ 'data-testid': 'register-company-name-input' }}
      />
    ),
  },
  {
    size: 12,
    field: (
      <TextField
        type="text"
        label={<Trans i18nKey="labels.companyVat" />}
        name="companyVat"
        margin="none"
        variant="outlined"
      />
    ),
  },
];

const validate = (values: FormData) => {
  const errors = Validations.new(values)
    .required('email')
    .required('password')
    .required('repeatPassword')
    .emailShape('email')
    .required('companyName')
    .minOrEqual('password', 7, <Trans i18nKey="notifications.weakPassword" />)
    .getErrors();

  if (values.password !== values.repeatPassword) {
    errors.repeatPassword = (
      <Trans i18nKey="notifications.notMatchingPasswords" />
    );
  }

  return errors;
};

export const RegisterForm: FC = () => {
  const [formKey, setFormKey] = useState(Date.now());

  const { i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values: FormData) => {
    const res = await fetch('/api/users/user/register', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ ...values, preferredLanguage: i18n.language }),
    });

    if (res.status === 200) {
      enqueueSnackbar(<Trans i18nKey="notifications.userWasRegistered" />, {
        variant: 'success',
      });

      setFormKey(Date.now());
    }
    if (res.status === 400) {
      const responseStatus = await res.json();
      const errorMessage = responseStatus.error;
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  return (
    <FormBase
      key={formKey}
      fields={formFields}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      type="register"
    />
  );
};
