import React, { FC, useContext } from 'react';
import { Trans } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import QRCode from 'react-qr-code';
import { Buffer } from 'buffer';
import { Button, Grid } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import { useSnackbar } from 'notistack';

import {
  StickerSelect,
  StickerStartAtPositionSelect,
  StickerOrGuideSelect,
} from '../Fields';
import { UserContext } from '../../contexts';
import { Validations, getQRQuide, getQRSticker } from '../../utils';

interface StickerFormProps {
  onCancel: () => void;
  data: string[];
  variant?: 'placement' | 'guide';
  defaultStickerType?: string;
}

interface FormData {
  stickerOrGuide: string;
  stickerFormat: string;
  stickerStartAtPosition: number;
}

export const StickerForm: FC<StickerFormProps> = (props) => {
  const { onCancel, data, variant, defaultStickerType } = props;

  const { userData } = useContext(UserContext);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (formValues: FormData) => {
    if (!formValues.stickerFormat) {
      return enqueueSnackbar(
        <Trans i18nKey="notifications.errorFormatIsNotSelected" />,
        {
          variant: 'error',
        }
      );
    }

    if (!formValues.stickerOrGuide) {
      return enqueueSnackbar(
        <Trans i18nKey="notifications.errorStickerOrGuideIsNotSelected" />,
        {
          variant: 'error',
        }
      );
    }

    if (data.length === 0) {
      return enqueueSnackbar(
        <Trans i18nKey="notifications.errorIdWasNotFound" />,
        {
          variant: 'error',
        }
      );
    }

    try {
      if (variant !== 'placement') {
        const res = await fetch('/api/hardware/sticker-pdf', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'POST',
          body: JSON.stringify({
            hwIds: data,
            stickerFormat: formValues.stickerFormat,
            stickerStartAtPosition: formValues.stickerStartAtPosition,
            stickerOrGuide: formValues.stickerOrGuide,
          }),
        }).then((data) => data.json());
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = window.URL.createObjectURL(
          new Blob([Buffer.from(res.pdf, 'utf-8')], {
            type: 'application/pdf',
          })
        );

        link.click();
        window.URL.revokeObjectURL(link.href);
        link.remove();
        if (res.error) {
          return enqueueSnackbar(res.error, { variant: 'error' });
        } else if (res.status === 200) {
          enqueueSnackbar(<Trans i18nKey="notifications.printSticker" />, {
            variant: 'success',
          });
        }
      } else {
        const res = await fetch('/api/hardware/placement-pdf', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'POST',
          body: JSON.stringify({
            hwIds: data,
            stickerFormat: formValues.stickerFormat,
            stickerStartAtPosition: formValues.stickerStartAtPosition,
            stickerOrGuide: formValues.stickerOrGuide,
          }),
        }).then((data) => data.json());
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = window.URL.createObjectURL(
          new Blob([Buffer.from(res.pdf, 'utf-8')], {
            type: 'application/pdf',
          })
        );

        link.click();
        window.URL.revokeObjectURL(link.href);
        link.remove();
        if (res.error) {
          return enqueueSnackbar(res.error, { variant: 'error' });
        } else if (res.status === 200) {
          enqueueSnackbar(<Trans i18nKey="notifications.printSticker" />, {
            variant: 'success',
          });
        }
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  const initialValues: FormData = {
    stickerOrGuide: defaultStickerType
      ? defaultStickerType
      : variant === 'placement'
      ? 'placement'
      : variant === 'guide'
      ? 'guide'
      : '',
    stickerFormat: 'A4',
    stickerStartAtPosition: 1,
  };

  const validate = (values: FormData) => {
    const errors = Validations.new(values)
      .required('stickerOrGuide')
      .required('stickerFormat')
      .required('stickerStartAtPosition')
      .getErrors();

    return errors;
  };

  const copyPng = async (content: string) => {
    if (content) {
      const res = await fetch('/api/hardware/download-qr-code', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          content: content,
        }),
      }).then((data) => data.json());

      const image = new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
        img.src = res.qr;
      }).then((img) => {
        let canvas = document.createElement('canvas');
        canvas.width = 165;
        canvas.height = 165;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img as HTMLImageElement, 0, 0);

        return canvas.toBlob((blob) => {
          if (blob) {
            navigator.clipboard.write([
              new ClipboardItem({
                'image/png': blob as unknown as ClipboardItemData,
              }),
            ]);
            enqueueSnackbar(<Trans i18nKey="notifications.copyToClipboard" />, {
              variant: 'success',
            });
          } else {
            enqueueSnackbar(
              <Trans i18nKey="notifications.errorCopyToClipboard" />,
              {
                variant: 'error',
              }
            );
            return;
          }
        });
      });

      return image;
    }
  };

  const copySticker = async (hwID: string) => {
    if (hwID) {
      const res = await fetch('/api/hardware/sticker-png', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          hwID: hwID,
        }),
      }).then((data) => data.json());

      const image = new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
        img.src = res.qr;
      }).then((img) => {
        let canvas = document.createElement('canvas');
        canvas.width = 165;
        canvas.height = 165;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img as HTMLImageElement, 0, 0);

        return canvas.toBlob((blob) => {
          if (blob) {
            navigator.clipboard.write([
              new ClipboardItem({
                'image/png': blob as unknown as ClipboardItemData,
              }),
            ]);
            enqueueSnackbar(<Trans i18nKey="notifications.copyToClipboard" />, {
              variant: 'success',
            });
          } else {
            enqueueSnackbar(
              <Trans i18nKey="notifications.errorCopyToClipboard" />,
              {
                variant: 'error',
              }
            );
            return;
          }
        });
      });

      return image;
    }
  };

  const downloadPng = async (content: string) => {
    if (content) {
      const res = await fetch('/api/hardware/download-qr-code', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          content: content,
        }),
      }).then((data) => data.json());
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.download = 'qr.png';
      link.href = res.qr;
      link.click();
      link.remove();
      enqueueSnackbar(<Trans i18nKey="notifications.downloading" />, {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(<Trans i18nKey="notifications.errorWhenDownloading" />, {
        variant: 'error',
      });
    }
  };

  const downloadSticker = async (hwID: string) => {
    if (hwID) {
      const res = await fetch('/api/hardware/sticker-png', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          hwID: hwID,
        }),
      }).then((data) => data.json());
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.download = 'qr.png';
      link.href = res.qr;
      link.click();
      link.remove();
      enqueueSnackbar(<Trans i18nKey="notifications.downloading" />, {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(<Trans i18nKey="notifications.errorWhenDownloading" />, {
        variant: 'error',
      });
    }
  };

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      render={({ values, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Trans i18nKey="stickerForm.description" />
              </Grid>

              {data.length > 1 ? null : values.stickerOrGuide === 'Guide' ? (
                <Grid
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '16px 0px',
                  }}
                >
                  <Grid>
                    <QRCode size={200} value={getQRQuide(data[0])} />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '16px',
                    }}
                  >
                    <Button
                      onClick={() => {
                        copyPng(`/guide/${data[0]}`);
                      }}
                    >
                      <ContentCopyIcon />
                    </Button>
                    <Button
                      onClick={() => {
                        downloadPng(`/guide/${data[0]}`);
                      }}
                    >
                      <DownloadIcon />
                    </Button>
                  </Grid>
                </Grid>
              ) : values.stickerOrGuide === 'Sticker' ? (
                <Grid
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '16px 0px',
                  }}
                >
                  <Grid>
                    <QRCode size={200} value={getQRSticker(data[0])} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '16px',
                    }}
                  >
                    <Button
                      onClick={() => {
                        copySticker(data[0]);
                      }}
                    >
                      <ContentCopyIcon />
                    </Button>
                    <Button
                      onClick={() => {
                        downloadSticker(data[0]);
                      }}
                    >
                      <DownloadIcon />
                    </Button>
                  </Grid>
                </Grid>
              ) : null}

              {variant === undefined ? (
                <Grid item xs={12}>
                  <Field name="stickerOrGuide">
                    {(props) => {
                      return (
                        <StickerOrGuideSelect
                          defaultValue={defaultStickerType}
                          onChange={(stickerOrGuide?: string) => {
                            props.input.onChange(stickerOrGuide);
                          }}
                          error={
                            props.meta.submitFailed
                              ? props.meta.error
                              : undefined
                          }
                        />
                      );
                    }}
                  </Field>
                </Grid>
              ) : null}

              <Grid item xs={12} sm={6}>
                <Field name="stickerFormat">
                  {(props) => {
                    return (
                      <StickerSelect
                        onChange={(stickerFormat?: string) => {
                          props.input.onChange(stickerFormat);
                        }}
                        error={
                          props.meta.submitFailed ? props.meta.error : undefined
                        }
                      />
                    );
                  }}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="stickerStartAtPosition">
                  {(props) => {
                    return (
                      <StickerStartAtPositionSelect
                        disabled={values.stickerFormat === 'Role'}
                        onChange={(stickerStartAtPosition?: string) => {
                          props.input.onChange(stickerStartAtPosition);
                        }}
                        error={
                          props.meta.submitFailed ? props.meta.error : undefined
                        }
                      />
                    );
                  }}
                </Field>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 16 }}>
                <Grid container justifyContent="center" xs={12}>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      sx={{
                        marginBottom: '8px',
                      }}
                    >
                      <Trans i18nKey="stickerForm.print" />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="button" fullWidth onClick={onCancel}>
                      <Trans i18nKey="stickerForm.cancelPrinting" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};
