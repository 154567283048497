import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import csLocale from 'date-fns/locale/cs';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { App } from './App';
import { UserProvider } from './contexts';
import * as serviceWorker from './serviceWorker';
import './i18n';

import './index.css';

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterDateFns} locale={csLocale}>
    <Router>
      <UserProvider>
        <App />
      </UserProvider>
    </Router>
  </LocalizationProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
