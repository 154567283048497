import { useContext } from 'react';
import { UserContext } from 'src/contexts';

export const useGetTableRows = (key: string): number => {
  const { userData } = useContext(UserContext);
  const count = userData?.userInfo.tableSetting.find(
    (ts) => ts.key === key
  )?.value;

  return count ?? 5;
};
