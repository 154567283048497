import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  FC,
  PropsWithChildren,
} from 'react';
import { HardwareType } from '../commonTypes';
import { UserContext } from './UserContext';

export const TypesContext = createContext<
  Partial<{
    types: HardwareType[];
    isLoading: boolean;
    refreshTypes: () => Promise<void>;
    setTypesUpdate: (update: boolean) => void;
  }>
>({
  types: [],
  refreshTypes: () => Promise.resolve(),
  isLoading: false,
  setTypesUpdate: () => {},
});

export const TypesContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { userData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [typesUpdate, setTypesUpdate] = useState(false);
  const [types, setTypes] = useState<HardwareType[]>([]);

  const refreshTypes = async () => {
    setIsLoading(true);
    const dbTypes = await fetch('/api/hardware/types', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'GET',
    }).then((data) => data.json());
    const activeTypes = dbTypes.filter(
      (types: HardwareType) => types.isDeleted === false
    );
    setTypes(activeTypes);

    setIsLoading(false);
  };

  useEffect(() => {
    refreshTypes();
  }, [userData, typesUpdate]); // eslint-disable-line

  return (
    <TypesContext.Provider
      value={{ types, isLoading, refreshTypes, setTypesUpdate }}
    >
      {children}
    </TypesContext.Provider>
  );
};
