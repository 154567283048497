import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { EditHwForm } from './EditHwForm';
import { Hardware } from '../commonTypes';
import { Dialog } from '../components';

interface EditHwDialogProps {
  values?: Hardware;
  onClose?: () => void;
  onSubmit: (hw: Hardware) => void;
  editKey?: string;
}

export const EditHwDialog: FC<EditHwDialogProps> = (props) => {
  const { onClose, onSubmit, values, editKey } = props;

  return (
    <Dialog
      title={<Trans i18nKey="editHwForm.editHwFormTitle" />}
      onClose={onClose}
    >
      <EditHwForm
        onSubmit={onSubmit}
        onCancel={onClose}
        values={values}
        editKey={editKey}
      />
    </Dialog>
  );
};
