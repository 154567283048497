import React, { FC, ReactText, useContext, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';

import { HwTypeEditDialog } from './HwTypeEditDialog';
import { CompanyContext, UserContext } from '../contexts';
import { Trans } from '../i18n';
import { Company, HardwareType } from '../commonTypes';
import { AlertDialog, Dialog } from '../components';
import { StyledGrid } from '../customStyledComponents';

interface HwTypeDialogProps {
  onClose: () => void;
  dataDetail?: HardwareType;
  onDelete: (hwType: HardwareType) => void;
  onUpdate: (hwType: HardwareType) => void;
}

export const HwTypeDialog: FC<HwTypeDialogProps> = (props) => {
  const { onClose, dataDetail, onDelete, onUpdate } = props;

  const { globalUserCompany = [] } = useContext(CompanyContext);
  const { userData } = useContext(UserContext);

  const [openHwTypeEditDialog, setOpenHwTypeEditDialog] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState<ReactText>();

  const company = globalUserCompany?.find(
    (com: Company) => com._id === dataDetail?.companyId
  );

  const closeHwTypeEditDialog = () => {
    setOpenHwTypeEditDialog(!openHwTypeEditDialog);
  };

  const isEditable = useMemo(() => {
    const foundCompany = globalUserCompany.find(
      (guc) => guc._id === dataDetail?.companyId
    );
    const foundUser = foundCompany?.users.find(
      (u) => u.uid === userData?.user._id
    );
    if (foundUser?.userRole === 'User') {
      return true;
    } else {
      return false;
    }
  }, [dataDetail, globalUserCompany, userData]);

  const closeAlertDialog = (index?: ReactText) => {
    setShowRemoveDialog(index);
  };

  return (
    <Dialog title={dataDetail?.name ? dataDetail.name : '--'} onClose={onClose}>
      <>
        <Grid container>
          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="hwTypeEditDialog.description" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {dataDetail?.name ? dataDetail.name : '--'}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="hwTypeEditDialog.company" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {company?.companyName ? company.companyName : '--'}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="hwTypeEditDialog.inventoryNumberPrefix" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {dataDetail?.inventoryPrefix
                    ? dataDetail.inventoryPrefix
                    : '--'}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="hwTypeEditDialog.inventoryNumber" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {dataDetail?.isInventoryNumberRequired ? (
                    <Trans i18nKey="labels.mandatory" />
                  ) : (
                    <Trans i18nKey="labels.optional" />
                  )}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="hwTypeEditDialog.dateOfPurchase" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {dataDetail?.isDateOfPurchaseRequired ? (
                    <Trans i18nKey="labels.mandatory" />
                  ) : (
                    <Trans i18nKey="labels.optional" />
                  )}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="hwTypeEditDialog.guaranteeUntil" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {dataDetail?.isGuaranteeRequired ? (
                    <Trans i18nKey="labels.mandatory" />
                  ) : (
                    <Trans i18nKey="labels.optional" />
                  )}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="hwTypeEditDialog.serialNumber" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {dataDetail?.isSerialNumberRequired ? (
                    <Trans i18nKey="labels.mandatory" />
                  ) : (
                    <Trans i18nKey="labels.optional" />
                  )}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="hwTypeEditDialog.price" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {dataDetail?.isPriceRequired ? (
                    <Trans i18nKey="labels.mandatory" />
                  ) : (
                    <Trans i18nKey="labels.optional" />
                  )}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="hwTypeEditDialog.smallEquipment" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {dataDetail?.smallEquipments ? (
                    <Trans i18nKey="labels.yes" />
                  ) : (
                    <Trans i18nKey="labels.no" />
                  )}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid
            container
            xs={12}
            sx={{
              width: '100%',
              position: 'sticky',
              left: '0px',
              bottom: '-22px',
              padding: '16px 0px',
              background: 'white',
            }}
          >
            <Grid container spacing={1} marginBottom={1} marginTop={1}>
              <Grid item xs={6}>
                <Button
                  type="button"
                  color="error"
                  variant="contained"
                  style={{
                    boxShadow: 'none',
                    width: '100%',
                  }}
                  onClick={() => setShowRemoveDialog(dataDetail?._id)}
                  disabled={isEditable}
                >
                  <Trans i18nKey="detail.delete" />
                </Button>
              </Grid>

              {showRemoveDialog ? (
                <AlertDialog
                  onClose={() => closeAlertDialog()}
                  onDelete={() => {
                    if (dataDetail !== undefined) {
                      onDelete(dataDetail);
                    }
                  }}
                />
              ) : null}

              <Grid item xs={6}>
                <Button
                  type="button"
                  color="primary"
                  variant="outlined"
                  style={{
                    boxShadow: 'none',
                    width: '100%',
                  }}
                  onClick={() => setOpenHwTypeEditDialog(!openHwTypeEditDialog)}
                  disabled={isEditable}
                >
                  <Trans i18nKey="detail.edit" />
                </Button>
              </Grid>

              {openHwTypeEditDialog ? (
                <HwTypeEditDialog
                  onClose={() => closeHwTypeEditDialog()}
                  hwTypeData={dataDetail!}
                  onUpdate={(hwType) => onUpdate(hwType)}
                />
              ) : null}
            </Grid>

            <Button
              type="button"
              color="primary"
              variant="text"
              style={{
                boxShadow: 'none',
                width: '100%',
              }}
              onClick={onClose}
            >
              <Trans i18nKey="detail.close" />
            </Button>
          </Grid>
        </Grid>
      </>
    </Dialog>
  );
};
