import React, { FC } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { SelectProps } from '../../commonTypes';

export const CurrencySelectField: FC<
  SelectProps<string> & { value?: string }
> = (props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    props.onChange(selectedValue);
  };

  return (
    <FormControl error={!!props.error} fullWidth>
      <FormControl fullWidth>
        <Select
          id="currency-select"
          onChange={handleChange}
          value={props.value}
          defaultValue={props.value}
          inputProps={{ 'data-testid': 'currency-select' }}
          data-testid="currency-select-field"
        >
          <MenuItem value={'CZK'} data-testid="currency-option-CZK">
            CZK
          </MenuItem>
          <MenuItem value={'EUR'} data-testid="currency-option-EUR">
            EUR
          </MenuItem>
          <MenuItem value={'BGN'} data-testid="currency-option-BGN">
            BGN
          </MenuItem>
        </Select>
      </FormControl>
    </FormControl>
  );
};
