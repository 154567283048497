import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { SelectProps } from '../../commonTypes';

export const RevisionSelectField: FC<
  SelectProps<string> & { value?: string }
> = (props) => {
  const { onChange, error, value: revisionSelectValue } = props;
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  return (
    <FormControl error={!!error} fullWidth>
      <FormControl fullWidth>
        <InputLabel id="revision">
          <Trans i18nKey="newRevisionType.durationUnit" />
        </InputLabel>
        <Select
          labelId="revision"
          id="revision-select"
          label={<Trans i18nKey="newRevisionType.durationUnit" />}
          onChange={handleChange}
          value={revisionSelectValue}
          data-testid="revision-select-field"
        >
          <MenuItem value={-1}>
            <Trans i18nKey="labels.select" />
          </MenuItem>
          <MenuItem value={1} data-testid="revision-period-option">
            <Trans i18nKey="newRevisionType.day" />
          </MenuItem>
          <MenuItem value={30} data-testid="revision-period-option">
            <Trans i18nKey="newRevisionType.month" />
          </MenuItem>
          <MenuItem value={365} data-testid="revision-period-option">
            <Trans i18nKey="newRevisionType.year" />
          </MenuItem>
        </Select>
      </FormControl>
    </FormControl>
  );
};
