import React, { FC, ReactNode, useCallback, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import _ from 'lodash';
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Box,
  SelectChangeEvent,
  InputAdornment,
} from '@mui/material';

import { CreateUserDialog } from './CreateUserDialog';
import { EmployeesContext } from '../contexts';
import { Employee, SelectProps } from '../commonTypes';

const renderItems = (employees: Employee[] = []) => {
  let sortedEmployees = _.sortBy(employees, 'lastName');
  return sortedEmployees.map((employee) => (
    <MenuItem
      key={employee._id}
      value={employee._id}
      disabled={employee.isDeleted}
      data-testid={`user-select-option-${employee.email}`}
    >
      {employee.firstName} {employee.lastName} ({employee.email})
    </MenuItem>
  ));
};

export const UserSelectField: FC<
  SelectProps<Employee | undefined> & {
    showAddNew?: boolean;
    startAdornment?: ReactNode;
  }
> = (props) => {
  const {
    error,
    value,
    onChange,
    selectedValue,
    showAddNew,
    disabled,
    startAdornment,
  } = props;

  const { employees, refreshEmployees } = useContext(EmployeesContext);

  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleOnSubmitNewUser = useCallback(
    async (employee: Employee) => {
      try {
        await refreshEmployees?.();
        setShowAddUserDialog(false);
        setIsOpen(false);
        onChange(employee);
      } catch (error) {
        console.log(error);
      }
    },
    [employees, refreshEmployees] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const employeesByCompany = employees?.filter(
    (employe) => employe.companyId === selectedValue
  );

  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    const selectedEmployee = employees?.find(
      ({ _id: id }) => id === selectedValue
    );

    onChange(selectedEmployee);
  };

  return (
    <>
      {showAddUserDialog ? (
        <CreateUserDialog
          onClose={() => {
            onChange(undefined);
            setShowAddUserDialog(false);
          }}
          onSubmit={handleOnSubmitNewUser}
          selectedCompany={selectedValue}
        />
      ) : null}

      <FormControl error={!!error} fullWidth disabled={disabled}>
        <InputLabel>
          <Trans i18nKey="labels.employee" />
        </InputLabel>
        <Select
          name="borrowedBy"
          value={(value ?? -1) as string}
          onChange={handleChange}
          fullWidth
          margin="none"
          label={<Trans i18nKey="labels.employee" />}
          required
          data-testid="user-select-field"
          inputProps={{ value: value ?? '' }}
          open={isOpen}
          onClick={() => !disabled && setIsOpen((prev) => !prev)}
          startAdornment={
            startAdornment ? (
              <InputAdornment
                disablePointerEvents
                position="start"
                sx={{ display: 'flex' }}
              >
                {startAdornment}
              </InputAdornment>
            ) : null
          }
        >
          <MenuItem value={-1} onChange={() => onChange(undefined)}>
            <Trans i18nKey="labels.select" />
          </MenuItem>
          {renderItems(employeesByCompany)}
          {showAddNew && (
            <Box
              onClick={() => setShowAddUserDialog(true)}
              sx={{
                '&:last-of-type': {
                  color: 'primary.dark',
                  padding: '8px 16px',
                  cursor: 'pointer',
                  fontWeight: '500',
                  borderTop: (theme) =>
                    '1px solid ' + theme.palette.warning.light,
                  '&:hover': { bgcolor: 'warning.light' },
                },
              }}
            >
              <Trans i18nKey="labels.createNewUser" />
            </Box>
          )}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </>
  );
};
