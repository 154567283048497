import React, { FC } from 'react';

import { TablePaginationProps } from '@mui/material';
import { StyledTablePagination } from './GlobalCustomStyledComponents';

export const CustomTablePagination: FC<TablePaginationProps> = (props) => {
  return (
    <td
      style={{
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'end',
        width: '100%',
      }}
    >
      <table>
        <tbody>
          <tr>
            <StyledTablePagination {...props} sx={{ borderBottom: 'none' }} />
          </tr>
        </tbody>
      </table>
    </td>
  );
};
