import React, { FC, ReactText, useContext, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Button, Grid } from '@mui/material';

import { UsersEditDialog } from './UsersEditDialog';
import { CompanyContext, UserContext } from '../contexts';
import { Company, Employee } from '../commonTypes';
import { Dialog, AlertDialog } from '../components';
import { StyledGrid } from '../customStyledComponents';

interface UserDetailDialogProps {
  onClose?: () => void;
  employee?: Employee;
  onDelete: (employee: Employee) => void;
  onUpdate: (employee: Employee) => void;
}

export const UsersDetailDialog: FC<UserDetailDialogProps> = (props) => {
  const { employee, onClose, onDelete, onUpdate } = props;

  const { globalUserCompany = [] } = useContext(CompanyContext);
  const { userData } = useContext(UserContext);

  const [openEditUser, setOpenEditUser] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState<ReactText>();

  const company = globalUserCompany?.find(
    (com: Company) => com._id === employee?.companyId
  );

  const closeEditUser = () => {
    setOpenEditUser(!openEditUser);
  };

  const closeAlertDialog = (index?: ReactText) => {
    setShowRemoveDialog(index);
  };

  const isEditable = useMemo(() => {
    const foundCompany = globalUserCompany.find(
      (guc) => guc._id === employee?.companyId
    );
    const foundUser = foundCompany?.users.find(
      (u) => u.uid === userData?.user._id
    );
    if (foundUser?.userRole === 'User') {
      return true;
    } else {
      return false;
    }
  }, [employee, globalUserCompany, userData]);

  return (
    <Dialog
      title={
        employee?.firstName && employee.lastName ? (
          employee.firstName + ' ' + employee.lastName
        ) : (
          <Trans i18nKey="detail.detailTitle" />
        )
      }
      onClose={onClose}
    >
      <>
        <Grid container>
          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="usersEditDialog.firstname" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {employee?.firstName ? employee.firstName : '--'}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="usersEditDialog.lastname" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {employee?.lastName ? employee.lastName : '--'}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="usersEditDialog.email" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {employee?.email ? employee.email : '--'}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="usersEditDialog.company" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {company?.companyName ? company.companyName : '--'}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid
            container
            xs={12}
            sx={{
              width: '100%',
              position: 'sticky',
              left: '0px',
              bottom: '-22px',
              padding: '16px 0px',
              background: 'white',
            }}
          >
            <Grid container spacing={1} marginBottom={1} marginTop={1}>
              <Grid item xs={6}>
                <Button
                  type="button"
                  color="error"
                  variant="contained"
                  sx={{
                    boxShadow: 'none',
                    width: '100%',
                  }}
                  onClick={() => setShowRemoveDialog(employee?._id)}
                  disabled={isEditable}
                >
                  <Trans i18nKey="detail.delete" />
                </Button>
              </Grid>

              {showRemoveDialog ? (
                <AlertDialog
                  onClose={() => closeAlertDialog()}
                  onDelete={() => {
                    if (employee !== undefined) {
                      onDelete(employee);
                    }
                  }}
                />
              ) : null}

              <Grid item xs={6}>
                <Button
                  type="button"
                  color="primary"
                  variant="outlined"
                  sx={{
                    boxShadow: 'none',
                    width: '100%',
                  }}
                  onClick={() => setOpenEditUser(!openEditUser)}
                  disabled={isEditable}
                >
                  <Trans i18nKey="detail.edit" />
                </Button>
              </Grid>

              {openEditUser ? (
                <UsersEditDialog
                  onClose={() => closeEditUser()}
                  onUpdate={(em) => {
                    onUpdate(em);
                  }}
                  employeeData={employee!}
                />
              ) : null}
            </Grid>

            <Button
              type="button"
              color="primary"
              variant="text"
              sx={{
                boxShadow: 'none',
                width: '100%',
              }}
              onClick={onClose}
            >
              <Trans i18nKey="detail.close" />
            </Button>
          </Grid>
        </Grid>
      </>
    </Dialog>
  );
};
