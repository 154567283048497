import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Dialog } from '../Dialog';
import { GuideDetailForm } from '../DialogForms';

interface GuideDetailDialogProps {
  guideId?: string;
  onClose: () => void;
  openGuideEditor?: () => void;
}

export const GuideDetailDialog: FC<GuideDetailDialogProps> = (props) => {
  const { guideId, onClose, openGuideEditor } = props;

  return (
    <Dialog title={<Trans i18nKey="guideDetailForm.title" />} onClose={onClose}>
      <GuideDetailForm
        guideId={guideId}
        onCancel={onClose}
        openGuideEditor={openGuideEditor}
      />
    </Dialog>
  );
};
