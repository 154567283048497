import { ReactNode } from 'react';
import { t } from 'i18next';

export type ErrorsObject<T> = {
  [K in keyof T]?: string | ReactNode;
};

export class Validations<T> {
  private errors: ErrorsObject<T> = {};
  constructor(private values: T) {}

  public static new<T>(values: T) {
    return new Validations<T>(values);
  }

  public getErrors() {
    return { ...this.errors };
  }

  public required(fieldName: keyof T, message?: ReactNode) {
    if (!this.values[fieldName]) {
      this.errors[fieldName] = message ?? t('notifications.errorRequired');
    }

    return this;
  }

  public min(fieldName: keyof T, min: number, message?: ReactNode) {
    if (
      this.values[fieldName] &&
      (this.values[fieldName] as string).length < min
    ) {
      this.errors[fieldName] =
        message ??
        `${t('notifications.errorMinimumRequired')} ${min} ${t(
          'notifications.errorChar'
        )}`;
    }

    return this;
  }
  public minOrEqual(fieldName: keyof T, min: number, message?: ReactNode) {
    if (
      this.values[fieldName] &&
      (this.values[fieldName] as string).length <= min
    ) {
      this.errors[fieldName] =
        message ??
        `${t('notifications.errorMinimumRequired')} ${min} ${t(
          'notifications.errorChar'
        )}`;
    }

    return this;
  }

  public emailShape(fieldName: keyof T, message?: ReactNode) {
    const emailRegExp = new RegExp(
      /^\w+([.-]?\w+)([.+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    );
    const email = this.values[fieldName];
    const validEmail = emailRegExp.test(email as string);
    if (!validEmail) {
      this.errors[fieldName] =
        message ?? t('notifications.errorWrongEmailFormat');
    }

    return this;
  }
}

export function required<T>(
  values: T,
  errors: ErrorsObject<T>,
  fieldName: keyof T
): ErrorsObject<T> {
  if (!values[fieldName]) {
    return { ...errors, [fieldName]: t('notifications.errorRequired') };
  }
  return errors;
}

export function min<T>(
  values: T,
  errors: ErrorsObject<T>,
  fieldName: keyof T,
  min: number
): ErrorsObject<T> {
  if (values[fieldName] && (values[fieldName] as string).length < min) {
    return {
      ...errors,
      [fieldName]: `${t('notifications.errorMinimumRequired')} ${min} ${t(
        'notifications.errorChar'
      )}`,
    };
  }
  return errors;
}

export function emailShape<T>(
  values: T,
  errors: ErrorsObject<T>,
  fieldName: keyof T
): ErrorsObject<T> {
  const emailRegExp = new RegExp(
    /^\w+([.-]?\w+)([.+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  );
  const email = values[fieldName];
  const validEmail = emailRegExp.test(email as string);
  if (!validEmail) {
    return {
      ...errors,
      [fieldName]: `${t('notifications.errorWrongEmailFormat')}`,
    };
  }
  return errors;
}

export const isValidDate = (d?: Date) =>
  d instanceof Date && !isNaN(d as unknown as number);
