import { omit } from 'lodash';
import { FormData } from './interfaces';
import { Validations } from '../../utils';
import { HardwareType } from '../../commonTypes';

export const createValidate = (values: FormData) => {
  const hwCount = parseInt(values.hwCount ?? '1');
  const dynamicValidationCount = Array.from(Array(hwCount).keys());
  const validations = Validations.new(values)
    .required('type')
    .required('description')
    .required('hwCount');

  const { type = {} as HardwareType } = values;

  if (type.isDateOfPurchaseRequired) {
    validations.required('dateOfPurchase');
  }

  if (type.isGuaranteeRequired) {
    validations.required('guaranteeUntil');
  }

  if (type.isInventoryNumberRequired) {
    dynamicValidationCount.forEach((_, index) => {
      return validations.required(`inventoryNumber${index + 1}`);
    });
  }

  if (type.isSerialNumberRequired) {
    dynamicValidationCount.forEach((_, index) => {
      return validations.required(`serialNumber${index + 1}`);
    });
  }

  if (type.isPriceRequired) {
    validations.required('price');
  }

  return validations.getErrors();
};

export const generateHwData = (values: FormData) => {
  const hwCount = parseInt(values.hwCount ?? '1');
  const hwCountArray = Array.from(Array(hwCount).keys());
  const valuesCopy = { ...values };

  // Deleting inventary, serial numbers and borrowedBy
  hwCountArray.forEach((_, index) => {
    delete valuesCopy[`inventoryNumber${index + 1}`];
    delete valuesCopy[`serialNumber${index + 1}`];
    delete valuesCopy[`borrowedBy${index + 1}`];
  });

  if (valuesCopy.revisionType === undefined) {
    valuesCopy.revisionNext = undefined;
  }

  return hwCountArray.map((_, index) => {
    return {
      ...omit(valuesCopy, ['type', 'hwCount']),
      typeId: values.type!._id,
      serialNumber: values[`serialNumber${index + 1}`],
      inventoryNumber: values[`inventoryNumber${index + 1}`],
      borrowedBy: values[`borrowedBy${index + 1}`] ?? undefined,
    };
  });
};
