import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { IssueManagementForm } from './IssueManagementForm';
import { Hardware } from '../commonTypes';
import { Dialog } from '../components';

interface IssueManagementDialogProps {
  onClose: () => void;
  hwData: Hardware;
}
export const IssueManagementDialog: FC<IssueManagementDialogProps> = (
  props
) => {
  const { onClose, hwData } = props;

  return (
    <Dialog title={<Trans i18nKey="issue.reportIssue" />} onClose={onClose}>
      <IssueManagementForm onClose={onClose} hwData={hwData} />
    </Dialog>
  );
};
