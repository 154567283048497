import React, { FC, useContext, useState } from 'react';
import { Buffer } from 'buffer';

import { PdfDialog } from './Dialogs';
import { UserContext } from '../contexts';
import { Company, Hardware } from '../commonTypes';
import { Trans } from '../i18n';
import { StyledButton } from '../customStyledComponents';

interface IFhwData {
  hwData: Hardware[];
}

export const PrintPDF: FC<IFhwData> = (props) => {
  const { hwData } = props;

  const { userData } = useContext(UserContext);

  const [pdfModal, openPdfModal] = useState(false);

  const createAndDownloadPdf = (guc?: Company) => {
    let excel: (string | undefined)[];
    if (guc) {
      const companies = hwData.filter((item) => guc._id === item.companyId);
      excel = companies.map((list) => {
        return list._id;
      });
    } else {
      excel = hwData?.map((item: Hardware) => {
        return item._id;
      });
    }

    fetch('/api/hardware/create-pdf', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      body: JSON.stringify(excel),
      method: 'POST',
    }).then((data) => {
      data.json().then((res) => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.download = 'PDFFILE.pdf';
        link.href = window.URL.createObjectURL(
          new Blob([Buffer.from(res.pdf, 'utf-8')], {
            type: 'application/pdf',
          })
        );

        link.click();
        window.URL.revokeObjectURL(link.href);
        link.remove();
      });
    });
  };

  const closePdfModal = () => {
    openPdfModal(!pdfModal);
  };

  return (
    <>
      <StyledButton
        type="button"
        color="secondary"
        variant="contained"
        sx={{
          boxShadow: 'none',
          color: '#496AEC',
          margin: { xs: '0 4px 4px 0', sm: '0 0 4px 4px' },
        }}
        onClick={() => openPdfModal(!pdfModal)}
        data-testid="print-pdf-open-modal-btn"
      >
        <Trans i18nKey="labels.exportPDF" />
      </StyledButton>
      {pdfModal ? (
        <PdfDialog
          onClose={() => closePdfModal()}
          onSubmit={createAndDownloadPdf}
        />
      ) : null}
    </>
  );
};
