import { useCallback, useMemo, useState } from 'react';

export const useToggle = (defaultValue?: boolean | (() => boolean)) => {
  const [state, setState] = useState(defaultValue ?? false);

  const toggle = useCallback(() => {
    setState((prev) => !prev);
  }, []);

  return useMemo(() => [state, toggle] as const, [state, toggle]);
};
