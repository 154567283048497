import React, { FC } from 'react';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { t } from 'i18next';
import { Trans } from '../../i18n';
import { SelectProps } from '../../commonTypes';

const items = Array.from({ length: 44 }, (_, index) => (
  <MenuItem key={index + 1} value={index + 1}>
    {index + 1}
  </MenuItem>
));

export const StickerStartAtPositionSelect: FC<SelectProps<string>> = (
  props
) => {
  const { onChange, disabled, error } = props;
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };
  return (
    <FormControl error={!!error} fullWidth>
      <Select
        disabled={disabled}
        defaultValue={t('stickerSelect.choosePosition') as string}
        onChange={handleChange}
        renderValue={(selected?: string) => {
          if (!selected) {
            return (
              <FormHelperText sx={{ color: '#0000004D' }}>
                <Trans i18nKey="stickerSelect.choosePosition" />
              </FormHelperText>
            );
          }
          return selected;
        }}
      >
        <MenuItem disabled value={undefined}>
          <FormHelperText>
            <Trans i18nKey="stickerSelect.choosePosition" />
          </FormHelperText>
        </MenuItem>
        {items}
      </Select>
    </FormControl>
  );
};
