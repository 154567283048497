import React, { FC, useContext, useEffect, useState, useRef } from 'react';
import { Trans } from 'react-i18next';
import {
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Theme,
  useTheme,
  SelectChangeEvent,
} from '@mui/material';

import { CompanyContext, HardwareContext, IssueContext } from '../contexts';
import {
  CompanyPreviewTable,
  IssueTable,
  UpcomingRevisionsTable,
} from '../tables';
import { DetailHardwareDialog } from './Dialogs';
import TasksCard from 'src/pages/CompanyCardPage/TasksCard';
import EventsCard, {
  EventsCardFunctions,
} from 'src/pages/CompanyCardPage/EventsCard';
import { isServerLicense } from '../utils';

export const Dashboard: FC = () => {
  const { globalUserCompany = [] } = useContext(CompanyContext);
  const { setCompanyIds: handleFilteredByCompanyIds } =
    useContext(IssueContext);
  const { setCompanyIds: handleHwFilteredByCompanyIds } =
    useContext(HardwareContext);
  const [companyIdsForFilter, setCompanyIdsForFilter] = useState<string[]>([]);

  const [companies, setCompanies] = useState<string[]>([]);

  const [hardwareDetail, setHardwareDetail] = useState(false);
  const [hardwareId, setHardwareId] = useState<string>();

  const eventsCardRef = useRef<EventsCardFunctions>(null);

  const closeHardwareDetail = () => {
    setHardwareDetail(false);
    setHardwareId(undefined);
  };

  const theme = useTheme();

  const getStyles = (
    companyName: string,
    companies: readonly string[],
    theme: Theme
  ) => {
    return {
      fontWeight:
        companies.indexOf(companyName) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const handleChange = (event: SelectChangeEvent<typeof companies>) => {
    const {
      target: { value },
    } = event;
    setCompanies(typeof value === 'string' ? value.split(',') : value);
  };

  const handleDetailDialog = (hwId: string, isOpen: boolean) => {
    setHardwareId(hwId);
    setHardwareDetail(isOpen);
  };

  useEffect(() => {
    handleFilteredByCompanyIds([]);
    handleHwFilteredByCompanyIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h5" sx={{ fontWeight: '700', color: '#555555' }}>
          <Trans i18nKey="pages.dashboardPage.title" />
        </Typography>
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="flex-end">
        <FormControl sx={{ width: '200px' }}>
          <Select
            sx={{ height: '36px' }}
            multiple
            displayEmpty
            value={companies}
            onChange={handleChange}
            onClose={() => {
              handleFilteredByCompanyIds(companies);
              handleHwFilteredByCompanyIds(companies);
              setCompanyIdsForFilter(companies);
            }}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <em>
                    <Trans i18nKey="tables.hwList.companies" />
                  </em>
                );
              }
              return selected
                .map(
                  (s) =>
                    globalUserCompany.find((guc) => guc._id === s)?.companyName
                )
                .join(', ');
            }}
            inputProps={{
              'aria-label': 'Without label',
            }}
            data-testid="hw-page-company-select"
          >
            <MenuItem disabled value="">
              <em>
                <Trans i18nKey="tables.hwList.companies" />
              </em>
            </MenuItem>
            {globalUserCompany.map((company) => (
              <MenuItem
                key={company._id}
                value={company._id}
                style={getStyles(company.companyName, companies, theme)}
              >
                {company.companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {isServerLicense && (
        <>
          <Grid item xs={12} md={6}>
            <TasksCard
              filterByCompany={companyIdsForFilter}
              refetchEventsTable={eventsCardRef.current?.refetch}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <EventsCard
              filterByCompany={companyIdsForFilter}
              ref={eventsCardRef}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <CompanyPreviewTable />
      </Grid>
      <Grid item xs={12}>
        <IssueTable onCallbackDetail={handleDetailDialog} />
      </Grid>
      <Grid item xs={12}>
        <UpcomingRevisionsTable onCallbackDetail={handleDetailDialog} />
      </Grid>

      {hardwareDetail ? (
        <DetailHardwareDialog
          history={[]}
          hwId={hardwareId}
          editKey="issue"
          onClose={() => closeHardwareDetail()}
        />
      ) : null}
    </Grid>
  );
};
