import React, { FC, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Trans } from '../../i18n';
import { SelectProps } from '../../commonTypes';

export const StickerSelect: FC<SelectProps<string>> = (props) => {
  const [defVal, setDefVal] = useState('A4');
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    props.onChange(selectedValue);
    setDefVal(selectedValue);
  };

  return (
    <FormControl error={!!props.error} fullWidth>
      <Select
        defaultValue={defVal}
        onChange={handleChange}
        renderValue={(selected?: string) => {
          if (!selected) {
            return (
              <FormHelperText sx={{ color: '#0000004D' }}>
                <Trans i18nKey="stickerSelect.choose" />
              </FormHelperText>
            );
          }

          if (selected === 'Role')
            return <Trans i18nKey="stickerSelect.formatRole" />;
          else if (selected === 'A4')
            return <Trans i18nKey="stickerSelect.formatA4" />;
          else return <Trans i18nKey="stickerSelect.choose" />;
        }}
      >
        <MenuItem disabled value={undefined}>
          <FormHelperText>
            <Trans i18nKey="stickerSelect.choose" />
          </FormHelperText>
        </MenuItem>
        <MenuItem value={'A4'}>
          <Trans i18nKey="stickerSelect.formatA4" />
        </MenuItem>
        <MenuItem value={'Role'}>
          <Trans i18nKey="stickerSelect.formatRole" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};
