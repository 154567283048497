import React, {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  CompanyContext,
  HardwarePlacementContext,
  UserContext,
} from '../../contexts';
import { Company, PlacementType, Tasks } from '../../commonTypes';
import { Field, Form } from 'react-final-form';
import { DatePicker } from 'mui-rff';
import { useSnackbar } from 'notistack';
import { Validations } from '../../utils';
import { useParams } from 'react-router-dom';

interface NewEventFormProps {
  onSubmit: () => void;
  eventId?: string;
  taskId?: string;
}

const renderPlacement = (placements: PlacementType[], companies: Company[]) => {
  const reduced = placements.reduce((acc, placement) => {
    if (!acc[placement.companyId]) {
      acc[placement.companyId] = [];
    }
    acc[placement.companyId].push(placement);

    return acc;
  }, {} as Record<string, PlacementType[]>);

  const out: ReactElement[] = [];

  Object.entries(reduced).forEach(([key, value]) => {
    out.push(
      <MenuItem disabled value="">
        <Typography fontWeight={'bolder'}>
          {companies.find((c) => c._id === key)?.companyName}
        </Typography>
      </MenuItem>
    );

    value.forEach((placement) => {
      out.push(
        <MenuItem key={placement._id} value={placement._id}>
          {placement.name}
        </MenuItem>
      );
    });
  });
  return out;
};
export const EventForm: FC<NewEventFormProps> = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();

  const { globalUserCompany = [] } = useContext(CompanyContext);
  const { userData } = useContext(UserContext);
  const { globalHardwarePlacements = [] } = useContext(
    HardwarePlacementContext
  );
  const [taskDetail, setTaskDetail] = useState<Tasks>();
  const [eventDetail, setEventDetail] = useState({
    companyIds: [],
    placementIds: [],
  });

  const getEvent = useCallback(async () => {
    if (!props.eventId) return;
    const res = await fetch(`/api/events/detail/${props.eventId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'GET',
    });
    if (res.ok) {
      const data = await res.json();
      setEventDetail({ ...data, assigneeId: data.assignee });
    }
  }, [props.eventId, userData]);

  const getTask = useCallback(async () => {
    if (!props.taskId) return;
    const res = await fetch(`/api/tasks/detail/${props.taskId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'GET',
    });
    if (res.ok) {
      const data = await res.json();
      setTaskDetail({ ...data, assigneeId: data.assignee });
    }
  }, [props.taskId, userData]);

  useEffect(() => {
    getEvent();
    getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (data: any) => {
    let apiUrl = '/api/events';
    if (props.eventId) {
      apiUrl = `/api/events/${props.eventId}`;
    }

    const res = await fetch(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: props.eventId ? 'PUT' : 'POST',
      body: JSON.stringify(
        props.taskId ? { ...data, taskId: props.taskId } : data
      ),
    });

    if (res.ok) {
      props.onSubmit();
      enqueueSnackbar(<Trans i18nKey="notifications.saved" />, {
        variant: 'success',
      });
    }
  };
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={handleSubmit}
      validate={(v) =>
        Validations.new(v).required('date').required('name').getErrors()
      }
      initialValues={
        props.eventId
          ? eventDetail
          : {
              companyIds: taskDetail?.companyIds
                ? taskDetail?.companyIds
                : id
                ? [id]
                : [],
              placementIds: taskDetail?.placementIds ?? [],
              date: new Date(),
              resolver: userData!.user._id,
            }
      }
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid
              container
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingX: '14px',
              }}
              rowGap={'12px'}
            >
              <Grid item xs={12}>
                <Field name={'name'}>
                  {(props) => (
                    <TextField
                      fullWidth
                      error={
                        props.meta.error &&
                        props.meta.touched &&
                        props.meta.error
                      }
                      helperText={
                        props.meta.error &&
                        props.meta.touched &&
                        props.meta.error
                      }
                      {...props.input}
                      label={t('event.name')}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item container gap={'12px'}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Field name="companyIds">
                      {(props) => {
                        const isAllSelected =
                          props.input.value.length === globalUserCompany.length;
                        return (
                          <FormControl fullWidth>
                            <Select<Array<string>>
                              {...props.input}
                              multiple
                              displayEmpty
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return (
                                    <em>
                                      <Trans i18nKey="event.companies" />
                                    </em>
                                  );
                                }
                                return selected
                                  .map(
                                    (s) =>
                                      globalUserCompany.find(
                                        (guc) => guc._id === s
                                      )?.companyName
                                  )
                                  .join(', ');
                              }}
                            >
                              <MenuItem disabled value="">
                                <em>
                                  <Trans i18nKey="event.companies" />
                                </em>
                              </MenuItem>
                              <Button
                                fullWidth
                                onClick={() => {
                                  if (isAllSelected) {
                                    props.input.onChange([]);
                                  } else {
                                    props.input.onChange(
                                      globalUserCompany.map((guc) => guc._id)
                                    );
                                  }
                                }}
                              >
                                {isAllSelected ? (
                                  <Trans i18nKey="event.unselectAll" />
                                ) : (
                                  <Trans i18nKey="event.selectAll" />
                                )}
                              </Button>
                              {globalUserCompany.map((company) => (
                                <MenuItem key={company._id} value={company._id}>
                                  {company.companyName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        );
                      }}
                    </Field>
                    <Box sx={{ width: '24px' }}></Box>
                    <Field name="placementIds">
                      {(props) => {
                        const isAllSelected =
                          props.input.value.length ===
                          globalHardwarePlacements.filter((placement) =>
                            values.companyIds.includes(placement.companyId)
                          ).length;
                        return (
                          <FormControl fullWidth>
                            <Select<Array<string>>
                              {...props.input}
                              multiple
                              displayEmpty
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return (
                                    <em>
                                      <Trans i18nKey="event.placements" />
                                    </em>
                                  );
                                }
                                return selected
                                  .map(
                                    (s) =>
                                      globalHardwarePlacements.find(
                                        (guc) => guc._id === s
                                      )?.name
                                  )
                                  .join(', ');
                              }}
                            >
                              <MenuItem disabled value="">
                                <em>
                                  <Trans i18nKey="event.placements" />
                                </em>
                              </MenuItem>
                              <Button
                                fullWidth
                                onClick={() => {
                                  if (isAllSelected) {
                                    props.input.onChange([]);
                                  } else {
                                    props.input.onChange(
                                      globalHardwarePlacements
                                        .filter((placement) =>
                                          values.companyIds.includes(
                                            placement.companyId
                                          )
                                        )
                                        .map((ghp) => ghp._id)
                                    );
                                  }
                                }}
                              >
                                {isAllSelected ? (
                                  <Trans i18nKey="event.unselectAll" />
                                ) : (
                                  <Trans i18nKey="event.selectAll" />
                                )}
                              </Button>
                              {renderPlacement(
                                globalHardwarePlacements.filter((placement) =>
                                  values.companyIds.includes(
                                    placement.companyId
                                  )
                                ),
                                globalUserCompany
                              )}
                            </Select>
                          </FormControl>
                        );
                      }}
                    </Field>
                  </Box>
                </Grid>
              </Grid>
              <Grid item container columnGap={'12px'}>
                <Grid item xs>
                  <Box display="flex" alignItems="flex-start">
                    <Field name="resolver">
                      {(props) => {
                        return (
                          <FormControl fullWidth>
                            <Select<string>
                              {...props.input}
                              displayEmpty
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return (
                                    <em>
                                      <Trans i18nKey="event.resolver" />
                                    </em>
                                  );
                                }
                                return userData?.usersData.find(
                                  (c) => c._id === selected
                                )?.email;
                              }}
                            >
                              <MenuItem disabled value="">
                                <em>
                                  <Trans i18nKey="event.resolver" />
                                </em>
                              </MenuItem>
                              {userData?.usersData.map((user) => (
                                <MenuItem key={user._id} value={user._id}>
                                  {user.email}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        );
                      }}
                    </Field>
                    <Box sx={{ width: '24px' }}></Box>
                    <DatePicker inputFormat="dd.MM.yyyy" name="date" required />
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Field name="description">
                  {(props) => (
                    <TextField
                      size="small"
                      multiline
                      placeholder={t('event.description') as string}
                      inputProps={{
                        style: {
                          height: '98px',
                          overflow: 'visible',
                        },
                      }}
                      fullWidth
                      {...props.input}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Box sx={{ marginTop: '8px', padding: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  boxShadow: 'none',
                  width: '100%',
                }}
              >
                <Trans i18nKey="event.save" />
              </Button>
            </Box>
          </form>
        );
      }}
    />
  );
};
