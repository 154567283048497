import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UserContext } from '.';
import { Currency, PaymentDetails } from '../commonTypes';

export const PaymentContext = createContext<{
  payment: PaymentDetails;
  isLoading: boolean;
  refreshPayment: () => void;
  setCurrency: (currency: Currency) => void;
  currency?: string | null;
  resetCurrency: () => void;
}>({
  payment: { companyIban: '', totalSumToPay: '' },
  refreshPayment: () => {},
  isLoading: false,
  currency: '',
  setCurrency: () => {},
  resetCurrency: () => {},
});

export const PaymentContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { userData, defaultCurrency } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [payment, setPaymentDetails] = useState<PaymentDetails>({
    companyIban: '',
    totalSumToPay: '',
  });
  const [currency, setCurrency] = useState<Currency | null | undefined>(
    defaultCurrency
  );

  const refreshPayment = async () => {
    setIsLoading(true);

    const dbPayment = await fetch(
      `/api/payment/payment-details?currency=${currency}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    ).then((data) => data.json());

    setPaymentDetails(dbPayment);

    setIsLoading(false);
  };

  useEffect(() => {
    setCurrency(defaultCurrency);
  }, [defaultCurrency]);

  useEffect(() => {
    if (userData && currency) {
      refreshPayment();
    }
  }, [userData, currency]); // eslint-disable-line

  const resetCurrency = () => {
    setCurrency(defaultCurrency);
  };

  return (
    <PaymentContext.Provider
      value={{
        payment,
        isLoading,
        refreshPayment,
        setCurrency,
        currency,
        resetCurrency,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
