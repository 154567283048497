import React, { FC, useContext } from 'react';
import { Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { UserContext } from '../contexts';
import { Trans } from '../i18n';
import { Employee } from '../commonTypes';
import { Dialog } from '../components';

interface SendEmployeeEquipmentDialogProps {
  onClose: () => void;
  data: Employee;
}

export const SendEmployeeEquipmentDialog: FC<
  SendEmployeeEquipmentDialogProps
> = (props) => {
  const { onClose, data } = props;
  const { userData } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (employeeId?: string) => {
    try {
      if (employeeId) {
        const res = await fetch(
          `/api/hardware/send-employee-equipment-email/${employeeId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userData!.user.token}`,
            },
            method: 'PUT',
          }
        );
        if (res.status === 200) {
          enqueueSnackbar(
            <Trans i18nKey="notifications.confirmationSended" />,
            {
              variant: 'success',
            }
          );
        }
      }
    } catch (exception) {
      console.log(exception);

      enqueueSnackbar(<Trans i18nKey="notifications.invalidLink" />, {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog
      title={
        data.email ? (
          data.email
        ) : (
          <Trans i18nKey="sendEmployeeEquipmentDialog.title" />
        )
      }
      onClose={onClose}
    >
      <>
        <Trans i18nKey="sendEmployeeEquipmentDialog.description" />
        <Grid
          container
          xs={12}
          sx={{
            width: '100%',
            position: 'sticky',
            left: '0px',
            bottom: '-22px',
            padding: '16px 0px',
            background: 'white',
          }}
        >
          <Grid container spacing={1} marginBottom={1} marginTop={1}>
            <Grid item xs={6}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                sx={{
                  boxShadow: 'none',
                  width: '100%',
                }}
                onClick={() => {
                  onSubmit(data._id);
                  onClose();
                }}
              >
                <Trans i18nKey="labels.yes" />
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                sx={{
                  boxShadow: 'none',
                  width: '100%',
                }}
                onClick={onClose}
              >
                <Trans i18nKey="labels.no" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Dialog>
  );
};
