import React, { FC, useCallback, useEffect, useState } from 'react';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import { Button, Grid, styled } from '@mui/material';
import { PictureAsPdfOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { FileUploadDialog } from '../FileUpload';
import { UserContext } from '../../contexts';
import { Hardware } from '../../commonTypes';

interface GuideFormProps {
  onCancel: () => void;
  data: Hardware;
  openStickerPrint: () => void;
}

interface CustomOptionProps {
  onSelect: (url: string, title: string) => void;
  setEditorState: (state: EditorState) => void;
  dataId?: string;
}

const CreatePdfOption = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '25px',
  height: '20px',
  margin: '0 4px',
  padding: '5px',
  borderRadius: '2px',
  border: '1px solid #f1f1f1',
  cursor: 'pointer',
}));

const CustomOption: FC<CustomOptionProps> = ({ onSelect, dataId }) => {
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);

  const handleFileSelect = (url: string, title: string) => {
    setOpenFileUploadDialog(false);
    onSelect(url, title);
  };

  return (
    <>
      {openFileUploadDialog && (
        <FileUploadDialog
          onClose={() => setOpenFileUploadDialog(false)}
          documentId={dataId}
          onSelect={handleFileSelect}
        />
      )}
      <CreatePdfOption onClick={() => setOpenFileUploadDialog(true)}>
        <PictureAsPdfOutlined />
      </CreatePdfOption>
    </>
  );
};

export const GuideForm: FC<GuideFormProps> = (props) => {
  const { onCancel, data, openStickerPrint } = props;

  const { userData } = useContext(UserContext);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (data: Hardware) => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    try {
      const createGuide = await fetch('/api/guide/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'POST',
        body: JSON.stringify({
          hwId: data._id,
          manualContent: html,
        }),
      });
      const res = await createGuide.json();
      if (res.error) {
        enqueueSnackbar(`${res.error}`, {
          variant: 'error',
        });
        return;
      }
      enqueueSnackbar(<Trans i18nKey="notifications.saved" />, {
        variant: 'success',
      });
      onCancel();
      openStickerPrint();
    } catch (exception) {
      console.log(exception);
    }
  };

  const updateEditorState = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const getGuide = useCallback(async () => {
    const res = await fetch(`/api/guide/${data._id}`, {
      headers: {
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'GET',
    }).then((data) => data.json());

    if (res) {
      const blocksFromHtml = htmlToDraft(res.manualContent);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      updateEditorState(editorState);
    }
  }, [data, userData]);

  useEffect(() => {
    getGuide();
  }, [getGuide]);

  const uploadImageCallBack = (file: File) => {
    return new Promise((resolve, _reject) => {
      const data = new FormData();
      data.append('file', file);

      fetch(`/api/guide/create-file-img`, {
        headers: {
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'POST',
        body: data,
      }).then((data) =>
        data.json().then((r) => resolve({ data: { link: r.url } }))
      );
    });
  };

  const handleFileSelect = async (url: string, title: string) => {
    await new Promise((resolve) => requestAnimationFrame(resolve));

    const currentEditorState = editorState;

    const contentState = currentEditorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newContentState = Modifier.replaceText(
      contentStateWithEntity,
      currentEditorState.getSelection(),
      title,
      undefined,
      entityKey
    );

    const newEditorState = EditorState.push(
      currentEditorState,
      newContentState,
      'insert-characters'
    );

    updateEditorState(newEditorState);
  };

  return (
    <Grid
      container
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Grid item xs={12}>
        <Editor
          toolbarClassName="editorToolbar"
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'emoji',
              'history',
              'link',
              'image',
            ],

            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: {
              uploadEnabled: true,
              uploadCallback: uploadImageCallBack,
              alt: { present: true, mandatory: false },
              previewImage: true,
            },
          }}
          editorState={editorState}
          onEditorStateChange={updateEditorState}
          toolbarCustomButtons={[
            <CustomOption
              onSelect={handleFileSelect}
              setEditorState={updateEditorState}
              dataId={data._id}
            />,
          ]}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ marginTop: 16 }}
        sx={{
          width: '100%',
          position: 'sticky',
          left: '0px',
          bottom: '-18px',
          padding: '16px 0px',
          background: 'white',
          display: 'flex',
          alignItems: 'flex-end',
          zIndex: '10',
        }}
      >
        <Grid container justifyContent="center" xs={12}>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                marginBottom: '8px',
              }}
              onClick={() => handleSubmit(data)}
            >
              <Trans i18nKey="labels.save" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button type="button" fullWidth onClick={onCancel}>
              <Trans i18nKey="labels.close" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
