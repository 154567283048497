import { Field } from 'react-final-form';
import {
  Button,
  Grid,
  TablePagination,
  styled,
  AccordionSummary,
} from '@mui/material';

const ButtonWithoutShadow = styled(Button)(({ theme }) => ({
  minWidth: '50px',
  boxShadow: 'none',
}));

const StyledDiv = styled('div')(({ theme }) => ({
  fontSize: '13px',
  wordBreak: 'break-word',
}));

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiToolbar-root': {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiBox-root': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const AddButton = styled(Button)(({ theme }) => ({
  zIndex: 1205,
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    width: '64px',
    height: '64px',
    borderRadius: '100%',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  zIndex: 1205,
  textWrap: 'nowrap',
  margin: '0px 4px 4px 4px',
  [theme.breakpoints.down('sm')]: {
    margin: '0px 4px 4px 0',
  },
}));

const StyledDot = styled('div')<{ ownerState: { dotColor: string } }>(
  ({ theme, ownerState: { dotColor = 'lime' } }) => ({
    width: '15px',
    height: '15px',
    backgroundColor: dotColor,
    borderRadius: '100%',
    cursor: 'pointer',
  })
);

const StyledGrid = styled(Grid)(({ theme }) => ({
  zIndex: 1205,
  [theme.breakpoints.down('sm')]: {
    padding: '8px 0px 8px 0px',
  },
  [theme.breakpoints.down('lg')]: {
    padding: '4px 0px 4px 0px',
    borderBottom: '1px solid #D9D9D9',
  },
}));

const StyledTextField = styled(Field)(({ theme }) => ({
  padding: '16px 0px 16px 0px',
  border: 'unset',
  width: '100%',
  fontSize: '14px',
}));

const Link = styled('div')(({ theme }) => ({
  textDecoration: 'underline',
  cursor: 'pointer',
}));

const StyledTableColumnTitle = styled('p')(({ theme }) => ({
  fontSize: '13px',
}));

const StyledTableTitle = styled('p')(({ theme }) => ({
  fontSize: '18px',
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: 0,
  minHeight: 'unset',
  margin: 0,
  width: 'fit-content',
  '&.Mui-expanded': {
    minHeight: 'unset',
  },
  '& .MuiButtonBase-root': {
    padding: 0,
    margin: 0,
    flexShrink: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 0,
    justifyContent: 'left',
  },
}));

const GreenDot = styled('div')(({ theme }) => ({
  width: '15px',
  height: '15px',
  backgroundColor: 'lime',
  borderRadius: '100%',
  cursor: 'pointer',
}));

const RedDot = styled('div')(({ theme }) => ({
  width: '15px',
  height: '15px',
  backgroundColor: 'red',
  borderRadius: '100%',
  cursor: 'pointer',
}));

export {
  ButtonWithoutShadow,
  StyledDiv,
  StyledTablePagination,
  AddButton,
  StyledButton,
  StyledDot,
  StyledGrid,
  StyledTextField,
  Link,
  StyledTableColumnTitle,
  StyledTableTitle,
  StyledAccordionSummary,
  GreenDot,
  RedDot,
};
