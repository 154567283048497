import { AppBar, Drawer, ListItemButton, styled } from '@mui/material';

const drawerWidth = 250;

const RootContainer = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const StyledDrawerList = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const StyledContent = styled('main')(({ theme }) => ({
  minHeight: '100vh',
  width: '80%',
  flexGrow: 1,
  backgroundColor: '#F7F7F7',
}));

const ContetnWithoutPadding = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(10),
  padding: theme.spacing(0),
}));

const StyledChildrenContent = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  padding: theme.spacing(3),
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: 1205,
  [theme.breakpoints.up('sm')]: {
    width: '100%',
    marginLeft: drawerWidth,
  },
}));

const LogoImage = styled('img')(({ theme }) => ({
  width: '120px',
}));

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  margin: '10px 0',
  borderRadius: '8px',
}));

const StyledDrawerPaper = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    borderRight: 0,
    boxShadow: '2px 0px 7px #8C8C8C1A',
    display: 'flex',
    alignItems: 'center',
  },
}));

export {
  RootContainer,
  StyledDrawerList,
  StyledContent,
  ContetnWithoutPadding,
  StyledChildrenContent,
  StyledAppBar,
  LogoImage,
  StyledListItem,
  StyledDrawerPaper,
};
