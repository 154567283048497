import React, { FC, useContext } from 'react';
import { Trans } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { get } from 'lodash';
import { Grid, Button, TextField } from '@mui/material';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import { UserContext, HardwareContext } from '../contexts';
import { Employee, Hardware } from '../commonTypes';
import { UserSelectField } from '../users';
import { Dialog } from '../components';

interface BorrowDialogProps {
  values?: Hardware;
  onClose: () => void;
  onSubmit: (res: Hardware) => void;
}

export const BorrowDialog: FC<BorrowDialogProps> = (props) => {
  const { values, onClose, onSubmit } = props;
  const { setHardwareUpdate } = useContext(HardwareContext);
  const { userData } = useContext(UserContext);

  const handleSubmit = async (formValues: Hardware) => {
    if (values?.borrowedBy === formValues.borrowedBy) {
      return;
    }

    try {
      const res = await fetch(`/api/hardware/${values?._id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'PUT',
        body: JSON.stringify({
          borrowingConfirmed: false,
          borrowedDate: formValues.borrowedDate,
          borrowedBy:
            formValues.borrowedBy === -1 ? null : formValues.borrowedBy,
        }),
      }).then((data) => data.json());
      if (res.error) {
        return;
      }

      onSubmit(res);
      setHardwareUpdate(true);
    } catch (exception) {
      console.log(exception);
    }
  };

  return (
    <Dialog
      title={<Trans i18nKey="borrowDialog.borrowHardware" />}
      onClose={onClose}
    >
      <Form
        initialValues={{ ...values, borrowedDate: new Date() }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting, values, pristine }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field name="borrowedBy">
                    {(props) => {
                      return (
                        <UserSelectField
                          selectedValue={values.companyId}
                          value={get(props, 'input.value', undefined)}
                          onChange={(emp?: Employee) =>
                            props.input.onChange(emp?._id)
                          }
                          showAddNew={true}
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="borrowedDate">
                    {(props) => {
                      return (
                        <DesktopDatePicker
                          label={
                            <Trans i18nKey="borrowDialog.borrowDatePickerLabel" />
                          }
                          inputFormat="dd.MM.yyyy"
                          value={props.input.value}
                          onChange={props.input.onChange}
                          renderInput={(params) => (
                            <TextField {...params} sx={{ width: '100%' }} />
                          )}
                        />
                      );
                    }}
                  </Field>
                </Grid>

                <Grid
                  item
                  xs={12}
                  position={{ xs: 'sticky', lg: 'relative' }}
                  bottom={{ xs: '-16px' }}
                  bgcolor={{ xs: 'white' }}
                  paddingBottom="16px"
                  sx={{
                    marginTop: '8px',
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    spacing={{ xs: 0, lg: 2 }}
                    sx={{
                      maxWidth: '100%',
                      width: '100%',

                      background: '#fff',
                    }}
                  >
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={pristine || submitting}
                        sx={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.save" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        type="button"
                        variant="text"
                        onClick={onClose}
                        sx={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.cancel" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </Dialog>
  );
};
