import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { UserContext } from '../../contexts';
import { Events } from '../../commonTypes';
import { format } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { AlertDialog } from '../Dialogs';
import { EventDialog } from '../Dialogs/EventDialog';

interface EventDetailFormProps {
  eventId: string;
  onClose?: () => void;
  handleDelete?: (id: string) => void;
}

const EventDetailForm: FC<EventDetailFormProps> = ({
  eventId,
  onClose,
  handleDelete,
}) => {
  const { t } = useTranslation();

  const { userData } = useContext(UserContext);

  const [eventDetail, setEventDetail] = useState<Events>();
  const [openAlertDialog, setOpenAlertDialog] = useState<string>();
  const closeAlertDialog = (index?: string) => {
    setOpenAlertDialog(index);
  };
  const [openNewEventDialog, setOpenNewEventDialog] = useState<
    boolean | string
  >(false);

  const getEvent = useCallback(async () => {
    if (!eventId) return;
    const res = await fetch(`/api/events/detail/${eventId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'GET',
    });
    if (res.ok) {
      const data = await res.json();
      setEventDetail({ ...data, assigneeId: data.assignee });
    }
  }, [eventId, userData]);

  useEffect(() => {
    getEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!eventDetail)
    return (
      <Box display={'flex'} justifyContent={'center'}>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Box>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant={'h6'}>{eventDetail?.name}</Typography>
        </Box>
        <Box mt={'8px'} display={'flex'}>
          <Typography fontWeight={'500'} mr={1}>
            {t('events.detailModal.date')}
          </Typography>
          {format(new Date(eventDetail.date), 'dd.MM.yyyy')}
        </Box>
        <Box display={'flex'}>
          <Typography fontWeight={'500'} mr={1}>
            {t('events.detailModal.resolver')}
          </Typography>
          {
            userData?.usersData?.find((ud) => ud._id === eventDetail.resolver)
              ?.email
          }
        </Box>
        <Box display={'flex'}>
          <Typography fontWeight={'500'} mr={1}>
            {t('events.detailModal.description')}
          </Typography>
          {eventDetail.description}
        </Box>
      </Box>
      <Box mt={4}>
        <Box>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              marginBottom: '8px',
            }}
            onClick={() => {
              setOpenNewEventDialog(eventDetail._id);
            }}
          >
            <Trans i18nKey="events.edit" />
          </Button>
        </Box>

        <Box display={'flex'} justifyContent={'space-between'}>
          <Box width={'50%'}>
            <Button
              type="button"
              color="error"
              variant="contained"
              style={{
                boxShadow: 'none',
                width: '100%',
              }}
              onClick={() => closeAlertDialog(eventDetail._id)}
            >
              <Trans i18nKey="events.delete" />
            </Button>
          </Box>
          <Box width={'50%'}>
            <Button type="button" fullWidth onClick={onClose}>
              <Trans i18nKey="events.close" />
            </Button>
          </Box>
        </Box>
      </Box>
      {openNewEventDialog && (
        <EventDialog
          eventId={
            typeof openNewEventDialog === 'string'
              ? openNewEventDialog
              : undefined
          }
          onClose={() => setOpenNewEventDialog(false)}
          onSubmit={() => {
            setOpenNewEventDialog(false);
            getEvent();
          }}
        />
      )}
      {openAlertDialog ? (
        <AlertDialog
          onClose={() => closeAlertDialog()}
          onDelete={() => {
            handleDelete?.(eventDetail._id);
            onClose?.();
          }}
        />
      ) : null}
    </>
  );
};

export default EventDetailForm;
