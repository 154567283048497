import React, { FC, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Trans } from '../../i18n';
import { SelectProps } from '../../commonTypes';

export const IssueResolveSelect: FC<SelectProps<string>> = (props) => {
  const [defVal, setDefVal] = useState('inprogress');
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    props.onChange(selectedValue);
    setDefVal(selectedValue);
  };

  return (
    <FormControl error={!!props.error} fullWidth>
      <Select
        defaultValue={defVal}
        onChange={handleChange}
        renderValue={(selected?: string) => {
          if (!selected) {
            return (
              <FormHelperText sx={{ color: '#0000004D' }}>
                <Trans i18nKey="resolveSelect.choose" />
              </FormHelperText>
            );
          }

          if (selected === 'inprogress')
            return <Trans i18nKey="resolveSelect.inprogress" />;
          else if (selected === 'done')
            return <Trans i18nKey="resolveSelect.done" />;
          else return <Trans i18nKey="resolveSelect.choose" />;
        }}
      >
        <MenuItem disabled value={undefined}>
          <FormHelperText>
            <Trans i18nKey="resolveSelect.choose" />
          </FormHelperText>
        </MenuItem>
        <MenuItem value={'inprogress'}>
          <Trans i18nKey="resolveSelect.inprogress" />
        </MenuItem>
        <MenuItem value={'done'}>
          <Trans i18nKey="resolveSelect.done" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};
