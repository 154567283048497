import React, { FC, useContext } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import formatDate from 'date-fns/format';
import { Grid } from '@mui/material';

import { HardwareRevisionTypeContext } from '../../../contexts';
import { StyledGrid } from '../../../customStyledComponents';
import { Company, Hardware } from '../../../commonTypes';

interface TabPanelDetailProps {
  dataDetail?: Hardware;
  detailCompany?: Company;
  detail?: Hardware;
  employee?: string | null | number;
  isCompanyVATincluded?: boolean;
}

export const TabPanelDetail: FC<TabPanelDetailProps> = (props) => {
  const { dataDetail, detailCompany, detail, employee, isCompanyVATincluded } =
    props;

  const { globalHardwareRevisionType } = useContext(
    HardwareRevisionTypeContext
  );

  const getRevisionData = () => {
    const revisionTypeData = globalHardwareRevisionType?.find(
      (rf) =>
        rf._id ===
        (typeof dataDetail?.revisionType === 'object'
          ? dataDetail?.revisionType?._id
          : dataDetail?.revisionType)
    );
    return revisionTypeData?.name;
  };

  const revisionData = getRevisionData();

  return (
    <>
      <Grid xs={12}>
        <StyledGrid>
          <Grid container>
            <Grid item xs={6}>
              <Trans i18nKey="detail.description" />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {dataDetail?.description ?? '--'}
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>

      <Grid xs={12}>
        <StyledGrid>
          <Grid container>
            <Grid item xs={6}>
              <Trans i18nKey="detail.company" />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {detailCompany?.companyName ? detailCompany.companyName : '--'}
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>

      <Grid xs={12}>
        <StyledGrid>
          <Grid container>
            <Grid item xs={6}>
              <Trans i18nKey="detail.type" />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {detail?.type.name ? detail.type.name : '--'}
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>

      <Grid xs={12}>
        <StyledGrid>
          <Grid container>
            <Grid item xs={6}>
              <Trans i18nKey="detail.granted" />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {employee ? employee : '--'}
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>

      <Grid xs={12}>
        <StyledGrid>
          <Grid container>
            <Grid item xs={6}>
              <Trans i18nKey="detail.purchaseDate" />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {dataDetail?.dateOfPurchase
                ? formatDate(new Date(dataDetail.dateOfPurchase), 'dd.MM.yyyy')
                : '--'}
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>

      <Grid xs={12}>
        <StyledGrid>
          <Grid container>
            <Grid item xs={6}>
              <Trans i18nKey="detail.guaranteeDate" />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {dataDetail?.guaranteeUntil
                ? formatDate(new Date(dataDetail.guaranteeUntil), 'dd.MM.yyyy')
                : '--'}
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>

      <Grid xs={12}>
        <StyledGrid>
          <Grid container>
            <Grid item xs={6}>
              <Trans i18nKey="detail.revisionDate" />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {dataDetail?.revisionNext
                ? formatDate(new Date(dataDetail.revisionNext), 'dd.MM.yyyy')
                : '--'}
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>
      <Grid xs={12}>
        <StyledGrid>
          <Grid container>
            <Grid item xs={6}>
              <Trans i18nKey="detail.revisionType" />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {revisionData}
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>

      <Grid xs={12}>
        <StyledGrid>
          <Grid container>
            <Grid item xs={6}>
              <Trans i18nKey="detail.placement" />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {dataDetail?.placement?.name ? dataDetail.placement.name : '--'}
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>
      {!detail?.type.smallEquipments ||
      detail?.type.smallEquipments === 'normal' ? (
        <Grid xs={12}>
          <StyledGrid>
            <Grid container>
              <Grid item xs={6}>
                <Trans i18nKey="detail.inventoryNumber" />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ textAlign: 'right' }}
                data-testid="info-modal-inventory-num"
              >
                {detail?.inventoryNumber ? detail.inventoryNumber : '--'}
              </Grid>
            </Grid>
          </StyledGrid>
        </Grid>
      ) : null}
      <Grid xs={12}>
        <StyledGrid>
          <Grid container>
            <Grid item xs={6}>
              {isCompanyVATincluded ? (
                <Trans i18nKey="addHardwareForm.priceVATincluded" />
              ) : (
                <Trans i18nKey="addHardwareForm.priceWithoutVAT" />
              )}
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {dataDetail?.price ? dataDetail.price.toLocaleString() : '--'}
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>
      {!detail?.type.smallEquipments ||
      detail?.type.smallEquipments === 'normal' ? (
        <Grid xs={12}>
          <StyledGrid>
            <Grid container>
              <Grid item xs={6}>
                <Trans i18nKey="detail.serialNumber" />
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                {dataDetail?.serialNumber ? dataDetail.serialNumber : '--'}
              </Grid>
            </Grid>
          </StyledGrid>
        </Grid>
      ) : null}

      {!detail?.type.smallEquipments ||
      detail?.type.smallEquipments === 'small' ? (
        <Grid xs={12}>
          <StyledGrid>
            <Grid container>
              <Grid item xs={6}>
                <Trans i18nKey="detail.count" />
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                {detail?.count ? detail.count : '--'}
                {detail?.countUnit
                  ? t(`countUnitSelect.${detail.countUnit}`)
                  : ''}
              </Grid>
            </Grid>
          </StyledGrid>
        </Grid>
      ) : null}
      <Grid xs={12}>
        <StyledGrid>
          <Grid container>
            <Grid item xs={6}>
              <Trans i18nKey="detail.smallEquipment" />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {detail?.type.smallEquipments === 'small' ? 'Ano' : 'Ne'}
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>
    </>
  );
};
