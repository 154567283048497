import React, { FC, useContext } from 'react';
import { Trans } from 'react-i18next';
import { Grid, Button } from '@mui/material';

import { Dialog } from '../Dialog';
import { UserContext } from '../../contexts';

interface LogoutDialogProps {
  onClose?: () => void;
}

export const LogoutDialog: FC<LogoutDialogProps> = (props) => {
  const { onClose } = props;
  const { logout } = useContext(UserContext);

  return (
    <Dialog title={<Trans i18nKey="labels.logout" />} onClose={onClose}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Button
            onClick={logout}
            variant="contained"
            type="submit"
            color="primary"
            data-testid="logout-dialog-confirm-btn"
          >
            <Trans i18nKey="labels.yes" />
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={onClose} variant="contained" type="button">
            <Trans i18nKey="labels.no" />
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
