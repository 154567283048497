import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
  FC,
  PropsWithChildren,
} from 'react';
import { Company } from '../commonTypes';
import { UserContext } from './UserContext';

interface ICompanyContext {
  globalCompany: Company[];
  setGlobalCompany: Dispatch<SetStateAction<Company[]>>;
  globalUserCompany: Company[];
  setUserGlobalCompany: Dispatch<SetStateAction<Company[]>>;
  refreshCompany: () => void;
  setCompanyUpdate: (update: boolean) => void;
  globalAdminCompany: Company[];
  setAdminGlobalCompany: Dispatch<SetStateAction<Company[]>>;
  globalNonUserCompany: Company[];
  setNonUserGlobalCompany: Dispatch<SetStateAction<Company[]>>;
}

export const CompanyContext = createContext<Partial<ICompanyContext>>({
  globalCompany: [],
  setGlobalCompany: () => {},
  globalUserCompany: [],
  setUserGlobalCompany: () => {},
  setCompanyUpdate: () => {},
  globalAdminCompany: [],
  setAdminGlobalCompany: () => {},
  globalNonUserCompany: [],
  setNonUserGlobalCompany: () => {},
});

export const CompanyContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { userData } = useContext(UserContext);
  const [globalCompany, setGlobalCompany] = useState<Array<Company>>([]);
  const [globalUserCompany, setUserGlobalCompany] = useState<Array<Company>>(
    []
  );
  const [globalNonUserCompany, setNonUserGlobalCompany] = useState<
    Array<Company>
  >([]);
  const [globalAdminCompany, setAdminGlobalCompany] = useState<Array<Company>>(
    []
  );
  const [companyUpdate, setCompanyUpdate] = useState(false);

  const refreshCompany = async () => {
    if (userData) {
      const getUserCompany = async () => {
        const dbUserCompany = await fetch('/api/companies/userCompany', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'GET',
        }).then((data) => data.json());
        const activeUserCompany = dbUserCompany.filter(
          (userCompany: Company) => userCompany.isDeleted === false
        );

        setUserGlobalCompany(activeUserCompany);
      };
      getUserCompany();
    }

    if (userData) {
      const getNonUserCompany = async () => {
        const dbNonUserCompany = await fetch('/api/companies/userCompany', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'GET',
        }).then((data) => data.json());
        const activeNonUserCompany = dbNonUserCompany.filter(
          (userCompany: Company) => userCompany.isDeleted === false
        );
        const nonUserCompany = activeNonUserCompany.filter(
          (userCompany: Company) =>
            userCompany.users.filter(
              (uc) => uc.userRole !== 'User' && uc.uid === userData.user._id
            ).length > 0
        );
        setNonUserGlobalCompany(nonUserCompany);
      };
      getNonUserCompany();
    }

    if (userData) {
      const getAdminCompany = async () => {
        const dbAdminCompany = await fetch(
          '/api/companies/userCompany?includeuserRole=Admin',
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userData!.user.token}`,
            },
            method: 'GET',
          }
        ).then((data) => data.json());
        const activeAdminCompany = dbAdminCompany.filter(
          (adminCompany: Company) => adminCompany.isDeleted === false
        );
        setAdminGlobalCompany(activeAdminCompany);
      };
      getAdminCompany();
    }
  };

  useEffect(() => {
    refreshCompany();
  }, [userData, companyUpdate]); // eslint-disable-line

  return (
    <CompanyContext.Provider
      value={{
        globalCompany,
        setGlobalCompany,
        globalUserCompany,
        setUserGlobalCompany,
        refreshCompany,
        setCompanyUpdate,
        globalAdminCompany,
        setAdminGlobalCompany,
        globalNonUserCompany,
        setNonUserGlobalCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
