import React, { useCallback, useContext } from 'react';
import { Trans } from 'react-i18next';
import { pick } from 'lodash';
import { useSnackbar } from 'notistack';

import { HardwarePlacementContext, UserContext } from '../contexts';
import { PlacementType } from '../commonTypes';

export const useUpdateItem = (
  onUpdate?: (placement: PlacementType[]) => void
) => {
  const { globalHardwarePlacements } = useContext(HardwarePlacementContext);
  const { userData } = useContext(UserContext);

  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    async (row: PlacementType) => {
      const _placements = [...(globalHardwarePlacements ?? [])];
      try {
        const updatedItem = _placements.find((a) => {
          return a?._id === row?._id;
        });

        if (updatedItem) {
          fetch(`/api/places/update/${row._id}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userData!.user.token}`,
            },
            method: 'PUT',
            body: JSON.stringify({
              ...pick(row, ['name']),
              id: row._id,
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              const index = _placements.findIndex(({ _id }) => res._id === _id);
              _placements.splice(index, 1, res);
              onUpdate?.(_placements);
            });

          enqueueSnackbar(<Trans i18nKey="notifications.saved" />, {
            variant: 'success',
          });
        }
      } catch (exception) {
        console.log(exception);
        enqueueSnackbar(<Trans i18nKey="notifications.errorWhenSaving" />, {
          variant: 'error',
        });
      }
    },
    [globalHardwarePlacements, userData, enqueueSnackbar, onUpdate]
  );
};
