import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Grid } from '@mui/material';

import { Employee } from '../commonTypes';
import { Trans } from '../i18n';
import { Dialog } from '../components';
import { StyledGrid, StyledTextField } from '../customStyledComponents';

interface UsersEditDialogProps {
  onClose: () => void;
  employeeData: Employee;
  onUpdate: (em: Employee) => void;
}

export const UsersEditDialog: FC<UsersEditDialogProps> = (props) => {
  const { onClose, onUpdate, employeeData } = props;

  const handleSubmit = (data: Employee) => {
    let updatedEmployeeData: Employee = {
      ...employeeData,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
    };

    onUpdate(updatedEmployeeData);
    onClose();
  };

  const initialValues: Employee = {
    email: employeeData.email,
    firstName: employeeData.firstName,
    lastName: employeeData.lastName,
  };

  return (
    <Dialog
      title={employeeData.email ?? <Trans i18nKey="detail.detailTitle" />}
      onClose={onClose}
    >
      <>
        <Form
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, values, pristine }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid>
                  <Grid xs={12} sx={{ marginBottom: '20px' }}>
                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="usersEditDialog.firstname" />
                        </Grid>
                        <Grid item xs={6}>
                          <StyledTextField
                            inputProps={{ style: { textAlign: 'right' } }}
                            type="text"
                            name="firstName"
                            margin="none"
                            fullWidth
                            required
                            variant="standard"
                            component="input"
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>

                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="usersEditDialog.lastname" />
                        </Grid>
                        <Grid item xs={6}>
                          <StyledTextField
                            inputProps={{ style: { textAlign: 'right' } }}
                            type="text"
                            name="lastName"
                            margin="none"
                            fullWidth
                            required
                            variant="standard"
                            component="input"
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>

                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="usersEditDialog.email" />
                        </Grid>
                        <Grid item xs={6}>
                          <StyledTextField
                            inputProps={{ style: { textAlign: 'right' } }}
                            type="text"
                            name="email"
                            margin="none"
                            fullWidth
                            required
                            variant="standard"
                            component="input"
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    xs={12}
                    sx={{
                      width: '100%',
                      position: 'sticky',
                      left: '0px',
                      bottom: '-18px',
                      padding: '16px 0px',
                      background: 'white',
                    }}
                  >
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={pristine || submitting}
                        style={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.save" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        type="button"
                        variant="text"
                        onClick={onClose}
                        style={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.cancel" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </>
    </Dialog>
  );
};
