import React, { FC, useState } from 'react';
import QRCode from 'react-qr-code';
import { Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ModeIcon from '@mui/icons-material/Mode';

import { StickerDialog } from '../Dialogs';
import { Trans } from '../../i18n';
import { getQRQuide } from '../../utils';

interface GuideDetailFormProps {
  onCancel: () => void;
  openGuideEditor?: () => void;
  guideId?: string;
}

export const GuideDetailForm: FC<GuideDetailFormProps> = (props) => {
  const { onCancel, openGuideEditor, guideId } = props;

  const [isStickerDialogOpen, setStickerDialogOpen] = useState(false);
  const [stickerData, setStickerData] = useState<string[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleRedirect = (path: string) => {
    window.open(path, '_blank');
  };

  const downloadPng = async (content: string) => {
    if (content) {
      const res = await fetch('/api/hardware/download-qr-code', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          content: content,
        }),
      }).then((data) => data.json());
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.download = 'qr.png';
      link.href = res.qr;
      link.click();
      link.remove();
      enqueueSnackbar(<Trans i18nKey="notifications.downloading" />, {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(<Trans i18nKey="notifications.errorWhenDownloading" />, {
        variant: 'error',
      });
    }
  };

  const copyPng = async (content: string) => {
    if (content) {
      const res = await fetch('/api/hardware/download-qr-code', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          content: content,
        }),
      }).then((data) => data.json());

      const image = new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
        img.src = res.qr;
      }).then((img) => {
        let canvas = document.createElement('canvas');
        canvas.width = 165;
        canvas.height = 165;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img as HTMLImageElement, 0, 0);

        return canvas.toBlob((blob) => {
          if (blob) {
            navigator.clipboard.write([
              new ClipboardItem({
                'image/png': blob as unknown as ClipboardItemData,
              }),
            ]);
            enqueueSnackbar(<Trans i18nKey="notifications.copyToClipboard" />, {
              variant: 'success',
            });
          } else {
            enqueueSnackbar(
              <Trans i18nKey="notifications.errorCopyToClipboard" />,
              {
                variant: 'error',
              }
            );
            return;
          }
        });
      });

      return image;
    }
  };

  return (
    <Grid container xs={12}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <QRCode size={200} value={getQRQuide(guideId!)} />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '16px',
        }}
      >
        <Button
          onClick={() => {
            openGuideEditor?.();
          }}
        >
          <ModeIcon />
        </Button>
        <Button
          onClick={() => {
            copyPng(`/guide/${guideId}`);
          }}
        >
          <ContentCopyIcon />
        </Button>
        <Button
          onClick={() => {
            downloadPng(`/guide/${guideId}`);
          }}
        >
          <DownloadIcon />
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        position={{ xs: 'sticky', lg: 'relative' }}
        bottom={{ xs: '-16px', lg: '0px' }}
        bgcolor={{ xs: 'white' }}
        sx={{
          marginTop: '16px',
        }}
      >
        <Grid
          container
          justifyContent="center"
          spacing={{ xs: 0, lg: 2 }}
          sx={{
            maxWidth: '100%',
            width: '100%',
            background: '#fff',
            padding: '16px 0',
          }}
        >
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setStickerData([guideId!]);
                setStickerDialogOpen(!isStickerDialogOpen);
              }}
              sx={{
                boxShadow: 'none',
                width: '100%',
              }}
            >
              <Trans i18nKey="labels.printQRCode" />
            </Button>
          </Grid>

          {isStickerDialogOpen ? (
            <StickerDialog
              onClose={() => setStickerDialogOpen(!isStickerDialogOpen)}
              data={stickerData}
              variant="guide"
            />
          ) : null}

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleRedirect(`/guide/${guideId}`)}
              sx={{
                boxShadow: 'none',
                width: '100%',
              }}
            >
              <Trans i18nKey="labels.openPage" />
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="button"
              variant="text"
              onClick={onCancel}
              sx={{
                boxShadow: 'none',
                width: '100%',
              }}
            >
              <Trans i18nKey="labels.cancel" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
