import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { HardwareRevisionType } from '../commonTypes';
import { NewHardwareRevisionForm } from './NewHardwareRevisionForm';
import { Dialog } from '../components';

interface AddHardwareDialogProps {
  onClose?: () => void;
  onSubmit: (hardwareRevisionType: HardwareRevisionType) => void;
  selectedCompany?: string;
}
export const AddHardwareRevisionDialog: FC<AddHardwareDialogProps> = (
  props
) => {
  const { onClose, onSubmit, selectedCompany } = props;

  return (
    <Dialog
      title={<Trans i18nKey="labels.addNewRevisionType" />}
      onClose={onClose}
    >
      <NewHardwareRevisionForm
        onSubmit={onSubmit}
        onCancel={onClose!}
        selectedCompany={selectedCompany}
      />
    </Dialog>
  );
};
