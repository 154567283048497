import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import cs from './translations/cs.json';
import en from './translations/en.json';
import bg from './translations/bg.json';

export const i18n = i18next
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources: {
      cs: { translation: cs },
      en: { translation: en },
      bg: { translation: bg },
    },
    fallbackLng: ['en'],

    detection: {
      caches: ['cookie'],
    },

    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
    },
  });
