import React, { FC, ReactNode } from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface DialogProps {
  title: string | ReactNode;
  children: string | ReactNode;
  onClose?: () => void;
  fullScreen?: boolean;
}

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const Dialog: FC<DialogProps> = (props) => {
  const { title, children, onClose, fullScreen } = props;

  return (
    <MuiDialog open onClose={onClose} fullWidth fullScreen={fullScreen}>
      <DialogTitle>
        <Typography variant="h6">{title}</Typography>
        {onClose && (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </MuiDialog>
  );
};
