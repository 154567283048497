import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
  FC,
  PropsWithChildren,
} from 'react';
import { UserContext } from '.';
import { PlacementType } from '../commonTypes';

interface IHardwarePlacementContext {
  globalHardwarePlacements: PlacementType[];
  setGlobalHardwarePlacements: Dispatch<SetStateAction<PlacementType[]>>;
  refreshHardwarePlacements: () => Promise<void>;
}

export const HardwarePlacementContext = createContext<
  Partial<IHardwarePlacementContext>
>({
  globalHardwarePlacements: [],
  setGlobalHardwarePlacements: () => {},
  refreshHardwarePlacements: () => Promise.resolve(),
});

export const HardwarePlacementContextProvider: FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const { userData } = useContext(UserContext);
  const [globalHardwarePlacements, setGlobalHardwarePlacements] = useState<
    Array<PlacementType>
  >([]);

  const refreshHardwarePlacements = async () => {
    if (userData) {
      const getHardwarePlacements = async () => {
        const dbHardwarePlacements = await fetch('/api/places', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'GET',
        }).then((data) => data.json());

        const activeHardwarePlacements = dbHardwarePlacements.filter(
          (hardwareRevisionType: PlacementType) =>
            hardwareRevisionType.isDeleted === false
        );
        setGlobalHardwarePlacements(activeHardwarePlacements);
      };
      getHardwarePlacements();
    }
  };

  useEffect(() => {
    refreshHardwarePlacements();
  }, [userData]); // eslint-disable-line

  return (
    <HardwarePlacementContext.Provider
      value={{
        globalHardwarePlacements,
        setGlobalHardwarePlacements,
        refreshHardwarePlacements,
      }}
    >
      {children}
    </HardwarePlacementContext.Provider>
  );
};
