import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { UserContext } from '../../contexts';
import { Tasks } from '../../commonTypes';
import { format } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { EventDialog } from '../Dialogs/EventDialog';
import { ArrowDropDown, MoreVert } from '@mui/icons-material';
import { AlertDialog } from '../Dialogs';
import { TaskDialog } from '../Dialogs/TaskDialog';
import { StyledAccordionSummary } from '../../customStyledComponents';

interface TaskDetailFormProps {
  taskId: string;
  onClose?: () => void;
  handleDelete?: (id: string) => void;
  refetchTable?: (state: boolean) => void;
  refetchEventsTable?: () => void;
}

const TaskDetailForm: FC<TaskDetailFormProps> = ({
  taskId,
  onClose,
  handleDelete,
  refetchTable,
  refetchEventsTable,
}) => {
  const { t } = useTranslation();

  const { userData } = useContext(UserContext);

  const [taskDetail, setTaskDetail] = useState<Tasks>();

  const [openAlertDialog, setOpenAlertDialog] = useState<string>();

  const [openNewEventDialog, setOpenNewEventDialog] = useState<
    boolean | string
  >(false);

  const [openTaskDialog, setOpenTaskDialog] = useState<boolean | string>(false);

  const getTask = useCallback(async () => {
    if (!taskId) return;
    const res = await fetch(`/api/tasks/detail/${taskId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'GET',
    });
    if (res.ok) {
      const data = await res.json();
      setTaskDetail({ ...data, assigneeId: data.assignee });
    }
  }, [taskId, userData]);

  useEffect(() => {
    getTask();
  }, [getTask]);

  const onStatusChange = async (event: SelectChangeEvent<Tasks['status']>) => {
    const status = event.target.value;
    if (status === taskDetail?.status) return;
    const res = await fetch(`/api/tasks/update-status/${taskId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'PUT',
      body: JSON.stringify({ status }),
    });
    if (res.ok) {
      getTask();
      refetchTable?.(true);
    }
  };

  if (!taskDetail)
    return (
      <Box display={'flex'} justifyContent={'center'}>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Box>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant={'h6'}>{taskDetail?.name}</Typography>
          <Box flex={1} />
          <Select<Tasks['status']>
            value={taskDetail.status}
            onChange={onStatusChange}
            displayEmpty
            size={'small'}
            input={<OutlinedInput />}
          >
            <MenuItem value="new">
              <Trans i18nKey="tasks.status.new" />
            </MenuItem>
            <MenuItem value="in_progress">
              <Trans i18nKey="tasks.status.in_progress" />
            </MenuItem>
            <MenuItem value="done">
              <Trans i18nKey="tasks.status.done" />
            </MenuItem>
          </Select>
        </Box>
        <Box mt={'8px'} display={'flex'}>
          <Typography fontWeight={'500'} mr={1}>
            {t('tasks.detailModal.deadline')}
          </Typography>
          {format(new Date(taskDetail.deadline), 'dd.MM.yyyy')}
        </Box>
        <Box display={'flex'}>
          <Typography fontWeight={'500'} mr={1}>
            {t('tasks.detailModal.assignee')}
          </Typography>
          {
            userData?.usersData?.find((ud) => ud._id === taskDetail.assignee)
              ?.email
          }
        </Box>
        <Box display={'flex'}>
          <Typography fontWeight={'500'} mr={1}>
            {t('tasks.detailModal.description')}
          </Typography>
          {taskDetail.description}
        </Box>
      </Box>

      <Grid container mt={'24px'}>
        <Grid item xs={6}>
          <Box display={'flex'} justifyContent={'start'}>
            <span style={{ marginRight: '8px' }}>
              <Trans
                size={'13px'}
                i18nKey={'tasks.detailModal.company'}
                style={{ marginRight: '8px' }}
              />
            </span>
            {taskDetail.taskCompanies.length > 3 ? (
              <Accordion disableGutters elevation={0}>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  {t('tasks.detailModal.companies', {
                    count: taskDetail.taskCompanies.length,
                  })}
                </StyledAccordionSummary>
                <AccordionDetails sx={{ ml: -2, mt: -1 }}>
                  {taskDetail.taskCompanies?.map((tc) => {
                    return <Typography>{tc?.companyName}</Typography>;
                  })}
                </AccordionDetails>
              </Accordion>
            ) : (
              <Box>
                {taskDetail.taskCompanies?.map((tc) => {
                  return (
                    <>
                      <Typography>{tc?.companyName}</Typography>
                    </>
                  );
                })}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display={'flex'} justifyContent={'start'}>
            <span style={{ marginRight: '8px' }}>
              <Trans
                size={'13px'}
                i18nKey={'tasks.detailModal.placement'}
                style={{ marginRight: '8px' }}
              />
            </span>
            {taskDetail.taskPlacements.length > 3 ? (
              <Accordion disableGutters elevation={0}>
                <StyledAccordionSummary expandIcon={<ArrowDropDown />}>
                  {t('tasks.detailModal.placements', {
                    count: taskDetail.taskPlacements.length,
                  })}
                </StyledAccordionSummary>
                <AccordionDetails sx={{ ml: -2, mt: -1 }}>
                  {taskDetail.taskPlacements?.map((tc) => {
                    return <Typography>{tc?.name}</Typography>;
                  })}
                </AccordionDetails>
              </Accordion>
            ) : (
              <Box>
                {taskDetail.taskPlacements?.map((tc) => {
                  return (
                    <>
                      <Typography>{tc?.name}</Typography>
                    </>
                  );
                })}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Typography variant={'h6'} my={'24px'}>
        {t('tasks.detailModal.linkedEvents')}
      </Typography>

      <Box sx={{ overflow: 'scroll', height: '148px' }}>
        {taskDetail.events.length > 0 ? (
          <>
            {taskDetail.events
              .sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              )
              .map((event) => (
                <>
                  <Box display={'flex'} px="16px" pb={'10px'} pt="8px">
                    <Box>
                      <Typography fontSize={'14px'}>{event.name}</Typography>
                      <Box display={'flex'} alignItems={'center'}>
                        <Typography fontSize={'11px'} fontWeight={'300'}>
                          {format(new Date(event.date), 'dd.MM.yyyy')}
                        </Typography>
                        <Box
                          fontSize={'11px'}
                          fontWeight={'300'}
                          mx={'8px'}
                          my={'1px'}
                        >
                          |
                        </Box>
                        <Typography
                          fontSize={'9px'}
                          color={'primary'}
                          fontWeight={'400'}
                        >
                          {
                            userData?.usersData.find(
                              (ud) => ud._id === event.resolver
                            )?.email
                          }
                        </Typography>
                      </Box>
                    </Box>
                    <Box flex={1} />
                    <IconButton
                      onClick={() => setOpenNewEventDialog(event._id)}
                    >
                      <MoreVert />
                    </IconButton>
                  </Box>
                  <Divider />
                </>
              ))}
          </>
        ) : (
          <Box textAlign={'center'}>
            {t('tables.common.emptyDataSourceMessage')}
          </Box>
        )}
      </Box>

      <Grid container gap={'8px'} mt={'24px'}>
        <Grid item md>
          <Button
            fullWidth
            onClick={() => setOpenNewEventDialog(true)}
            variant={'contained'}
          >
            {t('tasks.detailModal.newEvent')}
          </Button>
        </Grid>
        <Grid item md>
          <Button
            onClick={() => setOpenTaskDialog(taskId)}
            fullWidth
            variant={'outlined'}
          >
            {t('tasks.detailModal.edit')}
          </Button>
        </Grid>
      </Grid>
      <Grid mt={'8px'} container gap={'8px'}>
        <Grid item md>
          <Button
            onClick={() => setOpenAlertDialog(taskDetail._id)}
            fullWidth
            color={'error'}
            variant={'contained'}
          >
            {t('tasks.detailModal.delete')}
          </Button>
        </Grid>
        <Grid item md>
          <Button onClick={() => onClose?.()} fullWidth>
            {t('tasks.detailModal.back')}
          </Button>
        </Grid>
      </Grid>
      {openNewEventDialog && (
        <EventDialog
          eventId={
            typeof openNewEventDialog === 'string'
              ? openNewEventDialog
              : undefined
          }
          taskId={taskId}
          onClose={() => setOpenNewEventDialog(false)}
          onSubmit={() => {
            setOpenNewEventDialog(false);
            getTask();
            refetchEventsTable?.();
          }}
        />
      )}
      {openTaskDialog && (
        <TaskDialog
          taskId={
            typeof openTaskDialog === 'string' ? openTaskDialog : undefined
          }
          onClose={() => setOpenTaskDialog(false)}
          onSubmit={() => {
            setOpenTaskDialog(false);
            getTask();
            refetchTable?.(true);
          }}
        />
      )}
      {openAlertDialog ? (
        <AlertDialog
          onClose={() => setOpenAlertDialog(undefined)}
          onDelete={() => {
            onClose?.();
            handleDelete?.(taskDetail._id);
          }}
        />
      ) : null}
    </>
  );
};

export default TaskDetailForm;
