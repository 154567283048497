import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { AddNewUserForm } from './AddNewUserForm';
import { Company } from '../commonTypes';
import { Dialog } from '../components';

interface AddNewUserDialogProps {
  onClose?: () => void;
  onSubmit: (company: Company) => void;
}

export const AddNewUserDialog: FC<AddNewUserDialogProps> = (props) => {
  const { onClose, onSubmit } = props;

  return (
    <Dialog title={<Trans i18nKey="labels.createNewUser" />} onClose={onClose}>
      <AddNewUserForm onSubmit={onSubmit} onCancel={onClose!} />
    </Dialog>
  );
};
