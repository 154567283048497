import React, { FC, useContext } from 'react';
import { Trans } from 'react-i18next';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';

import { CompanyContext } from '../contexts';
import { Company, SelectProps } from '../commonTypes';

const renderItems = (owners: Company[] = []) => {
  return owners.map((item) => (
    <MenuItem
      key={item._id}
      value={item._id}
      data-testid={`company-select-option-${item.companyName}`}
    >
      {item.companyName}
    </MenuItem>
  ));
};

export const OwnerSelectField: FC<
  SelectProps<Company | undefined> & { value?: string }
> = (props) => {
  const { onChange, disabled, error, value } = props;
  const { globalNonUserCompany } = useContext(CompanyContext);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedId = event.target.value;
    const selectedOwner = globalNonUserCompany?.find(
      ({ _id: id }) => id === selectedId
    );
    onChange(selectedOwner);
  };

  return (
    <FormControl error={!!error} fullWidth>
      <FormControl fullWidth disabled={disabled}>
        <InputLabel id="owner">
          <Trans i18nKey="labels.company" />
        </InputLabel>
        <Select
          labelId="owner"
          id="owner-select"
          label="Společnost"
          onChange={handleChange}
          value={value}
          data-testid="company-select-field"
        >
          <MenuItem data-testid="company-default-value" value={-1}>
            <Trans i18nKey="labels.select" />
          </MenuItem>
          {renderItems(globalNonUserCompany)}
        </Select>
      </FormControl>
    </FormControl>
  );
};
