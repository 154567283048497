import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Dialog } from '../Dialog';
import { EventForm } from '../DialogForms/EventForm';

interface EventDialogProps {
  onClose: () => void;
  onSubmit: () => void;
  eventId?: string;
  taskId?: string;
}

export const EventDialog: FC<EventDialogProps> = (props) => {
  const { onClose, onSubmit, eventId, taskId } = props;

  return (
    <Dialog
      title={
        eventId ? (
          <Trans i18nKey="event.editEvent" />
        ) : (
          <Trans i18nKey="event.newEvent" />
        )
      }
      onClose={onClose}
    >
      <EventForm taskId={taskId} eventId={eventId} onSubmit={onSubmit} />
    </Dialog>
  );
};
