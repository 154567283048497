import React, { FC, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { Form } from 'react-final-form';
import { Grid, Button } from '@mui/material';
import { DatePicker } from 'mui-rff';
import { addDays, format } from 'date-fns';

import {
  UserContext,
  HardwareContext,
  HardwareRevisionTypeContext,
} from '../contexts';
import { Hardware } from '../commonTypes';
import { Dialog } from '../components';

interface RevisionDialogProps {
  values?: Hardware;
  onClose: () => void;
  onSubmit?: (res: Hardware) => void;
}

export const RevisionDialog: FC<RevisionDialogProps> = (props) => {
  const { values, onClose, onSubmit } = props;

  const { globalHardwareRevisionType } = useContext(
    HardwareRevisionTypeContext
  );
  const { setHardwareUpdate } = useContext(HardwareContext);
  const { userData } = useContext(UserContext);

  const [revisionNext, setRevisionNext] = useState(new Date());
  const [dateInDatePickerState, setDateInDatePickerState] = useState(
    new Date()
  );

  const newRevisionDate = (date: Date, days: number) => {
    const newDate = new Date(date.getMilliseconds());
    const numberOfDaysToAdd = days;
    if (numberOfDaysToAdd) {
      newDate.setTime(addDays(date, numberOfDaysToAdd).getTime());
      setRevisionNext(newDate);
      setDateInDatePickerState(date);
    }
  };

  const handleDate = (date: unknown, values: Hardware) => {
    const dateInDatePicker = date as Date;
    if (dateInDatePicker) {
      if (typeof values.revisionType === 'object') {
        newRevisionDate(dateInDatePicker, values.revisionType.days);
      } else if (typeof values.revisionType === 'string') {
        const revisionTypes = globalHardwareRevisionType;
        if (revisionTypes) {
          const revisionType = revisionTypes.find(
            (revision) =>
              revision._id === (values.revisionType as unknown as string)
          );
          if (revisionType) {
            newRevisionDate(dateInDatePicker, revisionType.days);
          }
        }
      }
    }
  };

  const handleSubmit = async (formValues: Hardware) => {
    if (revisionNext === formValues.revisionNext) {
      return;
    }
    if (!values) {
      return;
    }

    try {
      const res = await fetch(`/api/hardware/${values?._id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'PUT',
        body: JSON.stringify({
          revisionNext: revisionNext,
          revisionType: values.revisionType,
          revisionHistory: {
            completedRevision: dateInDatePickerState,
            resolution: 'passed',
            employee: userData?.user._id,
          },
          borrowedBy: values.borrowedBy,
          borrowingConfirmed: values.borrowingConfirmed,
        }),
      }).then((data) => data.json());

      if (res.error) {
        return;
      }

      onSubmit?.(res);
      setHardwareUpdate(true);
      onClose();
    } catch (exception) {
      console.log(exception);
    }
  };

  return (
    <Dialog title={<Trans i18nKey="revisionDialog.title" />} onClose={onClose}>
      <Form
        initialValues={{
          ...values,
        }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting, values, pristine }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} data-testid="next-revision-date">
                  Další revize {format(revisionNext, 'dd.MM.yyyy')}
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    inputFormat="dd.MM.yyyy"
                    name="revisionDate"
                    label={
                      <Trans i18nKey="revisionDialog.revisionDatePicker" />
                    }
                    onAccept={(date) => {
                      handleDate(date, values);
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  position={{ xs: 'sticky', lg: 'relative' }}
                  bottom={{ xs: '-16px' }}
                  bgcolor={{ xs: 'white' }}
                  paddingBottom="16px"
                  sx={{
                    marginTop: '8px',
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    spacing={{ xs: 0, lg: 2 }}
                    sx={{
                      maxWidth: '100%',
                      width: '100%',

                      background: '#fff',
                    }}
                  >
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={pristine || submitting}
                        sx={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                        data-testid="save-new-revision-date-btn"
                      >
                        <Trans i18nKey="revisionDialog.save" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        type="button"
                        variant="text"
                        onClick={onClose}
                        sx={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="revisionDialog.cancel" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </Dialog>
  );
};
