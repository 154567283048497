import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Company } from '../commonTypes';
import { UserContext } from './UserContext';

export const OwnerContext = createContext<
  Partial<{
    owners: Company[];
    activeOwners: Company[];
    isLoading: boolean;
    refreshOwners: () => void;
  }>
>({ owners: [], activeOwners: [], refreshOwners: () => {}, isLoading: false });

export const OwnersContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { userData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [owners, setOwners] = useState<Company[]>([]);
  const [activeOwners, setActiveOwners] = useState<Company[]>([]);

  const refreshOwners = async () => {
    setIsLoading(true);
    const dbOwners: Company[] = await fetch('/api/companies/userCompany', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'GET',
    }).then((data) => data.json());

    setOwners(dbOwners);
    const activeDbOwners = dbOwners.filter((owner) => owner.isDeleted !== true);
    setActiveOwners(activeDbOwners);
    setIsLoading(false);
  };

  useEffect(() => {
    refreshOwners();
  }, []); // eslint-disable-line

  return (
    <OwnerContext.Provider
      value={{ owners, activeOwners, isLoading, refreshOwners }}
    >
      {children}
    </OwnerContext.Provider>
  );
};
