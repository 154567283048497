import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material';

const LogoImage = styled('img')(({ theme }) => ({
  height: '100%',
}));

export const DynamicIcon: FC<{ name: string }> = ({ name }) => {
  const [imgSrc, setImgSrc] = useState(require('../img/evhardBlue.png'));

  useEffect(() => {
    try {
      setImgSrc(require(`../img/${name}.png`));
    } catch {
      setImgSrc(require(`../img/evhardBlue.png`));
    }
  }, [name]);

  return <LogoImage src={imgSrc} />;
};
