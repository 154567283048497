import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Grid } from '@mui/material';

import { Trans } from '../i18n';
import { PlacementType } from '../commonTypes';
import { Dialog } from '../components';
import { StyledGrid, StyledTextField } from '../customStyledComponents';

interface HardwarePlacementEditDialogProps {
  onClose: () => void;
  placementData: PlacementType;
  onUpdate: (place: PlacementType) => void;
}

export const HwPlacementEditDialog: FC<HardwarePlacementEditDialogProps> = (
  props
) => {
  const { onClose, placementData, onUpdate } = props;

  const handleSubmit = (data: PlacementType) => {
    placementData.name = data.name;
    if (!!placementData) {
      onUpdate(placementData);
    }
    onClose();
  };

  const initialValues: PlacementType = {
    _id: placementData._id,
    name: placementData.name,
    companyId: placementData.companyId,
    isDeleted: placementData.isDeleted,
    createdAt: placementData.createdAt,
  };

  return (
    <Dialog
      title={placementData.name ?? <Trans i18nKey="detail.detailTitle" />}
      onClose={onClose}
    >
      <>
        <Form
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, pristine }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid>
                  <Grid xs={12} sx={{ marginBottom: '20px' }}>
                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="placementEditDialog.description" />
                        </Grid>
                        <Grid item xs={6}>
                          <StyledTextField
                            inputProps={{ style: { textAlign: 'right' } }}
                            type="text"
                            name="name"
                            margin="none"
                            fullWidth
                            required
                            variant="standard"
                            component="input"
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="center"
                    xs={12}
                    sx={{
                      width: '100%',
                      position: 'sticky',
                      left: '0px',
                      bottom: '-18px',
                      padding: '16px 0px',
                      background: 'white',
                    }}
                  >
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={pristine || submitting}
                        style={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.save" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        type="button"
                        variant="text"
                        onClick={onClose}
                        style={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.cancel" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </>
    </Dialog>
  );
};
