import React, { ChangeEvent, MouseEvent, useState, FC } from 'react';
import { Trans } from 'react-i18next';

import {
  Grid,
  Checkbox,
  FormControlLabel,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Company, NewUserCompany } from '../commonTypes';

interface IAddNewUserCheckboxProps {
  company: Company;
  key: number;
  onChange: (company?: NewUserCompany) => void;
  name: string;
}

export const AddNewUserCheckbox: FC<IAddNewUserCheckboxProps> = (props) => {
  const { company, key, onChange } = props;

  const [alignment, setAlignment] = useState('User');
  const [checked, setChecked] = useState(false);

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      onChange({
        userRole: 'User',
        companyId: company._id,
      });
    } else {
      onChange(undefined);
    }
  };

  const handleChange = (
    _event: MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    onChange({
      userRole: newAlignment ?? alignment,
      companyId: company._id,
    });

    if (newAlignment === null) {
      setAlignment(alignment);
    } else {
      setAlignment(newAlignment);
    }
  };

  return (
    <Grid
      container
      xs={12}
      justifyContent="space-between"
      spacing={2}
      key={key}
    >
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChangeCheckbox}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={company.companyName}
        />
      </Grid>
      <Grid item>
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          disabled={!checked}
        >
          <ToggleButton value="Admin">
            <Trans i18nKey="labels.admin" />
          </ToggleButton>
          <ToggleButton
            data-testid={`editor-toggle-${company.companyName}`}
            value="Editor"
          >
            <Trans i18nKey="labels.editor" />
          </ToggleButton>
          <ToggleButton value="User">
            <Trans i18nKey="labels.user" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};
