import React, { FC } from 'react';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { Trans } from '../i18n';
import { SelectProps } from './../commonTypes';

export const CountUnitSelect: FC<SelectProps<string>> = (props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    props.onChange(selectedValue);
  };

  return (
    <FormControl fullWidth>
      <FormControl>
        <Select
          defaultValue={props.defaultValue ? props.defaultValue : 'pcs'}
          onChange={handleChange}
          renderValue={(selected?: string) => {
            if (!selected) {
              return (
                <FormHelperText sx={{ color: '#0000004D' }}>
                  <Trans i18nKey="countUnitSelect.choose" />
                </FormHelperText>
              );
            }
            switch (selected) {
              case 'pcs':
                return <Trans i18nKey="countUnitSelect.pcs" />;
              case 'kg':
                return <Trans i18nKey="countUnitSelect.kg" />;
              case 'meter':
                return <Trans i18nKey="countUnitSelect.meter" />;
            }
          }}
        >
          <MenuItem disabled value={undefined}>
            <FormHelperText>
              <Trans i18nKey="countUnitSelect.choose" />
            </FormHelperText>
          </MenuItem>
          <MenuItem value={'pcs'}>
            <Trans i18nKey="countUnitSelect.pcs" />
          </MenuItem>
          <MenuItem value={'kg'}>
            <Trans i18nKey="countUnitSelect.kg" />
          </MenuItem>
          <MenuItem value={'meter'}>
            <Trans i18nKey="countUnitSelect.meter" />
          </MenuItem>
        </Select>
      </FormControl>
    </FormControl>
  );
};
