import React, { FC } from 'react';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';
import { Typography, Paper, Grid, Button } from '@mui/material';

import { Trans } from '../i18n';
import { FormFields } from '../commonTypes';

interface FormBaseProps {
  fields: FormFields[];
  initialValues?: any;
  onSubmit: (values: object, form: FormApi) => void;
  titleForm?: string;
  validate?: any;
  type?: 'login' | 'register';
}

export const FormBase: FC<FormBaseProps> = (props) => {
  const { fields, initialValues, validate, onSubmit, titleForm, type } = props;

  return (
    <div style={{ margin: 'auto' }}>
      <Typography variant="h5" align="center" component="h2" gutterBottom>
        {titleForm}
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            {console.log('submitting', submitting)}
            <Paper style={{ width: '100%', boxShadow: 'none' }}>
              <Grid container alignItems="flex-start" spacing={2}>
                {fields.map((item, idx) => (
                  <Grid item xs={item.size} key={idx}>
                    {item.field}
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                    data-testid="form-base-submit-btn"
                  >
                    {type === 'login' ? (
                      <Trans i18nKey="labels.signin" />
                    ) : (
                      <Trans i18nKey="labels.register" />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        )}
      />
    </div>
  );
};
