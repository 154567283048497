import React, { FC, PropsWithChildren, useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { UserContext } from '../contexts';

export const PrivateRoute: FC<PropsWithChildren<RouteProps>> = (props) => {
  const { children, ...rest } = props;
  const { userData, isLoading } = useContext(UserContext);

  if (isLoading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userData?.user.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
