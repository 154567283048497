import React, { FC, useContext } from 'react';
import { Trans } from 'react-i18next';

import { Dialog } from '../Dialog';
import { PaymentForm } from '../DialogForms';
import { Company } from '../../commonTypes';
import { PaymentContext } from '../../contexts';

interface PaymentDialogProps {
  companyData: Company;
  onClose: () => void;
  onSubmit: (comp: Company) => void;
}

export const PaymentDialog: FC<PaymentDialogProps> = (props) => {
  const { companyData, onClose, onSubmit } = props;
  const { payment } = useContext(PaymentContext);
  const { resetCurrency } = useContext(PaymentContext);

  const handleOnClose = () => {
    resetCurrency();
    onClose();
  };

  return (
    <Dialog
      title={<Trans i18nKey="paymentDialog.title" />}
      onClose={handleOnClose}
    >
      <PaymentForm
        companyData={companyData}
        paymentData={payment}
        onCancel={handleOnClose}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
};
