import React, { FC, useContext, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { Trans } from 'react-i18next';

import { UserContext } from '../../contexts';
import { SharedFileUploadProps } from './interfaces';

export const FilePickerForm: FC<SharedFileUploadProps> = (props) => {
  const { onClose, documentId, onSelect } = props;

  const { userData } = useContext(UserContext);

  const [title, setTitle] = useState('');

  return (
    <Grid container alignItems="flex-start">
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <TextField
            sx={{ width: '100%' }}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            label={<Trans i18nKey="fileDialog.linkDescription" />}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        position={{ xs: 'sticky', lg: 'relative' }}
        bottom={{ xs: '-16px' }}
        bgcolor={{ xs: 'white' }}
        sx={{
          marginTop: '16px',
        }}
      >
        <Grid
          container
          justifyContent="center"
          sx={{
            maxWidth: 'calc(100% + 32px)',
            width: 'calc(100% + 32px)',
            marginInline: '-16px',
            paddingInline: '16px',
            background: '#fff',
          }}
        >
          <Grid item xs={12}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              fullWidth
              onClick={async () => {
                const url = await fetch(`/api/documents/get-url/${documentId}`, {
                  headers: {
                    Authorization: `Bearer ${userData!.user.token}`,
                  },
                  method: 'GET',
                }).then((data) => data.json());

                onSelect?.(url.url, title);
                onClose();
              }}
            >
              <Trans i18nKey="labels.save" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button type="button" fullWidth onClick={onClose}>
              <Trans i18nKey="fileForm.close" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
