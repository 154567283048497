import React, { useState, useContext, FC } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
} from '@mui/material';

import { StickerDialog } from '../Dialogs';
import { CompanyContext, HardwareContext, TypesContext } from '../../contexts';
import { Hardware, HardwareType } from '../../commonTypes';

interface StickerPrintFormProps {
  onCancel: () => void;
}

export const StickerPrintForm: FC<StickerPrintFormProps> = ({ onCancel }) => {
  const { globalUserCompany = [] } = useContext(CompanyContext);
  const { globalHardware } = useContext(HardwareContext);
  const { types } = useContext(TypesContext);

  const [companies, setCompanies] = useState<string[]>([]);
  const [hardware, setHardware] = useState<{ data: Hardware[] }>({ data: [] });
  const [hwType, setHwType] = useState<string[]>([]);
  const [hardwareTypes, setHardwawreTypes] = useState<{ data: HardwareType[] }>(
    { data: [] }
  );
  const [hardwareToShow, setHardwareToShow] = useState<{ data: Hardware[] }>({
    data: [],
  });
  const [openSticker, setOpenSticker] = useState(false);
  const [hwIds, setHwIds] = useState<string[]>([]);

  const theme = useTheme();

  const handleSubmit = () => {
    setOpenSticker(!openSticker);
  };

  const handleCompanyChange = (event: SelectChangeEvent<typeof companies>) => {
    const {
      target: { value },
    } = event;
    setCompanies(typeof value === 'string' ? value.split(',') : value);
    setHardwawreTypes({ data: [] });
    setHwType([]);
    handleFillterCompany(typeof value === 'string' ? [value] : value);
  };

  const handleHwTypeChange = (event: SelectChangeEvent<typeof hwType>) => {
    const {
      target: { value },
    } = event;
    setHwType(typeof value === 'string' ? value.split(',') : value);
    handleFillterTypes(typeof value === 'string' ? [value] : value);
  };

  const handleFillterCompany = (companies: string[]) => {
    const filteredCompany = globalUserCompany.filter((guc) =>
      companies.includes(guc._id)
    );
    const filteredCompanyIds = filteredCompany.map((fc) => fc._id);

    if (filteredCompany.length > 0) {
      const filteredHardwareByFilteredCompanyIds = globalHardware.filter(
        (h: Hardware) => filteredCompanyIds.includes(h.companyId ?? '')
      );

      const filteredHardware = filteredHardwareByFilteredCompanyIds.filter(
        (h) => !h.type.smallEquipments || h.type.smallEquipments === 'normal'
      );

      setHardware({ data: filteredHardware });
      setHardwareToShow({ data: filteredHardware });

      if (types) {
        const filteredTypes = types?.filter((type) =>
          filteredCompanyIds.includes(type.companyId)
        );
        setHardwawreTypes({ data: filteredTypes });
      }
    } else {
      setHardware({ data: [] });
      setHardwareToShow({ data: [] });
    }
  };

  const handleFillterTypes = (types: string[]) => {
    const filteredHardwareByType = hardware.data.filter((h) =>
      types.includes(h.type.name)
    );
    if (filteredHardwareByType.length > 0) {
      setHardwareToShow({ data: filteredHardwareByType });
    } else {
      setHardwareToShow({ data: [] });
    }
  };

  function getStyles(
    companyName: string,
    companies: readonly string[],
    theme: Theme
  ) {
    return {
      fontWeight:
        companies.indexOf(companyName) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleId = (id: string) => {
    const index = hwIds.indexOf(id);
    const copyHw = [...hwIds];
    index === -1 ? copyHw.push(id) : copyHw.splice(index, 1);
    setHwIds(copyHw);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Trans i18nKey="stickerPrintForm.description" />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Select
          sx={{ height: '36px', width: '200px' }}
          multiple
          displayEmpty
          value={companies}
          onChange={handleCompanyChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <em>
                  <Trans i18nKey="tables.hwList.companies" />
                </em>
              );
            }

            return selected
              .map(
                (s) =>
                  globalUserCompany.find((guc) => guc._id === s)?.companyName
              )
              .join(', ');
          }}
        >
          <MenuItem disabled value="">
            <em>
              <Trans i18nKey="tables.hwList.companies" />
            </em>
          </MenuItem>
          {globalUserCompany.map((company) => (
            <MenuItem
              key={company._id}
              value={company._id}
              style={getStyles(company.companyName, companies, theme)}
            >
              {company.companyName}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Select
          disabled={hardwareTypes.data.length === 0}
          sx={{ height: '36px', width: '200px' }}
          multiple
          displayEmpty
          value={hwType}
          onChange={handleHwTypeChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <em>
                  <Trans i18nKey="stickerPrintForm.hwType" />
                </em>
              );
            }

            return selected.join(', ');
          }}
        >
          <MenuItem disabled value="">
            <em>
              <Trans i18nKey="stickerPrintForm.hwType" />
            </em>
          </MenuItem>
          {hardwareTypes.data.map((type) => (
            <MenuItem
              key={type.name}
              value={type.name}
              style={getStyles(type.name, hwType, theme)}
            >
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {hardwareToShow.data.length > 0 ? (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setHwIds(hardwareToShow.data.map((hw) => hw._id!));
                  } else {
                    setHwIds([]);
                  }
                }}
              />
            }
            label={t('stickerPrintForm.selectAll') as string}
          />
        </Grid>
      ) : null}
      <Grid
        item
        sx={{ height: { xs: '150px', md: '400px' }, overflow: 'scroll' }}
        sm={12}
      >
        {hardwareToShow.data.map((hw) => (
          <Grid item xs={12}>
            <FormControlLabel
              checked={hwIds.includes(hw._id!)}
              control={<Checkbox onChange={(e) => handleId(hw._id!)} />}
              label={hw.description}
            />
          </Grid>
        ))}
      </Grid>

      <Grid
        item
        xs={12}
        style={{ marginTop: 16 }}
        sx={{
          width: '100%',
          position: 'sticky',
          left: '0px',
          bottom: '-18px',
          padding: '20px 0px 20px 0px',
          background: 'white',
        }}
      >
        <Grid container justifyContent="center" xs={12}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="contained"
              fullWidth
              sx={{
                marginBottom: '8px',
              }}
              onClick={() => handleSubmit()}
              disabled={hwIds.length === 0}
            >
              <Trans i18nKey="stickerPrintForm.confirm" />
            </Button>

            {openSticker ? (
              <StickerDialog
                data={hwIds}
                onClose={() => setOpenSticker(!openSticker)}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Button type="button" fullWidth onClick={onCancel}>
              <Trans i18nKey="stickerPrintForm.back" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
