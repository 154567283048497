import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { FilePickerForm } from './FilePickerForm';
import { SharedFileUploadProps } from './interfaces';
import { Dialog } from '../Dialog';

export const FilePickerDialog: FC<SharedFileUploadProps> = (props) => {
  const { onClose, documentId, onSelect } = props;

  return (
    <Dialog
      title={<Trans i18nKey="fileDialog.linkDescription" />}
      onClose={onClose}
    >
      <FilePickerForm
        onClose={onClose}
        documentId={documentId}
        onSelect={onSelect}
      />
    </Dialog>
  );
};
