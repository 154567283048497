import React, { FC, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { IssueResolveSelect } from '../Fields';
import { useSnackbar } from 'notistack';
import { IssueContext, UserContext } from '../../contexts';

interface IssueFormProps {
  onClose: () => void;
  issueId: string;
}

export const IssueForm: FC<IssueFormProps> = (props) => {
  const { onClose, issueId } = props;

  const { userData } = useContext(UserContext);
  const { getIssue } = useContext(IssueContext);

  const [resolve, setResolve] = useState('inprogress');

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    const data = {
      status: resolve,
      resolvedBy: userData?.user._id,
    };
    try {
      const res = await fetch(`/api/issue/resolve/${issueId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'PUT',
        body: JSON.stringify(data),
      }).then((data) => data.json());

      if (res.error) {
        return;
      }
      enqueueSnackbar(<Trans i18nKey="notifications.saved" />, {
        variant: 'success',
      });
      getIssue();
      onClose();
    } catch (exception) {
      console.log(exception);
      enqueueSnackbar(
        <Trans i18nKey="notifications.unexpectedErrorWhenDeleting" />,
        { variant: 'error' }
      );
    }
  };

  return (
    <Grid
      container
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Grid item xs={12}>
        <IssueResolveSelect
          onChange={(resolveValue?: string) => {
            setResolve(resolveValue ?? resolve);
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        style={{ marginTop: 16 }}
        sx={{
          width: '100%',
          position: 'sticky',
          left: '0px',
          bottom: '-18px',
          padding: '16px 0px',
          background: 'white',
          display: 'flex',
          alignItems: 'flex-end',
          zIndex: '10',
        }}
      >
        <Grid container justifyContent="center" xs={12}>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                marginBottom: '8px',
              }}
              onClick={() => handleSubmit()}
            >
              <Trans i18nKey="labels.save" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button type="button" fullWidth onClick={onClose}>
              <Trans i18nKey="labels.close" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
