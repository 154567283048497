import React, { FC, useCallback, useContext, useRef } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';

import { UserContext } from '../contexts';
import { useToggle } from '../hooks';
import { LANGUAGES } from '../utils';

export const LanguageMenu: FC = () => {
  const { changeLanguage } = useContext(UserContext);
  const [isOpen, toggle] = useToggle(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleLanguageChange = useCallback(
    (id: string) => {
      changeLanguage?.(id);
      toggle();
    },
    [changeLanguage, toggle]
  );

  return (
    <>
      <IconButton ref={buttonRef} color="inherit" onClick={toggle}>
        <LanguageIcon />
      </IconButton>
      <Menu onClose={toggle} open={isOpen} anchorEl={buttonRef.current}>
        {LANGUAGES.map((lang) => (
          <MenuItem onClick={() => handleLanguageChange(lang.id)} key={lang.id}>
            {lang.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
