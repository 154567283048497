import React, { FC, ReactText, useContext, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';

import { UsersPageEditDialog } from './UsersPageEditDialog';
import { CompanyContext, UserContext } from '../contexts';
import { Trans } from '../i18n';
import { Company, CompanyUser } from '../commonTypes';
import { Dialog, AlertDialog } from '../components';
import { StyledGrid } from '../customStyledComponents';

interface UsersPageDetailDialogProps {
  onClose: () => void;
  detailData?: CompanyUser;
  onDelete: (user: CompanyUser) => void;
  onUpdate: (cu: CompanyUser) => void;
}

export const UsersPageDetailDialog: FC<UsersPageDetailDialogProps> = (
  props
) => {
  const { onClose, detailData, onDelete, onUpdate } = props;

  const { globalUserCompany = [] } = useContext(CompanyContext);
  const { userData } = useContext(UserContext);

  const [openUsersPageEditDialog, setOpenUsersPageEditDialog] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState<ReactText>();

  const company = globalUserCompany?.find(
    (com: Company) => com._id === detailData?.companyId
  );

  const closeUsersPageEditDialog = () => {
    setOpenUsersPageEditDialog(!openUsersPageEditDialog);
  };

  const closeAlertDialog = (index?: ReactText) => {
    setShowRemoveDialog(index);
  };

  const isEditable = useMemo(() => {
    const foundCompany = globalUserCompany.find(
      (guc) => guc._id === detailData?.companyId
    );
    const foundUser = foundCompany?.users.find(
      (u) => u.uid === userData?.user._id
    );
    if (foundUser?.userRole === 'User') {
      return true;
    } else {
      return false;
    }
  }, [detailData, globalUserCompany, userData]);

  const disableEdit = useMemo(() => {
    if (userData?.userInfo.email === detailData?.userData.email) {
      return true;
    } else {
      return false;
    }
  }, [userData, detailData]);

  return (
    <Dialog
      title={detailData?.userData?.email ? detailData.userData.email : '--'}
      onClose={onClose}
    >
      <>
        <Grid container>
          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="usersPageEditDialog.email" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {detailData?.userData?.email
                    ? detailData.userData.email
                    : '--'}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="usersPageEditDialog.company" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {company?.companyName ? company?.companyName : '--'}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid xs={12}>
            <StyledGrid>
              <Grid container>
                <Grid item xs={6}>
                  <Trans i18nKey="usersPageEditDialog.authorization" />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  {detailData?.userData?.userRole
                    ? detailData.userData.userRole
                    : '--'}
                </Grid>
              </Grid>
            </StyledGrid>
          </Grid>

          <Grid
            container
            xs={12}
            sx={{
              width: '100%',
              position: 'sticky',
              left: '0px',
              bottom: '-22px',
              padding: '16px 0px',
              background: 'white',
            }}
          >
            <Grid container spacing={1} marginBottom={1} marginTop={1}>
              <Grid item xs={6}>
                <Button
                  type="button"
                  color="error"
                  variant="contained"
                  style={{
                    boxShadow: 'none',
                    width: '100%',
                  }}
                  onClick={() => setShowRemoveDialog(detailData?.companyId)}
                  disabled={isEditable || disableEdit}
                >
                  <Trans i18nKey="detail.delete" />
                </Button>
              </Grid>

              {showRemoveDialog ? (
                <AlertDialog
                  onClose={() => closeAlertDialog()}
                  onDelete={() => {
                    if (detailData !== undefined) {
                      onDelete(detailData);
                    }
                  }}
                />
              ) : null}

              <Grid item xs={6}>
                <Button
                  type="button"
                  color="primary"
                  variant="outlined"
                  style={{
                    boxShadow: 'none',
                    width: '100%',
                  }}
                  onClick={() =>
                    setOpenUsersPageEditDialog(!openUsersPageEditDialog)
                  }
                  disabled={isEditable || disableEdit}
                >
                  <Trans i18nKey="detail.edit" />
                </Button>
              </Grid>

              {openUsersPageEditDialog ? (
                <UsersPageEditDialog
                  onClose={() => closeUsersPageEditDialog()}
                  companyUser={detailData!}
                  onUpdate={(cu) => {
                    onUpdate(cu);
                  }}
                />
              ) : null}
            </Grid>

            <Button
              type="button"
              color="primary"
              variant="text"
              style={{
                boxShadow: 'none',
                width: '100%',
              }}
              onClick={onClose}
            >
              <Trans i18nKey="detail.close" />
            </Button>
          </Grid>
        </Grid>
      </>
    </Dialog>
  );
};
