import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { Dialog } from '../Dialog';
import { IssueForm } from '../DialogForms';

interface IssueDialogProps {
  onClose: () => void;
  issueId: string;
}

export const IssueDialog: FC<IssueDialogProps> = (props) => {
  const { onClose, issueId } = props;

  return (
    <Dialog title={<Trans i18nKey="issueDialog.title" />} onClose={onClose}>
      <IssueForm issueId={issueId} onClose={onClose} />
    </Dialog>
  );
};
