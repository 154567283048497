import React, { FC, useContext } from 'react';
import { Trans } from 'react-i18next';
import { Grid, Button, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import { TextField } from 'mui-rff';
import { FormApi } from 'final-form';
import { Form, Field } from 'react-final-form';

import { UserContext, OwnerContext } from '../contexts';
import { Validations } from '../utils';
import { Employee, Company, FormFields } from '../commonTypes';
import { OwnerSelectField } from '../hardware';

interface UserFormProps {
  onCancel: () => void;
  onSubmit: (newUser: Employee) => void;
  selectedCompany?: string;
}

interface FormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  companyId?: string;
}

const formFields: FormFields[] = [
  {
    size: 6,
    field: (
      <TextField
        label={<Trans i18nKey="userForm.firstname" />}
        name="firstName"
        margin="none"
        data-testid="equipment-name-field"
      />
    ),
  },
  {
    size: 6,
    field: (
      <TextField
        label={<Trans i18nKey="userForm.lastname" />}
        name="lastName"
        margin="none"
        data-testid="equipment-surname-field"
      />
    ),
  },
  {
    size: 12,
    field: (
      <TextField
        type="email"
        label={<Trans i18nKey="userForm.email" />}
        name="email"
        margin="none"
        required
        data-testid="equipment-email-field"
      />
    ),
  },
];

const validate = (values: FormData) => {
  const errors = Validations.new(values)
    .required('firstName')
    .required('lastName')
    .required('email')
    .emailShape('email')
    .getErrors();

  return errors;
};

export const UserForm: FC<UserFormProps> = (props) => {
  const { onSubmit, onCancel, selectedCompany } = props;
  const { userData } = useContext(UserContext);
  const { activeOwners = [] } = useContext(OwnerContext);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: FormData, form: FormApi) => {
    try {
      const res = await fetch('/api/employees/employee', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'POST',
        body: JSON.stringify(values),
      }).then((data) => data.json());

      if (res.error) {
        return enqueueSnackbar(res.error, { variant: 'error' });
      }

      onSubmit(res);

      setTimeout(form.reset, 0);
    } catch (exception) {
      console.log(exception);
    }
  };

  const initialValues: FormData = {
    firstName: undefined,
    lastName: undefined,
    email: '@',
    companyId:
      selectedCompany ??
      (activeOwners?.length === 1 ? activeOwners[0]?._id : undefined),
  };

  return (
    <Form
      validate={validate}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <Paper elevation={0}>
          <form
            onSubmit={handleSubmit}
            noValidate
            data-testid="equipment-user-form"
          >
            <Grid item xs={12} marginBottom="16px">
              <Field name="companyId">
                {(props) => (
                  <OwnerSelectField
                    value={props.input.value}
                    onChange={(company?: Company) => {
                      props.input.onChange(company?._id);
                    }}
                    error={
                      props.meta.submitFailed ? props.meta.error : undefined
                    }
                    disabled={Boolean(selectedCompany)}
                  />
                )}
              </Field>
            </Grid>
            <Grid container alignItems="flex-start" spacing={2}>
              {formFields.map((item, idx) => (
                <Grid item xs={item.size} key={idx}>
                  {item.field}
                </Grid>
              ))}

              <Grid
                item
                xs={12}
                position={{ xs: 'sticky', lg: 'relative' }}
                bottom={{ xs: '-16px', lg: '0px' }}
                bgcolor={{ xs: 'white' }}
                sx={{
                  marginTop: '16px',
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  spacing={{ xs: 0, lg: 2 }}
                  sx={{
                    maxWidth: 'calc(100% + 32px)',
                    width: 'calc(100% + 32px)',
                    marginInline: '-16px',
                    paddingInline: '16px',

                    background: '#fff',
                  }}
                >
                  <Grid item xs={12} sm={6} lg={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      data-testid="submit-new-equipment-user-btn"
                      sx={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                    >
                      <Trans i18nKey="labels.send" />
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Button
                      type="button"
                      variant="text"
                      onClick={onCancel}
                      data-testid="cancel-new-equipment-user-btn"
                      sx={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                    >
                      <Trans i18nKey="labels.cancel" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    />
  );
};
