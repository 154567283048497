import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
  FC,
  PropsWithChildren,
} from 'react';
import { UserContext } from '.';
import { HardwareRevisionType } from '../commonTypes';

interface IHardwareRevisionTypeContext {
  globalHardwareRevisionType: HardwareRevisionType[];
  setGlobalHardwareRevisionType: Dispatch<
    SetStateAction<HardwareRevisionType[]>
  >;
  refreshHardwareRevisionType: () => Promise<void>;
}

export const HardwareRevisionTypeContext = createContext<
  Partial<IHardwareRevisionTypeContext>
>({
  globalHardwareRevisionType: [],
  setGlobalHardwareRevisionType: () => {},
  refreshHardwareRevisionType: () => Promise.resolve(),
});

export const HardwareRevisionTypeContextProvider: FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const { userData } = useContext(UserContext);
  const [globalHardwareRevisionType, setGlobalHardwareRevisionType] = useState<
    Array<HardwareRevisionType>
  >([]);

  const refreshHardwareRevisionType = async () => {
    if (userData) {
      const getHardwareRevisionType = async () => {
        const dbHardwareRevisionType = await fetch(
          '/api/hardwareRevisionTypes?includeuserRole',
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userData!.user.token}`,
            },
            method: 'GET',
          }
        ).then((data) => data.json());

        const activeHardwareRevisionType = dbHardwareRevisionType.filter(
          (hardwareRevisionType: HardwareRevisionType) =>
            hardwareRevisionType.isDeleted === false
        );
        setGlobalHardwareRevisionType(activeHardwareRevisionType);
      };
      getHardwareRevisionType();
    }
  };

  useEffect(() => {
    refreshHardwareRevisionType();
  }, [userData]); // eslint-disable-line

  return (
    <HardwareRevisionTypeContext.Provider
      value={{
        globalHardwareRevisionType,
        setGlobalHardwareRevisionType,
        refreshHardwareRevisionType,
      }}
    >
      {children}
    </HardwareRevisionTypeContext.Provider>
  );
};
