import MaterialTable, { Column } from '@material-table/core';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Events } from 'src/commonTypes';
import { UserContext } from 'src/contexts';
import {
  CustomTablePagination,
  StyledTableColumnTitle,
  StyledTableTitle,
} from 'src/customStyledComponents';
import { useMaterialTableLocalization } from 'src/hooks';
import EditIcon from '@mui/icons-material/Edit';
import { RemoveRedEye } from '@mui/icons-material';
import { format } from 'date-fns';
import { EventDetailDialog } from 'src/components/Dialogs/EventDetailDialog';
import { useSnackbar } from 'notistack';
import { EventDialog } from '../../components/Dialogs/EventDialog';
import { Box, Button, Paper } from '@mui/material';

interface TableState {
  columns: Array<Column<Events>>;
  data: Events[];
}

export interface EventsCardFunctions {
  refetch: () => void;
}

interface EventsCardProps {
  filterByCompany?: string[];
}

const EventsCard = forwardRef<EventsCardFunctions, EventsCardProps>(
  (props, ref) => {
    const localization = useMaterialTableLocalization();
    const { userData } = useContext(UserContext);
    const [tableData, setTableData] = useState<TableState>({
      columns: [],
      data: [],
    });
    const { id } = useParams<{ id: string }>();
    const { enqueueSnackbar } = useSnackbar();
    const filterByCompany = props.filterByCompany;

    const [openEventForm, setOpenEventForm] = useState<boolean | string>(false);
    const [openEventDetail, setOpenEventDetail] = useState<string>();

    const hasUserRole = !!userData?.user?.appRole;

    const getEvents = useCallback(async () => {
      const res = await fetch(
        `/api/events/${id}?filterByCompany=${filterByCompany}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'GET',
        }
      ).then((data) => data.json());
      setTableData({ ...tableData, data: res });
      return res;
    }, [id, userData, tableData, filterByCompany]);

    const handleDelete = async (id: string) => {
      await fetch(`/api/events/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'DELETE',
      });
      enqueueSnackbar(<Trans i18nKey="labels.deleted" />, {
        variant: 'success',
      });

      getEvents();
    };

    useImperativeHandle(ref, () => ({
      refetch: () => getEvents(),
    }));

    useEffect(() => {
      getEvents();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterByCompany]);

    const getUsersEmail = (userId?: string) => {
      const userEmail =
        userData?.usersData?.find((a) => a?._id === userId)?.email ?? '';
      return userEmail;
    };

    const dataTable: TableState = {
      columns: [
        {
          title: (
            <StyledTableColumnTitle>
              <Trans i18nKey="tables.eventsCard.name" />
            </StyledTableColumnTitle>
          ),
          field: 'name',
          render: (row) => {
            return <div>{row?.name || '--'}</div>;
          },
        },
        {
          title: (
            <StyledTableColumnTitle>
              <Trans i18nKey="tables.eventsCard.date" />
            </StyledTableColumnTitle>
          ),
          field: 'date',
          render: (row) => {
            const date = format(new Date(row?.date), 'dd.MM.yyyy');

            return <div>{date || '--'}</div>;
          },
        },
        {
          title: (
            <StyledTableColumnTitle>
              <Trans i18nKey="tables.eventsCard.resolver" />
            </StyledTableColumnTitle>
          ),
          field: 'resolver',
          render: (row) => {
            const userEmail = getUsersEmail(row.resolver);
            return <div>{userEmail || '--'}</div>;
          },
        },
        {
          title: (
            <StyledTableColumnTitle>
              <Trans i18nKey="tables.eventsCard.placementIds" />
            </StyledTableColumnTitle>
          ),
          field: 'placementIds',
          render: (row) => {
            return <div>{row?.placementNames || '--'}</div>;
          },
        },
      ],
      data: [],
    };

    return (
      <Paper style={{ marginTop: '16px' }}>
        <Box display={'flex'} p={'16px'}>
          <Trans i18nKey={'events.title'} />
          {hasUserRole ? (
            <>
              <Box flex={1} />
              <Button onClick={() => setOpenEventForm(true)}>
                {<Trans i18nKey="events.newEvent" />}
              </Button>
            </>
          ) : null}
        </Box>

        <MaterialTable
          title={
            <StyledTableTitle>
              <Trans i18nKey="tables.eventsCard.description" />
            </StyledTableTitle>
          }
          localization={localization}
          columns={dataTable.columns}
          data={tableData.data}
          style={{
            fontSize: '13px',
          }}
          components={{
            Pagination: CustomTablePagination,
          }}
          actions={
            hasUserRole
              ? [
                  (a) => ({
                    icon: EditIcon,
                    onClick: () => setOpenEventForm(a._id),
                  }),
                  (a) => ({
                    icon: RemoveRedEye,
                    onClick: () => setOpenEventDetail(a._id),
                  }),
                ]
              : []
          }
          options={{
            actionsColumnIndex: -1,
            showTitle: false,
          }}
        />
        {openEventDetail && (
          <EventDetailDialog
            onClose={() => setOpenEventDetail(undefined)}
            eventId={openEventDetail}
            handleDelete={handleDelete}
          />
        )}
        {openEventForm && (
          <EventDialog
            eventId={
              typeof openEventForm === 'string' ? openEventForm : undefined
            }
            onSubmit={() => {
              getEvents();
              setOpenEventForm(false);
            }}
            onClose={() => {
              setOpenEventForm(false);
            }}
          />
        )}
      </Paper>
    );
  }
);

export default EventsCard;
