import React, { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { Grid, Button } from '@mui/material';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import { Trans } from '../i18n';
import { Validations } from '../utils';
import { FormFields } from '../commonTypes';

interface FormData {
  email?: string;
}

const initialValues: FormData = {
  email: '@',
};

const formFields: FormFields[] = [
  {
    size: 12,
    field: (
      <TextField
        label={<Trans i18nKey="labels.email" />}
        type="email"
        name="email"
        margin="none"
        required
        inputProps={{ 'data-testid': 'reset-password-email-input' }}
      />
    ),
  },
];

const validate = (values: FormData) => {
  const errors = Validations.new(values)
    .required('email')
    .emailShape('email')
    .getErrors();

  return errors;
};

export const ResetPasswordForm: FC<{ onSubmitCallback: () => void }> = ({
  onSubmitCallback,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formKey, setFormKey] = useState(Date.now());

  const onSubmit = async (values: FormData) => {
    const res = await fetch('/api/users/user/reset', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(values),
    });
    if (res.status === 200) {
      enqueueSnackbar(
        <Trans i18nKey="notifications.passwordWillBeSendToEmail" />,
        {
          variant: 'success',
        }
      );
      onSubmitCallback();
      setFormKey(Date.now());
    } else {
      enqueueSnackbar((await res.json()).error, {
        variant: 'error',
      });
    }
  };

  return (
    <Form
      key={formKey}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, submitting, pristine, values, form }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid alignItems="flex-start" spacing={2}>
            {formFields.map((item, idx) => (
              <Grid item xs={item.size} key={idx} marginTop={2}>
                {item.field}
              </Grid>
            ))}
            <Grid item style={{ marginTop: 16 }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
                data-testid="reset-password-submit-btn"
              >
                <Trans i18nKey="labels.send" />
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};
