import React, { FC } from 'react';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { Trans } from '../i18n';
import { SelectProps } from '../commonTypes';

export const TypeEquipmentSelect: FC<SelectProps<string>> = (props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    props.onChange(selectedValue);
  };

  return (
    <FormControl fullWidth>
      <FormControl>
        <Select
          defaultValue="normal"
          onChange={handleChange}
          renderValue={(selected?: string) => {
            if (!selected) {
              return (
                <FormHelperText sx={{ color: '#0000004D' }}>
                  <Trans i18nKey="typeEquipment.choose" />
                </FormHelperText>
              );
            }
            if (selected === 'small')
              return <Trans i18nKey="typeEquipment.small" />;
            else return <Trans i18nKey="typeEquipment.normal" />;
          }}
        >
          <MenuItem disabled value={undefined}>
            <FormHelperText>
              <Trans i18nKey="typeEquipment.choose" />
            </FormHelperText>
          </MenuItem>
          <MenuItem value={'small'}>
            <Trans i18nKey="typeEquipment.small" />
          </MenuItem>
          <MenuItem value={'normal'}>
            <Trans i18nKey="typeEquipment.normal" />
          </MenuItem>
        </Select>
      </FormControl>
    </FormControl>
  );
};
