import React, { FC, useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Button, Grid } from '@mui/material';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';

import { HardwarePlacementContext, UserContext } from '../contexts';
import { Company, PlacementType } from '../commonTypes';
import { OwnerSelectField } from '../hardware';

interface FormData {
  companyId?: string;
  name?: string;
}

interface NewHardwarePlacementFormProps {
  onCancel: () => void;
  onSubmit: (placement: PlacementType | null) => void;
  selectedCompany?: string;
}

export const NewHardwarePlacementForm: FC<NewHardwarePlacementFormProps> = (
  props
) => {
  const { onSubmit, onCancel, selectedCompany } = props;

  const { userData } = useContext(UserContext);
  const { refreshHardwarePlacements } = useContext(HardwarePlacementContext);

  const { enqueueSnackbar } = useSnackbar();

  const refreshPlacements = () => {
    refreshHardwarePlacements?.();
  };

  useEffect(() => refreshPlacements, []); // eslint-disable-line

  const initialValues: FormData = {
    companyId: selectedCompany ?? '',
    name: '',
  };

  const handleSubmit = async (values: FormData) => {
    if (!values?.name || !values.companyId) {
      return;
    }

    try {
      const res = await fetch('/api/places/new', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'POST',
        body: JSON.stringify(values),
      }).then((data) => data.json());
      if (res.error) {
        return enqueueSnackbar(res.error, { variant: 'error' });
      }

      onSubmit(res.place);
    } catch (exception) {
      console.log(exception);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <Field name="companyId">
                  {(props) => (
                    <OwnerSelectField
                      value={props.input.value}
                      onChange={(company?: Company) => {
                        props.input.onChange(company?._id);
                      }}
                      error={
                        props.meta.submitFailed ? props.meta.error : undefined
                      }
                      disabled={Boolean(selectedCompany)}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={<Trans i18nKey="newPlacementForm.placementName" />}
                  margin="none"
                  name="name"
                  type="text"
                  fullWidth
                  required
                />
              </Grid>

              <Grid
                item
                xs={12}
                position={{ xs: 'sticky', lg: 'relative' }}
                bottom={{ xs: '-16px', lg: '0px' }}
                bgcolor={{ xs: 'white' }}
                sx={{
                  marginTop: '16px',
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  spacing={{ xs: 0, lg: 2 }}
                  sx={{
                    maxWidth: 'calc(100% + 32px)',
                    width: 'calc(100% + 32px)',
                    marginInline: '-16px',
                    paddingInline: '16px',
                    background: '#fff',
                  }}
                >
                  <Grid item xs={12} sm={6} lg={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      sx={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                    >
                      <Trans i18nKey="newPlacementForm.send" />
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Button
                      type="button"
                      variant="text"
                      onClick={onCancel}
                      sx={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                    >
                      <Trans i18nKey="newPlacementForm.cancel" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};
