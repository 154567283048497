import React, { ReactElement } from 'react';
import { StyledDot } from '../customStyledComponents';

interface ResultValidityCheckType {
  invalidLicense: boolean;
  upcomingInvalidLicense: boolean;
  dot: ReactElement;
}

export const premiumLicenseValidityCheck = (date: string) => {
  const currDate = new Date();
  const premiumValidTo = new Date(date);

  const premiumExpireUntilMonth = new Date(premiumValidTo);
  premiumExpireUntilMonth.setMonth(premiumExpireUntilMonth.getMonth() - 1);

  const invalidLicense = premiumValidTo < currDate || !date;
  const upcomingInvalidLicense =
    premiumValidTo > currDate && currDate >= premiumExpireUntilMonth;

  let resultValidityCheck: ResultValidityCheckType = {
    invalidLicense,
    upcomingInvalidLicense,
    dot: <StyledDot ownerState={{ dotColor: 'lime' }} />,
  };

  if (invalidLicense) {
    resultValidityCheck = {
      ...resultValidityCheck,
      dot: <StyledDot ownerState={{ dotColor: 'red' }} />,
    };
  } else if (upcomingInvalidLicense) {
    resultValidityCheck = {
      ...resultValidityCheck,
      dot: <StyledDot ownerState={{ dotColor: 'orange' }} />,
    };
  }
  return resultValidityCheck;
};
