export const LANGUAGES = [
  {
    id: 'cs-CZ',
    label: 'Čeština',
  },
  {
    id: 'en-US',
    label: 'English',
  },
  {
    id: 'bg-BG',
    label: 'Bulgarian',
  },
];

export const tableEnum = {
  globalTable: 'globalTable',
  employeeTable: 'employeeTable',
  hardwarePlacementTable: 'hardwarePlacementTable',
  hardwareRevisionTypeTable: 'hardwareRevisionTypeTable',
  hardwareTable: 'hardwareTable',
  hardwareTypeTable: 'hardwareTypeTable',
  usersTable: 'usersTable',
  companyTable: 'companyTable',
  companyPreviewTable: 'companyPreviewTable',
  issueTable: 'issueTable',
  upcomingRevisionTable: 'upcomingRevisionTable',
};
