import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Grid } from '@mui/material';

import { Trans } from '../i18n';
import { HardwareRevisionType } from '../commonTypes';
import { Dialog } from '../components';
import { StyledGrid, StyledTextField } from '../customStyledComponents';

interface HardwareRevisionTypeEditDialogProps {
  onClose: () => void;
  hwRevTypeData: HardwareRevisionType;
  onUpdate: (hwRevType: HardwareRevisionType) => void;
}

export const HardwareRevisionTypeEditDialog: FC<
  HardwareRevisionTypeEditDialogProps
> = (props) => {
  const { onClose, hwRevTypeData, onUpdate } = props;

  const handleSubmit = ({
    name,
    days,
    remindDaysBefore,
  }: HardwareRevisionType) => {
    const newData = {
      ...hwRevTypeData,
      name,
      days,
      remindDaysBefore,
    };
    onUpdate(newData);
    onClose();
  };

  const initialValues: HardwareRevisionType = {
    _id: hwRevTypeData._id,
    name: hwRevTypeData.name,
    days: hwRevTypeData.days,
    isDeleted: hwRevTypeData.isDeleted,
    createdAt: hwRevTypeData.createdAt,
    remindDaysBefore: hwRevTypeData.remindDaysBefore,
  };

  return (
    <Dialog
      title={hwRevTypeData.name ?? <Trans i18nKey="detail.detailTitle" />}
      onClose={onClose}
    >
      <>
        <Form
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, pristine }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid>
                  <Grid xs={12} sx={{ marginBottom: '20px' }}>
                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="revisionTypeEditDialog.description" />
                        </Grid>
                        <Grid item xs={6}>
                          <StyledTextField
                            inputProps={{ style: { textAlign: 'right' } }}
                            type="text"
                            name="name"
                            margin="none"
                            fullWidth
                            required
                            variant="standard"
                            component="input"
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>

                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="revisionTypeEditDialog.days" />
                        </Grid>
                        <Grid item xs={6}>
                          <StyledTextField
                            inputProps={{ style: { textAlign: 'right' } }}
                            type="text"
                            name="days"
                            margin="none"
                            fullWidth
                            required
                            variant="standard"
                            component="input"
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>

                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="revisionTypeEditDialog.remindDaysBefore" />
                        </Grid>
                        <Grid item xs={6}>
                          <StyledTextField
                            inputProps={{ style: { textAlign: 'right' } }}
                            type="text"
                            name="remindDaysBefore"
                            margin="none"
                            fullWidth
                            variant="standard"
                            component="input"
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    xs={12}
                    sx={{
                      width: '100%',
                      position: 'sticky',
                      left: '0px',
                      bottom: '-18px',
                      padding: '16px 0px',
                      background: 'white',
                    }}
                  >
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={pristine || submitting}
                        style={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.save" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        type="button"
                        variant="text"
                        onClick={onClose}
                        style={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.cancel" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </>
    </Dialog>
  );
};
