import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { NewHardwarePlacementForm } from './NewHardwarePlacementForm';
import { PlacementType } from '../commonTypes';
import { Dialog } from '../components';

interface AddHardwarePlacementDialogProps {
  onClose?: () => void;
  onSubmit: (placement: PlacementType | null) => void;
  selectedCompany?: string;
}
export const AddHardwarePlacementDialog: FC<AddHardwarePlacementDialogProps> = (
  props
) => {
  const { onClose, onSubmit, selectedCompany } = props;

  return (
    <Dialog title={<Trans i18nKey="labels.hwPlacement" />} onClose={onClose}>
      <NewHardwarePlacementForm
        onSubmit={onSubmit}
        onCancel={onClose!}
        selectedCompany={selectedCompany}
      />
    </Dialog>
  );
};
