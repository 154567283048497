import React, { FC } from 'react';
import { Trans } from '../../i18n';
import { Dialog } from '../Dialog';
import { LoginForm } from '../../forms';

interface LoginDialogProps {
  onClose: () => void;
}

export const LoginDialog: FC<LoginDialogProps> = (props) => {
  const { onClose } = props;

  return (
    <Dialog title={<Trans i18nKey="labels.login" />} onClose={onClose}>
      <LoginForm onClose={onClose} />
    </Dialog>
  );
};
