import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Checkbox, Grid } from '@mui/material';

import { HardwareType } from '../commonTypes';
import { Trans } from '../i18n';
import { Dialog } from '../components';
import { StyledGrid, StyledTextField } from '../customStyledComponents';

interface HwTypeEditDialogProps {
  onClose: () => void;
  hwTypeData: HardwareType;
  onUpdate: (hwType: HardwareType) => void;
}

export const HwTypeEditDialog: FC<HwTypeEditDialogProps> = (props) => {
  const { onClose, hwTypeData, onUpdate } = props;

  const handleSubmit = (data: HardwareType) => {
    onUpdate(data);
    onClose();
  };

  const initialValues: HardwareType = {
    companyId: hwTypeData.companyId,
    inventoryPrefix: hwTypeData.inventoryPrefix,
    isDateOfPurchaseRequired: hwTypeData.isDateOfPurchaseRequired,
    isGuaranteeRequired: hwTypeData.isGuaranteeRequired,
    isInventoryNumberRequired: hwTypeData.isInventoryNumberRequired,
    isPriceRequired: hwTypeData.isPriceRequired,
    isSerialNumberRequired: hwTypeData.isSerialNumberRequired,
    isDeleted: hwTypeData.isDeleted,
    name: hwTypeData.name,
    _id: hwTypeData._id,
  };

  return (
    <Dialog
      title={hwTypeData.name ?? <Trans i18nKey="detail.detailTitle" />}
      onClose={onClose}
    >
      <>
        <Form
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid>
                  <Grid xs={12} sx={{ marginBottom: '20px' }}>
                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="hwTypeEditDialog.description" />
                        </Grid>
                        <Grid item xs={6}>
                          <StyledTextField
                            inputProps={{ style: { textAlign: 'right' } }}
                            type="text"
                            name="name"
                            margin="none"
                            fullWidth
                            required
                            variant="standard"
                            component="input"
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>

                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="hwTypeEditDialog.inventoryNumberPrefix" />
                        </Grid>
                        <Grid item xs={6}>
                          <StyledTextField
                            inputProps={{ style: { textAlign: 'right' } }}
                            type="text"
                            name="inventoryPrefix"
                            margin="none"
                            fullWidth
                            variant="standard"
                            component="input"
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>

                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="hwTypeEditDialog.inventoryNumber" />
                        </Grid>
                        <Grid item xs={6}>
                          <Checkbox
                            defaultChecked={values.isInventoryNumberRequired}
                            onChange={() =>
                              (values.isInventoryNumberRequired =
                                !values.isInventoryNumberRequired)
                            }
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>

                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="hwTypeEditDialog.dateOfPurchase" />
                        </Grid>
                        <Grid item xs={6}>
                          <Checkbox
                            defaultChecked={values.isDateOfPurchaseRequired}
                            onChange={() =>
                              (values.isDateOfPurchaseRequired =
                                !values.isDateOfPurchaseRequired)
                            }
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>

                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="hwTypeEditDialog.guaranteeUntil" />
                        </Grid>
                        <Grid item xs={6}>
                          <Checkbox
                            defaultChecked={values.isGuaranteeRequired}
                            onChange={() =>
                              (values.isGuaranteeRequired =
                                !values.isGuaranteeRequired)
                            }
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>

                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="hwTypeEditDialog.serialNumber" />
                        </Grid>
                        <Grid item xs={6}>
                          <Checkbox
                            defaultChecked={values.isSerialNumberRequired}
                            onChange={() =>
                              (values.isSerialNumberRequired =
                                !values.isSerialNumberRequired)
                            }
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>

                    <StyledGrid>
                      <Grid container>
                        <Grid item xs={6} alignItems="center" display="flex">
                          <Trans i18nKey="hwTypeEditDialog.price" />
                        </Grid>
                        <Grid item xs={6}>
                          <Checkbox
                            defaultChecked={values.isPriceRequired}
                            onChange={() =>
                              (values.isPriceRequired = !values.isPriceRequired)
                            }
                          />
                        </Grid>
                      </Grid>
                    </StyledGrid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    xs={12}
                    sx={{
                      width: '100%',
                      position: 'sticky',
                      left: '0px',
                      bottom: '-18px',
                      padding: '16px 0px',
                      background: 'white',
                    }}
                  >
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                        style={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.save" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        type="button"
                        variant="text"
                        onClick={onClose}
                        style={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.cancel" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </>
    </Dialog>
  );
};
