import React, { FC } from 'react';
import { t } from 'i18next';
import { Hardware } from '../../commonTypes';
import { Dialog } from '../Dialog';
import { GuideForm } from '../DialogForms';

interface GuideDialogProps {
  onClose: () => void;
  data: Hardware;
  openStickerPrint: () => void;
}

export const GuideDialog: FC<GuideDialogProps> = (props) => {
  const { onClose, data, openStickerPrint } = props;

  return (
    <Dialog
      title={
        t('guideDialog.title', {
          description: data.description,
        }) as unknown as string
      }
      onClose={onClose}
      fullScreen={true}
    >
      <GuideForm
        onCancel={onClose}
        data={data}
        openStickerPrint={openStickerPrint}
      />
    </Dialog>
  );
};
