import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { Grid } from '@mui/material';
import { DatePicker, TextField } from 'mui-rff';

import { HardwareType } from '../../commonTypes';

export const BasicFields: FC<{ type: HardwareType }> = ({ type }) => {
  return (
    <>
      <Grid item xs={12} sm={6} lg={12}>
        <TextField
          label={<Trans i18nKey="addHardwareForm.description" />}
          type="text"
          name="description"
          margin="none"
          fullWidth
          required
          inputProps={{ 'data-testid': 'hw-description-field' }}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={12}>
        <DatePicker
          inputFormat="dd.MM.yyyy"
          name="dateOfPurchase"
          label={<Trans i18nKey="addHardwareForm.purchaseDate" />}
          required={type.isDateOfPurchaseRequired}
          // @ts-ignore
          inputProps={{ 'data-testid': 'hw-date-of-purchase-field' }}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={12}>
        <DatePicker
          inputFormat="dd.MM.yyyy"
          name="guaranteeUntil"
          label={<Trans i18nKey="addHardwareForm.guaranteeDate" />}
          required={type.isGuaranteeRequired}
          // @ts-ignore
          inputProps={{ 'data-testid': 'hw-guarantee-date-field' }}
        />
      </Grid>
    </>
  );
};
